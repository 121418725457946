import Methods from '../Methods';
import INotification from '../Models/INotification';
import IMutation from '../Mutations/IMutation';
import Transport, { ITransport } from '../Transports';

export interface QueryNotificationRequest {
    page?: number;
    perPage?: number;
    whereId?: string | Array<string>;
    whereIdLess?: string;
    whereIdGreater?: string;
    whereRead?: boolean;
    whereUnread?: boolean;
}

class NotificationService {
    private _transport: ITransport;

    constructor(transport: ITransport) {
        this._transport = transport;
    }

    public queryAsync(request?: QueryNotificationRequest): Promise<INotification[]> {
        return this._transport.invokeAsync<QueryNotificationRequest, INotification[]>(
            Methods.Get,
            `/api/v1/notifications`,
            request,
        );
    }

    public countAsync(request?: QueryNotificationRequest): Promise<number> {
        return this._transport.invokeAsync<unknown, number>(Methods.Get, `/api/v1/notifications/count`, request);
    }

    public removeAsync(request: string | QueryNotificationRequest): Promise<IMutation[]> {
        if (typeof request == 'string') {
            return this._transport.invokeAsync<null, IMutation[]>(Methods.Delete, `/api/v1/notifications/${request}`);
        }

        return this._transport.invokeAsync<QueryNotificationRequest, IMutation[]>(
            Methods.Delete,
            `/api/v1/notifications/delete`,
            request,
        );
    }

    public markAsReadAsync(request: string | QueryNotificationRequest): Promise<IMutation[]> {
        if (typeof request == 'string') {
            return this._transport.invokeAsync<null, IMutation[]>(
                Methods.Patch,
                `/api/v1/notifications/${request}/mark-as-read`,
            );
        }

        return this._transport.invokeAsync<QueryNotificationRequest, IMutation[]>(
            Methods.Patch,
            `/api/v1/notifications/mark-as-read`,
            request,
        );
    }
}

const service = new NotificationService(Transport);

Object.freeze(service);

export default service;
