import oidc from '@/vue-oidc-client';
import { ErrorCodes } from './ErrorCodes';

class Application {
    private readonly maxAuthFailedCount = 1;

    public get authFailedCount(): number {
        const authFailedCountString = window.localStorage.getItem('auth-failed-count');

        if (!authFailedCountString) {
            return 0;
        }

        return parseInt(authFailedCountString) || 0;
    }

    public set authFailedCount(value: number) {
        window.localStorage.setItem('auth-failed-count', value.toString());
    }

    public authAsync() {
        return oidc.startup().then(this.lateAuthAsync.bind(this));
    }

    private async lateAuthAsync() {
        const authFailedCount = this.authFailedCount;

        try {
            await oidc.mgr.signinSilent();

            this.authFailedCount = 0;

            return true;
        } catch (error) {
            this.authFailedCount = authFailedCount + 1;

            if (authFailedCount >= this.maxAuthFailedCount) {
                this.authFailedCount = 0;
                throw new Error(ErrorCodes.TooManyFailedAuthorizationAttempts);
            }

            await oidc.mgr.signinRedirect();

            return false;
        }
    }
}

export default new Application();
