<template>
    <div
        class="story-record"
        :class="{
            'story-record--hover': selectable,
            'story-record--active': active,
            'story-record--offset': record.offset,
            'story-record--padding': record.padding,
        }"
    >
        <span class="story-record__header" v-if="(record.header || record.actor) && !record.collapsed">
            <span
                class="story-record__header-actor"
                :class="{
                    'story-record__header-actor--weak': record.actor?.weakColor,
                    'story-record__header-actor--transparent': record.actor?.hideActor,
                }"
                >{{ record.actor?.displayName }}&nbsp;</span
            >

            <span class="story-record__header-content" v-if="record.header?.content">
                <VFeedStoryRecordContentRenderer :content="record.header.content"></VFeedStoryRecordContentRenderer
                >&nbsp;
            </span>

            <span class="story-record__header-timestamp" v-tooltip="timestampWithTime">{{ timestamp }}</span>
        </span>

        <p
            class="story-record__content"
            :class="{
                'story-record__content--edited': record.contentEdited,
            }"
            :edited-text="'(' + t('edited') + ')'"
            v-if="content"
        >
            {{ content }}
        </p>

        <div class="story-record__content story-record__content--attachment" v-if="record.attachment">
            <VAttachment :attachment="record.attachment"></VAttachment>
        </div>

        <div
            class="story-record__layer story-record__layer--left"
            v-if="record.actor?.withPicture && !record.collapsed"
        >
            <VUserCard picture-only :user="record.actor"></VUserCard>
        </div>

        <div
            class="story-record__layer story-record__layer--right story-record__layer--actions story-record__layer--show-on-hover"
            v-if="record.actions"
        >
            <VButtonDropdown
                :options="record.actions"
                placement="left"
                @open:popper="toggleActive"
                @close:popper="toggleActive"
            >
                <DotsSvg></DotsSvg>
            </VButtonDropdown>
        </div>
    </div>
</template>

<script setup lang="ts">
// Svg
import DotsSvg from '@/assets/dots.svg';

// Components
import VUserCard from './VUserCard.vue';
import VAttachment from './VAttachment.vue';
import VButtonDropdown from './VButtonDropdown.vue';
import VFeedStoryRecordContentRenderer from './VFeedStoryRecordContentRenderer.vue';

// Other
import IFeedStoryRecord from '@/core/Values/IFeedStoryRecord';
import { formatDateOnly, formatWithTime } from '@/utils/date-utils';
import { PropType, defineProps, computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();
const props = defineProps({
    record: { type: Object as PropType<IFeedStoryRecord>, required: true },
    selectable: { type: Boolean, default: false },
});

let active = ref(false);

const content = computed(() => props.record.content);
const timestamp = computed(() => (!props.record?.timestamp ? '' : formatDateOnly(props.record.timestamp)));
const timestampWithTime = computed(() => (!props.record?.timestamp ? '' : formatWithTime(props.record.timestamp)));

function toggleActive() {
    active.value = !active.value;
}
</script>

<style lang="scss">
.story-record {
    position: relative;
    padding: 0 2.5rem 0 3rem;

    @media (min-width: #{$breakpoint-tablet + 1}) {
        padding: 0 4rem 0 4.5rem;
    }

    &__header {
        display: inline-block;
        margin: 0.25rem 0;

        line-height: 1.25rem;
    }

    &__header-actor {
        @include h5(false);
        color: var(--text-black-primary);

        &--weak {
            @include caption-primary(false);
            color: var(--text-black-tertiary);
        }

        &--transparent {
            color: transparent;
        }
    }

    &__header-content {
        color: var(--text-black-primary);
        @include caption-tertiary(false);
    }

    &__header-timestamp {
        @include caption-tertiary(false);
        color: var(--text-black-tertiary);
        text-wrap: nowrap;
    }

    &__content {
        margin: 0;

        @include h7();
        line-height: 1.5rem;
        word-wrap: break-word;
        white-space: pre-line;
        color: var(--text-black-primary);

        &--edited::after {
            content: attr(edited-text);
            color: var(--text-black-tertiary);
        }

        &--attachment {
            max-width: 20rem;
            margin-bottom: 0.25rem;
        }
    }

    &__layer {
        position: absolute;
        top: 0.25rem;

        &--left {
            left: 0.5rem;

            @media (min-width: #{$breakpoint-tablet + 1}) {
                left: 2rem;
            }
        }

        &--right {
            right: 2rem;
        }

        &--actions {
            top: -0.75rem;

            border-radius: 0.5rem;
            border: 0.0625rem var(--background-tertiary) solid;
            background-color: var(--background-color);
            --background-color: var(--background-primary);
        }

        &--show-on-hover {
            // display: none;
            visibility: hidden;
        }
    }

    &--offset {
        margin-top: 1rem;
    }

    &--padding {
        padding-top: 0.125rem;
        padding-bottom: 0.125rem;
    }

    &--hover:hover,
    &--active {
        background-color: var(--background-color);
        --background-color: var(--background-secondary);

        & .story-record__layer--show-on-hover {
            visibility: visible;
        }
    }
}
</style>
