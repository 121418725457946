import Methods from '../Methods';
import IProject from '../Models/IProject';
import Transport, { ITransport } from '../Transports';

class ProjectService {
    private _transport: ITransport;

    constructor(transport: ITransport) {
        this._transport = transport;
    }

    public getAllAsync(): Promise<IProject[]> {
        return this._transport.invokeAsync<undefined, IProject[]>(Methods.Get, `/api/v1/projects`);
    }

    public findAsync(projectId: number): Promise<IProject> {
        return this._transport.invokeAsync<undefined, IProject>(Methods.Get, `/api/v1/projects/${projectId}`);
    }

    public getProjectBoardsAsync(projectId: number): Promise<IProject[]> {
        return this._transport.invokeAsync<undefined, IProject[]>(Methods.Get, `/api/v1/projects/${projectId}/boards`);
    }

    public getProjectColumnsAsync(projectId: number): Promise<IProject[]> {
        return this._transport.invokeAsync<undefined, IProject[]>(Methods.Get, `/api/v1/projects/${projectId}/columns`);
    }
}

const service = new ProjectService(Transport);

Object.freeze(service);

export default service;
