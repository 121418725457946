import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createVNode as _createVNode, unref as _unref, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "approval-list" }
const _hoisted_2 = { class: "approval-list__list" }
const _hoisted_3 = { class: "approval-cart" }
const _hoisted_4 = { class: "approval-cart__row approval-cart__row--content" }
const _hoisted_5 = {
  key: 0,
  class: "approval-cart__actions"
}
const _hoisted_6 = {
  key: 0,
  class: "approval-cart__row approval-cart__row--comment"
}

import VButton from './VButton.vue';
import VUserCard from './VUserCard.vue';
import VApprovalStatus from './VApprovalStatus.vue';

// Other
import IApprovement from '@/core/Models/IApprovement';
import AuthorizationProvider from '@/core/Authorization/AuthorizationProvider';
import Operations from '@/core/Authorization/Operations';
import ApprovementStatus from '@/core/Values/ApprovementStatus';
import { $confirm } from '@/utils/app-utils';
import { PropType } from 'vue';
import { useI18n } from 'vue-i18n';


export default /*@__PURE__*/_defineComponent({
  __name: 'VApprovementList',
  props: {
    disabled: { type: Boolean, default: false },
    modelValue: { type: Array as PropType<IApprovement[]>, required: true, default: () => [] },
},
  emits: ['approve', 'update:modelValue', 'disapprove', 'update:modelValue'],
  setup(__props, { emit: __emit }) {

// Svg
// ...

// Components
const emit = __emit;
const { t } = useI18n();

const props = __props;

function getCommentChunks(approvement: IApprovement): string[] | undefined {
    return approvement.comment?.split('\n');
}

function approve(approvement: IApprovement) {
    $confirm({
        title: t('confirm-task-or-goal-approve'),
        message: '',
        withComment: true,
        commentHint: t('confirm-task-or-goal-approve-hint'),
        commentRequired: false,
        commentMaxLength: 512,
        buttonOkName: t('approve'),
        buttonOkClasses: 'button--green',
        okCallback: (comment) => {
            approvement.status = ApprovementStatus.Approved;
            emit('approve', { approvement, comment });
            emit('update:modelValue', props.modelValue);
        },
    });
}

function disapprove(approvement: IApprovement) {
    $confirm({
        title: t('confirm-task-or-goal-disapprove'),
        message: '',
        withComment: true,
        commentRequired: true,
        commentMaxLength: 512,
        buttonOkName: t('disapprove'),
        okCallback: (comment) => {
            approvement.status = ApprovementStatus.Disapproved;
            emit('disapprove', { approvement, comment });
            emit('update:modelValue', props.modelValue);
        },
    });
}

function isWaiting(approvement: IApprovement): boolean {
    return approvement.status === ApprovementStatus.Waiting;
}

function canApprove(approvement: IApprovement): boolean {
    return AuthorizationProvider.authorize(approvement, Operations.ApproveTask);
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("ul", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.modelValue, (approvement, index) => {
        return (_openBlock(), _createElementBlock("li", {
          key: index,
          class: "approval-list__item"
        }, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createVNode(VUserCard, {
                class: "approval-cart__approver",
                user: approvement.approver
              }, null, 8, ["user"]),
              (!__props.disabled && canApprove(approvement) && isWaiting(approvement))
                ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                    _createVNode(VButton, {
                      class: "button--secondary button--green",
                      onClick: ($event: any) => (approve(approvement))
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_unref(t)('approve')), 1)
                      ]),
                      _: 2
                    }, 1032, ["onClick"]),
                    _createVNode(VButton, {
                      class: "button--secondary button--negative",
                      onClick: ($event: any) => (disapprove(approvement))
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_unref(t)('disapprove')), 1)
                      ]),
                      _: 2
                    }, 1032, ["onClick"])
                  ]))
                : _createCommentVNode("", true),
              _createVNode(VApprovalStatus, {
                class: "approval-cart__status",
                status: approvement.status
              }, null, 8, ["status"])
            ]),
            (approvement.comment)
              ? (_openBlock(), _createElementBlock("p", _hoisted_6, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(getCommentChunks(approvement), (chunk, chunkIndex) => {
                    return (_openBlock(), _createElementBlock(_Fragment, { key: chunkIndex }, [
                      _createTextVNode(_toDisplayString(chunk) + " ", 1),
                      _cache[0] || (_cache[0] = _createElementVNode("br", null, null, -1))
                    ], 64))
                  }), 128))
                ]))
              : _createCommentVNode("", true)
          ])
        ]))
      }), 128))
    ])
  ]))
}
}

})