import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "text-diff" }

import TextDiffService from '@/core/Services/TextDiffService';
import { $error } from '@/utils/app-utils';
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { diffWords, Change } from 'diff';


export default /*@__PURE__*/_defineComponent({
  __name: 'VTextDiff',
  props: {
    textDiffId: { type: Number, required: true },
},
  setup(__props) {

// Svg
// ...

// Components
// ...

// Other
const { t } = useI18n();
const props = __props;

let textDiff = ref(null as Change[] | null);
let isShowDifference = ref(false);

async function toggleDifference() {
    isShowDifference.value = !isShowDifference.value;

    if (textDiff.value) {
        return;
    }

    try {
        const response = await TextDiffService.find(props.textDiffId);
        textDiff.value = diffWords(response.left, response.right);
    } catch (error) {
        $error(error);
    }
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("span", _hoisted_1, [
    _createElementVNode("button", {
      class: "button button--link button--link-inline button--link-green",
      onClick: toggleDifference
    }, _toDisplayString(_unref(isShowDifference) ? _unref(t)('hide-difference') : _unref(t)('show-difference')), 1),
    (_unref(isShowDifference))
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          _cache[0] || (_cache[0] = _createElementVNode("br", null, null, -1)),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(textDiff), (word, index) => {
            return (_openBlock(), _createElementBlock("span", {
              key: index,
              class: _normalizeClass(["text-diff__part", {
                        'text-diff__part--added': word.added,
                        'text-diff__part--removed': word.removed,
                    }])
            }, _toDisplayString(word.value), 3))
          }), 128))
        ], 64))
      : _createCommentVNode("", true)
  ]))
}
}

})