import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, withCtx as _withCtx, isRef as _isRef, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "app-page-header__column app-page-header__column--tools" }

import DotsSvg from '@/assets/dots.svg';
import DownloadSvg from '@/assets/download.svg';

// Components
import VTaskFilter, { TaskFilterTypes } from '../components/VTaskFilter.vue';
import VSearchField from '../components/VSearchField.vue';
import VPage from '../components/VPage.vue';
import VPageHeader from '../components/VPageHeader.vue';
import VPageContent from '../components/VPageContent.vue';
import VTable from '@/components/VTable.vue';
import VTableRow from '@/components/VTableRow.vue';
import VTableDataSet from '@/components/VTableDataSet.vue';
import VButtonDropdown from '../components/VButtonDropdown.vue';

// Other
import { useI18n } from 'vue-i18n';
import { computed, markRaw, ref, toRaw, watch } from 'vue';
import Storages from '@/core/Storages';
import { IOrder } from '@/core/Values/IOrder';
import Settings from '@/core/Settings';
import TaskService, { ExportTaskRequest, QueryTaskRequest } from '@/core/Services/TaskService';
import ITask from '@/core/Models/ITask';
import UserMapper from '@/core/UserMapper';
import ObjectStorageMapper from '@/core/ObjectStorageMapper';
import MutationBus from '@/core/Mutations/MutationBus';
import IMutatorContext from '@/core/Mutations/IMutatorContext';
import { TaskMutatorContext } from '@/core/Mutators/TaskMutator';
import store from '@/store';
import { useTaskContextMenu } from '@/mixins/TaskApi';
import useTaskTableViewer, { journalColumns } from '@/mixins/TaskTableViewer';
import { useRoute } from 'vue-router';
import orderBy from 'lodash.orderby';
import TaskType from '@/core/Values/TaskType';
import { IDropdownOption } from '@/core/Values/IDropdownOption';
import { downloadFile } from '@/utils/utils';
import TaskExportSchema from '@/core/Values/TaskExportSchema';


export default /*@__PURE__*/_defineComponent({
  __name: 'StatisticPage',
  setup(__props) {

// Svg
const { t } = useI18n();
const route = useRoute();
const { openContextMenu } = useTaskContextMenu();
const table = useTaskTableViewer(Settings.UI.Table + '.statistic', {
    header: true,
    columns: journalColumns,
});

let goalsAndTasks = ref([] as ITask[]);
let searchString = ref('');
let mutatorContext = null as IMutatorContext | null;
let currentUser = store.state.user;
let pageTitle = t('statistic');
let regularActions: IDropdownOption[] = [
    {
        icon: markRaw(DownloadSvg),
        title: t('export'),
        action: () => {
            downloadFile('/api/v1/tasks/export', {
                ...tasksFilters.value,
                withColumns: table.value.columns.filter((column) => column.visible).map((column) => column.key),
                schema: TaskExportSchema.GroupedByParent,
                language: store.state.userSettings.locale,
            } as ExportTaskRequest);
        },
    },
];

const order = computed(
    Storages.Filters.computed<IOrder>(Settings.UI.Order + '.statistic', {
        orderBy: 'id',
        orderAscending: false,
    }),
);
const filters = computed(
    Storages.Filters.computed(Settings.UI.Filters + '.statistic', { value: [], formattedValue: {} }),
);
const tasks = computed(() => {
    const result: ITask[] = [];

    const mapper = new Map<number, ITask[]>();
    const orderColumn = toRaw(order).value.orderBy as keyof ITask;
    const orderAscending = toRaw(order).value.orderAscending ?? false;

    orderBy(goalsAndTasks.value, orderColumn, orderAscending ? 'asc' : 'desc').forEach((task) => {
        const key = task.parentId ?? -1;
        const group = mapper.get(key);
        if (!group) {
            mapper.set(key, [task]);
        } else {
            group.push(task);
        }
    });

    const root = mapper.get(-1);
    if (!root) {
        return result;
    }

    return root.reduce((carry, task) => {
        const children = mapper.get(task.id);
        return children ? carry.concat(task, children) : carry.concat(task);
    }, result);
});

const tasksFilters = computed(
    (): Partial<QueryTaskRequest> => ({
        ...order.value,
        ...filters.value.formattedValue,
        includes: ['comments-count', 'attachments-count', 'approvements', 'collaborators'],
        search: searchString.value ?? undefined,
    }),
);

watch(() => tasksFilters.value, fetchData, { immediate: true });

async function fetchData(): Promise<void> {
    goalsAndTasks.value = await TaskService.queryAsync(tasksFilters.value);

    // Attaching of users and preview to tasks.
    UserMapper.mapTasksAsync(goalsAndTasks.value);
    ObjectStorageMapper.mapTasksAsync(goalsAndTasks.value);

    MutationBus.deactivate(mutatorContext);
    mutatorContext = markRaw(
        new TaskMutatorContext(goalsAndTasks.value, {
            mapUsers: true,
            mapPreview: false,
            // Fetches a task if the patch contains the changes that can affect of the filters.
            fetchTask: async (patch: Partial<ITask>) => {
                if (!TaskService.matchPatch(patch, currentUser?.id as string, tasksFilters.value)) {
                    return undefined;
                }

                const tasks = await TaskService.queryAsync({
                    ...tasksFilters.value,
                    whereId: [patch.id as number],
                    perPage: 1,
                });

                if (!tasks.length) {
                    return undefined;
                }

                return UserMapper.mapTaskAsync(tasks[0]);
            },

            // Excludes tasks if they are not matching the specified filters.
            excludeTask: (task: ITask) => !TaskService.match(task, currentUser?.id as string, tasksFilters.value),
            // Ignores tasks creation if they are not matching the specified filters.
            ignoreTaskCreating: (task: ITask) => TaskService.match(task, currentUser?.id as string, tasksFilters.value),
        }),
    );
    MutationBus.activate(mutatorContext);
}

return (_ctx: any,_cache: any) => {
  const _component_RouterLink = _resolveComponent("RouterLink")!

  return (_openBlock(), _createBlock(VPage, null, {
    default: _withCtx(() => [
      _createVNode(VPageHeader, {
        "show-third-block": "",
        title: _unref(pageTitle)
      }, {
        second: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(VTaskFilter, {
              class: "prevent-close",
              "filter-types": [
                            _unref(TaskFilterTypes).Author,
                            _unref(TaskFilterTypes).Status,
                            _unref(TaskFilterTypes).Assignee,
                            _unref(TaskFilterTypes).Contributor,
                            _unref(TaskFilterTypes).AuthorOrAssignee,
                            _unref(TaskFilterTypes).Deadline,
                            _unref(TaskFilterTypes).CreationDate,
                        ],
              modelValue: filters.value,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((filters).value = $event))
            }, null, 8, ["filter-types", "modelValue"]),
            _createVNode(VButtonDropdown, {
              class: "prevent-close",
              placement: "left-start",
              options: _unref(regularActions)
            }, {
              default: _withCtx(() => [
                _createVNode(_unref(DotsSvg), { class: "rotate-90" })
              ]),
              _: 1
            }, 8, ["options"]),
            _createVNode(VSearchField, {
              class: "prevent-close",
              "debounce-mode": "",
              modelValue: _unref(searchString),
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_isRef(searchString) ? (searchString).value = $event : searchString = $event))
            }, null, 8, ["modelValue"])
          ])
        ]),
        _: 1
      }, 8, ["title"]),
      _createVNode(VPageContent, {
        class: "",
        vertical: ""
      }, {
        default: _withCtx(() => [
          _createVNode(VTable, {
            table: _unref(table),
            "onUpdate:table": _cache[2] || (_cache[2] = ($event: any) => (_isRef(table) ? (table).value = $event : null)),
            order: order.value,
            "onUpdate:order": _cache[3] || (_cache[3] = ($event: any) => ((order).value = $event))
          }, {
            default: _withCtx(() => [
              (tasks.value.length)
                ? (_openBlock(), _createBlock(VTableDataSet, { key: 0 }, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(tasks.value, (task, index) => {
                        return (_openBlock(), _createElementBlock(_Fragment, {
                          key: task.id
                        }, [
                          (task.type === _unref(TaskType).Goal)
                            ? (_openBlock(), _createBlock(_component_RouterLink, {
                                key: 0,
                                to: { name: 'goals.view', params: { goalId: task.id } }
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(VTableRow, {
                                    table: _unref(table),
                                    data: task,
                                    index: index,
                                    active: task.id.toString() == _unref(route).query.task,
                                    onContextmenu: ($event: any) => (_unref(openContextMenu)($event, task))
                                  }, null, 8, ["table", "data", "index", "active", "onContextmenu"])
                                ]),
                                _: 2
                              }, 1032, ["to"]))
                            : (_openBlock(), _createBlock(_component_RouterLink, {
                                key: 1,
                                class: "prevent-close",
                                to: { query: { task: task.id } }
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(VTableRow, {
                                    offset: +!!task.parentId * 3,
                                    table: _unref(table),
                                    data: task,
                                    index: index,
                                    active: task.id.toString() == _unref(route).query.task,
                                    onContextmenu: ($event: any) => (_unref(openContextMenu)($event, task))
                                  }, null, 8, ["offset", "table", "data", "index", "active", "onContextmenu"])
                                ]),
                                _: 2
                              }, 1032, ["to"]))
                        ], 64))
                      }), 128))
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }, 8, ["table", "order"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}
}

})