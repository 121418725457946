import { Store } from 'vuex';
import IMutation from '../Mutations/IMutation';
import { IState } from '@/store';
import { MutationNames } from '@/store/mutations';
import MutationType from '../Mutations/MutationType';
import ObjectType from '../Mutations/ObjectType';
import { IMutator } from '../Mutations/IMutator';
import IMutatorContext from '../Mutations/IMutatorContext';
import { EventNames } from '../EventNames';

export class StoreMutator implements IMutator {
    private _matrix = {
        [ObjectType.Project]: {
            [MutationType.Created]: MutationNames.CreateProject,
            [MutationType.Updated]: MutationNames.UpdateProject,
            [MutationType.Deleted]: MutationNames.DeleteProject,
            [MutationType.Patched]: MutationNames.PatchProject,
        },
        [ObjectType.Board]: {
            [MutationType.Created]: MutationNames.CreateBoard,
            [MutationType.Updated]: MutationNames.UpdateBoard,
            [MutationType.Deleted]: MutationNames.DeleteBoard,
            [MutationType.Patched]: MutationNames.PatchBoard,
        },
    };

    public mutate(context: IMutatorContext, mutations: IMutation[], eventName: string): void {
        if (eventName !== EventNames.DbObjectsMutated) {
            return;
        }

        mutations.forEach((mutation) => {
            const objectType = mutation.objectType as keyof typeof this._matrix;

            if (
                !Object.prototype.hasOwnProperty.call(this._matrix, mutation.objectType) ||
                !Object.prototype.hasOwnProperty.call(this._matrix[objectType], mutation.type)
            ) {
                return;
            }

            const store = (context as StoreMutatorContext).store;

            store.commit(this._matrix[objectType][mutation.type], mutation.objectState);
        });
    }
}

const defaultMutator = new StoreMutator();

export class StoreMutatorContext implements IMutatorContext {
    public store: Store<IState>;
    public mutator: IMutator;

    public constructor(store: Store<IState>, mutator: IMutator = defaultMutator) {
        this.store = store;
        this.mutator = mutator;
    }
}
