import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import ITask from '@/core/Models/ITask';
import Status, { StatusTitle } from '../core/Values/Status';
import { PropType, computed } from 'vue';
import { useI18n } from 'vue-i18n';


export default /*@__PURE__*/_defineComponent({
  __name: 'VTaskStatus',
  props: {
    task: { type: Object as PropType<ITask>, required: true },
    showTitle: { type: Boolean, default: true },
},
  setup(__props) {

// Other
const statusColorClassName = {
    [Status.Draft]: 'status--blurry',
    [Status.Approvement]: 'status--orange',
    [Status.InProgress]: 'status--positive',
    [Status.Completed]: 'status--orange',
    [Status.Rejected]: 'status--blurry',
    [Status.Finished]: 'status--green',
};

const { t } = useI18n();
const props = __props;

const statusTitle = computed((): string => {
    if (!props.showTitle) {
        return '';
    }

    if (props.task.archivedAt) {
        return t('statuses.archived');
    }

    if (props.task.status === Status.Draft && props.task.inProgressAt) {
        return t('statuses.draft-in-progress');
    }

    const statusTitle = StatusTitle[props.task.status as keyof typeof StatusTitle] as string;

    return t(statusTitle ?? 'unknown');
});
const className = computed((): string => {
    if (props.task.archivedAt) {
        return 'status--negative';
    }

    return statusColorClassName[props.task.status as unknown as keyof typeof statusColorClassName] ?? '';
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["status status--indicator", className.value])
  }, _toDisplayString(statusTitle.value), 3))
}
}

})