import IMutation from '../Mutations/IMutation';
import MutationType from '../Mutations/MutationType';
import ObjectType from '../Mutations/ObjectType';
import { IMutator } from '../Mutations/IMutator';
import IMutatorContext from '../Mutations/IMutatorContext';
import IColumn from '../Models/IColumn';
import IMutationPatch from '../Mutations/IMutationPatch';
import diffPatcher from '../Mutations/Patcher';

class ColumnMutator implements IMutator {
    private _matrix = {
        [ObjectType.Column]: {
            [MutationType.Created]: this.createColumn,
            [MutationType.Updated]: this.updateColumn,
            [MutationType.Deleted]: this.deleteColumn,
            [MutationType.Patched]: this.patchColumn,
        },
    };

    mutate(context: IMutatorContext, mutations: IMutation[]): void {
        mutations.forEach((mutation) => {
            const objectType = mutation.objectType as keyof typeof this._matrix;

            if (
                !Object.prototype.hasOwnProperty.call(this._matrix, mutation.objectType) ||
                !Object.prototype.hasOwnProperty.call(this._matrix[objectType], mutation.type)
            ) {
                return;
            }

            const action = this._matrix[objectType][mutation.type] as (
                context: ColumnMutatorContext,
                objectState: unknown,
            ) => void;

            action.bind(this)(context as ColumnMutatorContext, mutation.objectState);
        });
    }

    protected async createColumn(context: ColumnMutatorContext, column: IColumn) {
        context.columns.push(column);
    }

    protected async updateColumn(context: ColumnMutatorContext, column: IColumn) {
        const carry = context.columns.find((item) => item.id === column.id);

        if (!carry) {
            return;
        }

        Object.assign(carry, column);
    }

    protected deleteColumn(context: ColumnMutatorContext, column: IColumn) {
        const index = context.columns.findIndex((item) => item.id === column.id);

        if (index !== -1) {
            context.columns.splice(index, 1);
        }
    }

    protected async patchColumn(context: ColumnMutatorContext, patch: IMutationPatch) {
        if (!patch.id) {
            return;
        }

        const carry = context.columns.find((item) => item.id === patch.id);

        if (!carry) {
            return;
        }

        diffPatcher.patch(carry, patch.patch);
    }
}

const defaultMutator = new ColumnMutator();

export class ColumnMutatorContext implements IMutatorContext {
    public columns: IColumn[];
    public mutator: IMutator;

    constructor(columns: IColumn[], mutator: IMutator = defaultMutator) {
        this.columns = columns;
        this.mutator = mutator;
    }
}

export default defaultMutator;
