import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "task-panel-layer"
}
const _hoisted_2 = {
  key: 0,
  class: "task-panel-layer__navigation"
}
const _hoisted_3 = { class: "task-panel-layer__content" }

import TimesSvg from '@/assets/times.svg';
import ArrowLeftSvg from '@/assets/arrow-left.svg';

// Components
import VButton from './VButton.vue';

// Other

import { useI18n } from 'vue-i18n';


export default /*@__PURE__*/_defineComponent({
  __name: 'VTaskPanelLayer',
  props: {
    header: { type: Boolean, default: false },
    visible: { type: Boolean, default: false },
},
  emits: ['update:visible'],
  setup(__props, { emit: __emit }) {

// Svg
const { t } = useI18n();
const emit = __emit;


function open() {
    emit('update:visible', true);
}

function close() {
    emit('update:visible', false);
}

return (_ctx: any,_cache: any) => {
  return (__props.visible)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (__props.header)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createVNode(VButton, {
                class: "task-panel-layer__button task-panel-layer__button--back",
                onClick: close
              }, {
                default: _withCtx(() => [
                  _createVNode(_unref(ArrowLeftSvg)),
                  _createElementVNode("span", null, _toDisplayString(_unref(t)('go-back')), 1)
                ]),
                _: 1
              }),
              _createVNode(VButton, {
                class: "button--transparent task-panel-layer__button task-panel-layer__button--close",
                onClick: close
              }, {
                default: _withCtx(() => [
                  _createVNode(_unref(TimesSvg))
                ]),
                _: 1
              })
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_3, [
          _renderSlot(_ctx.$slots, "default", {
            visible: __props.visible,
            open: open,
            close: close
          })
        ])
      ]))
    : _createCommentVNode("", true)
}
}

})