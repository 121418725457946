import { createI18n } from 'vue-i18n';
import ru from '../resources/langs/ru';
import { Plugin } from 'vue';

type Schema = typeof ru;

const i18n = createI18n<[Schema], 'ru'>({
    locale: 'ru', // set locale
    fallbackLocale: 'ru', // set fallback locale
    legacy: false,
    messages: {
        ru,
    },
    pluralizationRules: {
        /**
         * @param choice {number} a choice index given by the input to $tc: `$tc('path.to.rule', choiceIndex)`
         * @param choicesLength {number} an overall amount of available choices
         * @returns a final choice index to select plural word by
         */
        ru(choice: number, choicesLength: number): number {
            if (choice === 0) {
                return 0;
            }

            const teen = choice > 10 && choice < 20;
            const endsWithOne = choice % 10 === 1;

            if (choicesLength < 4) {
                return !teen && endsWithOne ? 1 : 2;
            }

            if (!teen && endsWithOne) {
                return 1;
            }

            if (!teen && choice % 10 >= 2 && choice % 10 <= 4) {
                return 2;
            }

            return choicesLength < 4 ? 2 : 3;
        },
    },
});

export const tryTranslate = (text: string | undefined | null, errorMessageCode = 'error'): string => {
    if (!text?.length) {
        return text ?? '';
    }

    if (text.startsWith('@|')) {
        const params = text.split('|');

        if (params.length < 2) {
            i18n.global.t(errorMessageCode).toLocaleLowerCase();
        }

        return i18n.global.te(params[1])
            ? i18n.global.t(params[1], params.slice(2))
            : i18n.global.t(errorMessageCode).toLocaleLowerCase();
    }

    return text;
};

const plugin: Plugin<unknown[]> = {
    ...i18n,
    install: (app, options) => {
        i18n.install(app, options);

        app.config.globalProperties.$tryTranslate = tryTranslate;
    },
};

declare module '@vue/runtime-core' {
    export interface ComponentCustomProperties {
        $tryTranslate: (text?: string | undefined | null, errorMessageCode?: string) => string;
    }
}

export default plugin as typeof i18n;
