import Methods from '../Methods';
import IColumn from '../Models/IColumn';
import IMutation from '../Mutations/IMutation';
import Transport, { ITransport } from '../Transports';

class ColumnService {
    private _transport: ITransport;

    constructor(transport: ITransport) {
        this._transport = transport;
    }

    public getAllAsync(): Promise<IColumn[]> {
        return this._transport.invokeAsync<undefined, IColumn[]>(Methods.Get, `/api/v1/columns`);
    }

    public findAsync(columnId: number): Promise<IColumn> {
        return this._transport.invokeAsync<undefined, IColumn>(Methods.Get, `/api/v1/columns/${columnId}`);
    }

    public createAsync(boardId: number): Promise<IMutation[]> {
        return this._transport.invokeAsync<{ boardId: number }, IMutation[]>(Methods.Post, `/api/v1/columns`, {
            boardId,
        });
    }

    public setTitleAsync(columnId: number, newTitle: string): Promise<IMutation[]> {
        return this._transport.invokeAsync<{ title: string }, IMutation[]>(
            Methods.Patch,
            `/api/v1/columns/${columnId}/title`,
            {
                title: newTitle,
            },
        );
    }
}

const service = new ColumnService(Transport);

Object.freeze(service);

export default service;
