import store from '@/store';
import { IStorage } from './IStorage';
import { MutationNames } from '@/store/mutations';
import { debounce } from 'debounce';
import { WritableComputedOptions } from 'vue';

/**
 * Local Storage saves data in the local storage.
 */
export default class LocalStorage implements IStorage {
    public static Instance = new LocalStorage();

    computed<TStoreType>(key: string, defaultValue: TStoreType): WritableComputedOptions<TStoreType> {
        return {
            get: () => this.getOrDefault<TStoreType>(key, defaultValue),
            set: (value: TStoreType) => this.set(key, value),
        };
    }

    computedDebounce<TStoreType>(key: string, defaultValue: TStoreType) {
        let debounceInstance: ((key: string, defaultValue: TStoreType) => void) | null;
        return {
            get: () => this.getOrDefault(key, defaultValue),
            set: (value: TStoreType) => {
                if (!debounceInstance) {
                    debounceInstance = debounce((key: string, defaultValue: TStoreType) => {
                        this.set(key, defaultValue);
                        debounceInstance = null;
                    }, 500);
                }

                debounceInstance(key, value);
            },
        };
    }

    get<TStoreType>(key: string): TStoreType {
        if (!store.state.storageProxy.has(key)) {
            const item = localStorage.getItem(key);

            let value = null;
            if (item) {
                try {
                    const json = JSON.parse(item);
                    value = json?.value;
                } catch (error) {
                    console.error('Invalid JSON value in the local storage: ' + item);
                }
            }

            this._setProxyValue(key, value);
        }

        return store.state.storageProxy.get(key) as TStoreType;
    }

    getOrDefault<TStoreType>(key: string, defaultValue: TStoreType): TStoreType {
        if (store.state.storageProxy.has(key)) {
            return store.state.storageProxy.get(key) as TStoreType;
        }

        const item = localStorage.getItem(key);

        let value = undefined;
        if (item) {
            try {
                const json = JSON.parse(item);
                value = json?.value;
            } catch (_) {
                console.error('Invalid JSON value in the local storage: ' + item);
            }
        }

        if (value !== undefined) {
            this.set(key, value);
        } else {
            this._setProxyValue(key, defaultValue);
        }

        return store.state.storageProxy.get(key) as TStoreType;
    }

    set<TStoreType>(key: string, value: TStoreType) {
        localStorage.setItem(key, JSON.stringify({ value }));
        this._setProxyValue(key, value);
    }

    private _setProxyValue<TStoreType>(key: string, value: TStoreType) {
        store.commit(MutationNames.StorageProxySet, { key, value });
    }
}
