import store from '@/store';
import ITask from './Models/ITask';
import { ActionNames } from '@/store/actions';
import IUser from './Models/IUser';
import IStory from './Models/IStory';
import IApprovement from './Models/IApprovement';
import ICollaborator from './Models/ICollaborator';

interface IUserStore {
    get(userId: string): IUser | undefined;
    fetchAsync(userIds: string | string[]): Promise<void>;
}

class UserMapper {
    _store: IUserStore;

    public constructor(store: IUserStore) {
        this._store = store;
    }

    async mapUsersAsync(ids?: string[]): Promise<IUser[]> {
        if (!ids) {
            return [];
        }

        await this._store.fetchAsync(ids);

        return ids.reduce((carry, id) => {
            const user = this._store.get(id);
            if (user) {
                carry.push(user);
            }
            return carry;
        }, [] as IUser[]);
    }

    async mapStoryAsync(story: IStory): Promise<IStory> {
        if (!story.actorId) {
            return story;
        }

        await this._store.fetchAsync(story.actorId);
        story.actor = this._store.get(story.actorId);

        return story;
    }

    async mapApprovementAsync(approvement: IApprovement): Promise<IApprovement> {
        if (!approvement.approverId) {
            return approvement;
        }

        await this._store.fetchAsync(approvement.approverId);
        approvement.approver = this._store.get(approvement.approverId);

        return approvement;
    }

    async mapCollaboratorAsync(collaborator: ICollaborator): Promise<ICollaborator> {
        if (!collaborator.userId) {
            return collaborator;
        }

        await this._store.fetchAsync(collaborator.userId);
        collaborator.user = this._store.get(collaborator.userId);

        return collaborator;
    }

    async mapTaskAsync(task: ITask): Promise<ITask> {
        const userIds = this._getUserIdsFromTask(task);

        await this._store.fetchAsync(Array.from(userIds));

        return this._mapTasksWithUsers([task])[0];
    }

    async mapTasksAsync(tasks: ITask[]): Promise<ITask[]> {
        const userIds = tasks.reduce((set, task) => {
            return new Set<string>([...set, ...this._getUserIdsFromTask(task)] as string[]);
        }, new Set<string>());

        await this._store.fetchAsync(Array.from(userIds));

        return this._mapTasksWithUsers(tasks);
    }

    private _mapTasksWithUsers(tasks: ITask[]): ITask[] {
        for (const task of tasks) {
            task.author = this._store.get(task.authorId);
            task.stories?.forEach((story) => {
                if (!story.actorId) {
                    return;
                }
                story.actor = this._store.get(story.actorId);
            });
            task.approvements?.forEach((approvement) => {
                if (!approvement.approverId) {
                    return;
                }
                approvement.approver = this._store.get(approvement.approverId);
            });
            task.collaborators?.forEach((collaborator) => {
                if (!collaborator.userId) {
                    return;
                }
                collaborator.user = this._store.get(collaborator.userId);
            });
        }

        return tasks;
    }

    private _getUserIdsFromTask(task: ITask): Set<string> {
        const ids = [
            task.authorId,
            ...(task.stories?.map((story) => story.actorId) ?? []),
            ...(task.approvements?.map((approvement) => approvement.approverId) ?? []),
            ...(task.collaborators?.map((collaborator) => collaborator.userId) ?? []),
        ].filter((id) => id) as string[];

        return new Set<string>(ids);
    }
}

export const defaultUserStore: IUserStore = {
    get(userId: string): IUser | undefined {
        return store.state.usersMap.get(userId);
    },
    fetchAsync(userIds: string | string[]): Promise<void> {
        const actionName =
            typeof userIds === 'string' ? ActionNames.FetchRequiredUserAsync : ActionNames.FetchRequiredUsersAsync;

        return store.dispatch(actionName, userIds);
    },
};

const mapper = new UserMapper(defaultUserStore);

export default mapper;
