import { HubConnection, HubConnectionBuilder, LogLevel, RetryContext } from '@microsoft/signalr';
import oidc from '@/vue-oidc-client';
import store from '@/store';
import { MutationNames } from '@/store/mutations';
import emitter from './Emitter';
import { EventNames } from './EventNames';

// prettier-ignore
const webSocketUrl = process.env.VUE_APP_WEB_SOCKET_URI
    ? process.env.VUE_APP_WEB_SOCKET_URI
    : window.location.origin + '/wss';

const connection: HubConnection = new HubConnectionBuilder()
    .withUrl(webSocketUrl, {
        accessTokenFactory: () => oidc.accessToken,
        withCredentials: false,
    })
    .withStatefulReconnect()
    .withAutomaticReconnect({
        nextRetryDelayInMilliseconds(retryContext: RetryContext) {
            return Math.tan(Math.min(retryContext.previousRetryCount, 5) * 0.3) * 10000;
        },
    })
    .configureLogging(LogLevel.Debug)
    .build();

connection.onclose((error) => {
    console.error('wss connection closed');
    emitter.emit(EventNames.ConnectionLoopClosed, error);
    store.commit(MutationNames.LoadApplicationError, error);
});

connection.onreconnected(() => {
    emitter.emit(EventNames.ConnectionLoopReconnected);
});

export default connection;
