<template>
    <VModalError></VModalError>
    <VModalAlert></VModalAlert>
    <VModalConfirm></VModalConfirm>
</template>

<script setup lang="ts">
// Components
import VModalError from './components/VModalError.vue';
import VModalAlert from './components/VModalAlert.vue';
import VModalConfirm from './components/VModalConfirm.vue';

// Other

removeAppLoader();

function removeAppLoader() {
    const loader = document.getElementById('app-loader');
    if (loader) {
        document.body.removeChild(loader);
    }
}
</script>
