import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, mergeProps as _mergeProps, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import CalendarSvg from '@/assets/calendar.svg';

// Components
import DatePicker, { DatePickerInstance, VueDatePickerProps } from '@vuepic/vue-datepicker';

// Other
import { DateTime } from 'luxon';
import { formatDate } from '../utils/date-utils';
import { ref, computed } from 'vue';
import { PropType } from 'vue';
import { useI18n } from 'vue-i18n';

// At the moment I don't want to localize the date-picker, and now there is no necessity
const defaultFormatWithQuarter = `DD (q кв. yyyy г.)`;


export default /*@__PURE__*/_defineComponent({
  __name: 'VDatePicker',
  props: {
    config: { type: Object, default: () => ({}) },
    inline: { type: Boolean, default: false },
    editable: { type: Boolean, default: true },
    disabled: { type: Boolean, default: false },
    modelValue: { type: [String, Date, Array] as PropType<string | string[] | Date | Date[]>, default: () => null },
    placeholder: { type: String, default: '' },
},
  emits: ['edit-request', 'input', 'update:modelValue'],
  setup(__props, { emit: __emit }) {

// Svg
const { t } = useI18n();
const emit = __emit;
const props = __props;

const datepicker = ref(null as DatePickerInstance | null);
const options = computed((): VueDatePickerProps => {
    return {
        format: (dates: Date | Date[]): string => {
            if (Array.isArray(dates)) {
                return dates.reduce((carry, date) => {
                    return carry + formatDate(date, defaultFormatWithQuarter);
                }, ' — ');
            }

            return formatDate(dates, defaultFormatWithQuarter);
        },
        offset: 4,
        locale: 'ru',
        autoApply: true,
        clearable: false,
        timePickerInline: true,
        enableTimePicker: false,
        readonly: props.disabled,
        placeholder: t('default-placeholder', 1),
        startTime: {
            hours: 0,
            minutes: 0,
        },
        textInput: {
            format: 'dd.MM.yyyy',
        },
        monthNameFormat: 'long',
        position: 'left',
        ...props.config,
    };
});
const value = computed({
    get(): string | string[] | Date | Date[] {
        return props.modelValue;
    },

    set(value: string | string[] | Date | Date[]): void {
        if (props.modelValue === value) {
            return;
        }

        if (value instanceof Date) {
            value = options.value.enableTimePicker
                ? DateTime.fromJSDate(value).toISO() ?? ''
                : DateTime.fromJSDate(value).toISODate() ?? '';
        }

        if (Array.isArray(value)) {
            value = value.map((date): string => {
                if (date instanceof Date) {
                    return options.value.enableTimePicker
                        ? DateTime.fromJSDate(date).toISO() ?? ''
                        : DateTime.fromJSDate(date).toISODate() ?? '';
                }
                return value as string;
            });
        }

        emit('input', value);
        emit('update:modelValue', value);
    },
});

function onOpen() {
    if (!props.editable) {
        datepicker.value?.closeMenu();
        emit('edit-request');
        return false;
    }
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(DatePicker), _mergeProps({
    ref_key: "datepicker",
    ref: datepicker,
    class: "date-picker",
    modelValue: value.value,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((value).value = $event))
  }, options.value, { onOpen: onOpen }), {
    "input-icon": _withCtx(() => [
      _createVNode(_unref(CalendarSvg))
    ]),
    _: 1
  }, 16, ["modelValue"]))
}
}

})