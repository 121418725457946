<template>
    <VPage>
        <VPageHeader show-third-block :title="pageTitle">
            <template v-slot:second>
                <div class="app-page-header__column app-page-header__column--tools">
                    <VTaskFilter
                        class="prevent-close"
                        :filter-types="[
                            TaskFilterTypes.Author,
                            TaskFilterTypes.Status,
                            TaskFilterTypes.Assignee,
                            TaskFilterTypes.Contributor,
                            TaskFilterTypes.AuthorOrAssignee,
                            TaskFilterTypes.Deadline,
                            TaskFilterTypes.CreationDate,
                        ]"
                        v-model="filters"
                    ></VTaskFilter>

                    <VButtonDropdown class="prevent-close" placement="left-start" :options="regularActions">
                        <DotsSvg class="rotate-90"></DotsSvg>
                    </VButtonDropdown>

                    <VSearchField class="prevent-close" debounce-mode v-model="searchString"></VSearchField>
                </div>
            </template>
        </VPageHeader>

        <VPageContent class="" vertical>
            <VTable v-model:table="table" v-model:order="order">
                <VTableDataSet v-if="tasks.length">
                    <template v-for="(task, index) in tasks" :key="task.id">
                        <template v-if="task.type === TaskType.Goal">
                            <RouterLink :to="{ name: 'goals.view', params: { goalId: task.id } }">
                                <VTableRow
                                    :table="table"
                                    :data="task"
                                    :index="index"
                                    :active="task.id.toString() == route.query.task"
                                    @contextmenu="openContextMenu($event, task)"
                                ></VTableRow>
                            </RouterLink>
                        </template>

                        <template v-else>
                            <RouterLink class="prevent-close" :to="{ query: { task: task.id } }">
                                <VTableRow
                                    :offset="+!!task.parentId * 3"
                                    :table="table"
                                    :data="task"
                                    :index="index"
                                    :active="task.id.toString() == route.query.task"
                                    @contextmenu="openContextMenu($event, task)"
                                ></VTableRow>
                            </RouterLink>
                        </template>
                    </template>
                </VTableDataSet>
            </VTable>
        </VPageContent>
    </VPage>
</template>

<script setup lang="ts">
// Svg
import DotsSvg from '@/assets/dots.svg';
import DownloadSvg from '@/assets/download.svg';

// Components
import VTaskFilter, { TaskFilterTypes } from '../components/VTaskFilter.vue';
import VSearchField from '../components/VSearchField.vue';
import VPage from '../components/VPage.vue';
import VPageHeader from '../components/VPageHeader.vue';
import VPageContent from '../components/VPageContent.vue';
import VTable from '@/components/VTable.vue';
import VTableRow from '@/components/VTableRow.vue';
import VTableDataSet from '@/components/VTableDataSet.vue';
import VButtonDropdown from '../components/VButtonDropdown.vue';

// Other
import { useI18n } from 'vue-i18n';
import { computed, markRaw, ref, toRaw, watch } from 'vue';
import Storages from '@/core/Storages';
import { IOrder } from '@/core/Values/IOrder';
import Settings from '@/core/Settings';
import TaskService, { ExportTaskRequest, QueryTaskRequest } from '@/core/Services/TaskService';
import ITask from '@/core/Models/ITask';
import UserMapper from '@/core/UserMapper';
import ObjectStorageMapper from '@/core/ObjectStorageMapper';
import MutationBus from '@/core/Mutations/MutationBus';
import IMutatorContext from '@/core/Mutations/IMutatorContext';
import { TaskMutatorContext } from '@/core/Mutators/TaskMutator';
import store from '@/store';
import { useTaskContextMenu } from '@/mixins/TaskApi';
import useTaskTableViewer, { journalColumns } from '@/mixins/TaskTableViewer';
import { useRoute } from 'vue-router';
import orderBy from 'lodash.orderby';
import TaskType from '@/core/Values/TaskType';
import { IDropdownOption } from '@/core/Values/IDropdownOption';
import { downloadFile } from '@/utils/utils';
import TaskExportSchema from '@/core/Values/TaskExportSchema';

const { t } = useI18n();
const route = useRoute();
const { openContextMenu } = useTaskContextMenu();
const table = useTaskTableViewer(Settings.UI.Table + '.statistic', {
    header: true,
    columns: journalColumns,
});

let goalsAndTasks = ref([] as ITask[]);
let searchString = ref('');
let mutatorContext = null as IMutatorContext | null;
let currentUser = store.state.user;
let pageTitle = t('statistic');
let regularActions: IDropdownOption[] = [
    {
        icon: markRaw(DownloadSvg),
        title: t('export'),
        action: () => {
            downloadFile('/api/v1/tasks/export', {
                ...tasksFilters.value,
                withColumns: table.value.columns.filter((column) => column.visible).map((column) => column.key),
                schema: TaskExportSchema.GroupedByParent,
                language: store.state.userSettings.locale,
            } as ExportTaskRequest);
        },
    },
];

const order = computed(
    Storages.Filters.computed<IOrder>(Settings.UI.Order + '.statistic', {
        orderBy: 'id',
        orderAscending: false,
    }),
);
const filters = computed(
    Storages.Filters.computed(Settings.UI.Filters + '.statistic', { value: [], formattedValue: {} }),
);
const tasks = computed(() => {
    const result: ITask[] = [];

    const mapper = new Map<number, ITask[]>();
    const orderColumn = toRaw(order).value.orderBy as keyof ITask;
    const orderAscending = toRaw(order).value.orderAscending ?? false;

    orderBy(goalsAndTasks.value, orderColumn, orderAscending ? 'asc' : 'desc').forEach((task) => {
        const key = task.parentId ?? -1;
        const group = mapper.get(key);
        if (!group) {
            mapper.set(key, [task]);
        } else {
            group.push(task);
        }
    });

    const root = mapper.get(-1);
    if (!root) {
        return result;
    }

    return root.reduce((carry, task) => {
        const children = mapper.get(task.id);
        return children ? carry.concat(task, children) : carry.concat(task);
    }, result);
});

const tasksFilters = computed(
    (): Partial<QueryTaskRequest> => ({
        ...order.value,
        ...filters.value.formattedValue,
        includes: ['comments-count', 'attachments-count', 'approvements', 'collaborators'],
        search: searchString.value ?? undefined,
    }),
);

watch(() => tasksFilters.value, fetchData, { immediate: true });

async function fetchData(): Promise<void> {
    goalsAndTasks.value = await TaskService.queryAsync(tasksFilters.value);

    // Attaching of users and preview to tasks.
    UserMapper.mapTasksAsync(goalsAndTasks.value);
    ObjectStorageMapper.mapTasksAsync(goalsAndTasks.value);

    MutationBus.deactivate(mutatorContext);
    mutatorContext = markRaw(
        new TaskMutatorContext(goalsAndTasks.value, {
            mapUsers: true,
            mapPreview: false,
            // Fetches a task if the patch contains the changes that can affect of the filters.
            fetchTask: async (patch: Partial<ITask>) => {
                if (!TaskService.matchPatch(patch, currentUser?.id as string, tasksFilters.value)) {
                    return undefined;
                }

                const tasks = await TaskService.queryAsync({
                    ...tasksFilters.value,
                    whereId: [patch.id as number],
                    perPage: 1,
                });

                if (!tasks.length) {
                    return undefined;
                }

                return UserMapper.mapTaskAsync(tasks[0]);
            },

            // Excludes tasks if they are not matching the specified filters.
            excludeTask: (task: ITask) => !TaskService.match(task, currentUser?.id as string, tasksFilters.value),
            // Ignores tasks creation if they are not matching the specified filters.
            ignoreTaskCreating: (task: ITask) => TaskService.match(task, currentUser?.id as string, tasksFilters.value),
        }),
    );
    MutationBus.activate(mutatorContext);
}
</script>

<style lang="scss"></style>
