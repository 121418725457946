<template>
    <canvas ref="canvas" class="snowf-canvas"></canvas>
</template>

<script setup lang="ts">
import { onMounted, ref } from 'vue';

type Flake = {
    x: number;
    y: number;
    r: number;
    velX: number;
    velY: number;
    swing: number;
    opacity: number;
};

const settings = {
    amount: 50,
    size: 5,
    speed: 1.5,
    wind: [-1, 0, 1][Math.round(random(0, 2))],
    color: '#fff',
    opacity: 0.8,
    swing: 1,
    image: '/snow.svg',
    zIndex: 100000,
    resize: true,
};

let canvas = ref(null as HTMLCanvasElement | null);

const img = new Image();
img.src = settings.image;

onMounted(() => {
    if (!canvas?.value) {
        return;
    }

    const context = canvas.value.getContext('2d');

    if (!context) {
        return;
    }

    var canvasHeight = canvas.value.offsetHeight;
    var canvasWidth = canvas.value.offsetWidth;
    var flakes: Flake[] = [];

    canvas.value.height = canvasHeight;
    canvas.value.width = canvasWidth;
    canvas.value.style.zIndex = settings.zIndex ? settings.zIndex.toString() : 'auto';

    function init() {
        for (var i = 0; i < settings.amount; i++) {
            flakes.push({
                x: random(0, canvasWidth),
                y: random(0, canvasHeight),
                r: random(settings.size, settings.size * 2) / 2,
                velX: 0,
                velY: random(settings.speed, settings.speed * 2),
                swing: random(0, 2 * Math.PI),
                opacity: random(0, settings.opacity),
            });
        }
        snow();
    }

    function snow() {
        if (!context) {
            return;
        }

        context.clearRect(0, 0, canvasWidth, canvasHeight);
        for (var i = 0; i < settings.amount; i++) {
            var flake = flakes[i];

            if (!settings.image) {
                context.beginPath();
                context.fillStyle = 'rgba(' + getRgb(settings.color) + ',' + flake.opacity + ')';
                context.arc(flake.x, flake.y, flake.r, 2 * Math.PI, 0);
                context.fill();
                context.closePath();
            } else {
                context.drawImage(img, flake.x - flake.r, flake.y - flake.r, 2 * flake.r, 2 * flake.r);
            }

            flake.velX =
                Math.abs(flake.velX) < Math.abs(settings.wind) ? flake.velX + settings.wind / 20 : settings.wind;
            flake.y = flake.y + flake.velY * 0.5;
            flake.x =
                flake.x +
                (settings.swing ? 0.4 * Math.cos((flake.swing += 0.03)) * flake.opacity * settings.swing : 0) +
                flake.velX * 0.5;
            if (
                flake.x > canvasWidth + flake.r ||
                flake.x < -flake.r ||
                flake.y > canvasHeight + flake.r ||
                flake.y < -flake.r
            ) {
                reset(flake);
            }
        }
        requestAnimationFrame(snow);
    }

    function reset(flake: Flake) {
        var prevR = flake.r;
        flake.r = random(settings.size, settings.size * 2) / 2;
        if (flake.x > canvasWidth + prevR) {
            flake.x = -flake.r;
            flake.y = random(0, canvasHeight);
        } else if (flake.x < -prevR) {
            flake.x = canvasWidth + flake.r;
            flake.y = random(0, canvasHeight);
        } else {
            flake.x = random(0, canvasWidth);
            flake.y = -flake.r;
        }
        flake.velX = 0;
        flake.velY = random(settings.speed, settings.speed * 2);
        flake.swing = random(0, 2 * Math.PI);
        flake.opacity = random(0, settings.opacity);
    }

    init();

    if (settings.resize) {
        window.addEventListener('resize', function () {
            if (!canvas.value) {
                return;
            }

            var H0 = canvas.value.height,
                W0 = canvas.value.width,
                H1 = canvas.value.offsetHeight,
                W1 = canvas.value.offsetWidth;

            canvas.value.height = canvasHeight = H1;
            canvas.value.width = canvasWidth = W1;
            for (var i = 0; i < settings.amount; i++) {
                var flake = flakes[i];
                flake.x = (flake.x / W0) * W1;
                flake.y = (flake.y / H0) * H1;
            }
        });
    }
});

function getRgb(str: string) {
    var rgb = '';
    if (str.indexOf('#') === 0) {
        rgb =
            str.length === 4
                ? str
                      .substr(1)
                      .split('')
                      .map(function (n) {
                          return parseInt(n.concat(n), 16);
                      })
                      .join(',')
                : str.length === 7
                ? [str.substr(1, 2), str.substr(3, 2), str.substr(5, 2)]
                      .map(function (n) {
                          return parseInt(n, 16);
                      })
                      .join(',')
                : '255,255,255';
    } else if (str.indexOf('rgb(') === 0) {
        rgb = str.substring(4, str.length - 1);
    } else {
        rgb = '255,255,255';
    }
    return rgb;
}

function random(min: number, max: number) {
    var delta = max - min;
    return max === min ? min : Math.random() * delta + min;
}
</script>

<style scoped>
.snowf-canvas {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
}
</style>
