import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, unref as _unref, createVNode as _createVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, renderSlot as _renderSlot, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "user-selector" }
const _hoisted_2 = { class: "user-selector__row user-selector__row--header" }
const _hoisted_3 = {
  key: 0,
  class: "user-selector__column"
}
const _hoisted_4 = { class: "user-selector__column-title" }
const _hoisted_5 = { class: "user-selector__column user-selector__column--select" }
const _hoisted_6 = { class: "user-selector__row user-selector__row--content" }
const _hoisted_7 = {
  key: 0,
  class: "user-selector__list"
}
const _hoisted_8 = {
  key: 1,
  class: "user-selector__text-hint"
}

import TimesSvg from '@/assets/times.svg';
import ArrowLeftSvg from '@/assets/arrow-left.svg';

// Components
import VButton from './VButton.vue';
import VSelect from './VSelect.vue';
import VUserCard from './VUserCard.vue';

// Other
import IUser from '@/core/Models/IUser';
import UserService from '@/core/Services/UserService';
import { debounce } from 'debounce';
import { computed, markRaw, ref, onMounted, PropType } from 'vue';
import { useI18n } from 'vue-i18n';


export default /*@__PURE__*/_defineComponent({
  __name: 'VUserSelector',
  props: {
    modelValue: { type: Array as PropType<IUser[]>, default: () => [] },
    title: { type: String, default: '' },
    fluid: { type: Boolean, default: false },
    multiple: { type: Boolean, default: false },
    editable: { type: Boolean, default: true },
    disabled: { type: Boolean, default: false },
    deletable: { type: Boolean, default: false },
    autofocus: { type: Boolean, default: false },
    clearable: { type: Boolean, default: true },
    actionsMargin: { type: Boolean, default: false },
    cancelButtonTitle: { type: String, default: '' },
    submitButtonTitle: { type: String, default: '' },
},
  emits: ['update:modelValue', 'edit-request', 'replaced', 'removed', 'added', 'cancel', 'submit'],
  setup(__props, { emit: __emit }) {

// Svg
const { t } = useI18n();
const emit = __emit;
const props = __props;

let initialOptions: IUser[] | null = null;

const root = ref(null as HTMLDivElement | null);
const options = ref([] as IUser[]);
const lastSelected = ref(null as IUser | null);

const users = computed({
    get: (): IUser[] => props.modelValue,
    set: (value: IUser[]) => emit('update:modelValue', value ?? []),
});
const fetchOptions = debounce(async (search: string, loading: (isLoading: boolean) => void) => {
    loading(true);
    try {
        await fetchUsersAsync(search);
    } finally {
        loading(false);
    }
}, 500);

onMounted(() => {
    if (props.autofocus) {
        (root.value?.querySelector('.vs__search') as HTMLInputElement | null)?.focus();
    }
});

function contains(user: IUser): boolean {
    return indexOf(user) !== -1;
}

function onSelected(user: IUser, force = false) {
    lastSelected.value = null;

    if (!force && !props.editable) {
        emit('edit-request', { callback: () => onSelected(user, true) });
        return;
    }

    const index = indexOf(user);
    if (index !== -1) {
        return onDeselected(user);
    }

    if (!props.multiple && users.value.length === 1) {
        emit('replaced', { oldUser: users.value[0], newUser: user });
        users.value = [user];
        return;
    }

    if (!props.multiple) {
        const remove = users.value;

        for (const user of remove) {
            emit('removed', user);
        }
    }

    emit('added', user);
    users.value = props.multiple ? [...users.value, user] : [user];
}

function onDeselected(user: IUser, force = false) {
    if (!props.clearable) {
        return;
    }

    if (!force && !props.editable) {
        emit('edit-request', { callback: () => onDeselected(user, true) });
        return;
    }

    const index = indexOf(user);

    if (index !== -1) {
        emit('removed', user);
        users.value = users.value.filter((_, i) => i !== index);
    }
}

async function fetchUsersAsync(search = '') {
    if (!search && initialOptions) {
        options.value = initialOptions;
        return;
    }

    const users = await UserService.query({
        page: 1,
        perPage: 10,
        search,
    });

    options.value = users.filter((user) => user.enabled).map((user) => markRaw(user));

    if (!initialOptions) {
        initialOptions = options.value;
    }
}

function indexOf(user: IUser): number {
    return users.value.findIndex((item) => item.id === user.id);
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (__props.title)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createElementVNode("h2", _hoisted_4, _toDisplayString(__props.title), 1)
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_5, [
        _createVNode(VSelect, {
          ref: "select",
          class: "v-select--primary v-select--visible v-select--no-option-padding",
          label: "userId",
          options: options.value,
          clearable: __props.clearable,
          filterable: false,
          placeholder: _unref(t)('add-user'),
          disabled: __props.disabled,
          onOpen: fetchUsersAsync,
          onSearch: _unref(fetchOptions),
          "onOption:selected": onSelected,
          modelValue: lastSelected.value,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((lastSelected).value = $event))
        }, {
          "no-options": _withCtx(() => [
            _createElementVNode("span", null, _toDisplayString(_unref(t)('no-options')), 1)
          ]),
          option: _withCtx((option) => [
            _createElementVNode("div", {
              class: _normalizeClass(["user-selector__select-option", {
                                'user-selector__select-option--selected': contains(option),
                            }])
            }, [
              _createVNode(VUserCard, {
                tiny: "",
                user: option
              }, null, 8, ["user"])
            ], 2)
          ]),
          _: 1
        }, 8, ["options", "clearable", "placeholder", "disabled", "onSearch", "modelValue"])
      ])
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createElementVNode("div", {
        class: _normalizeClass(["user-selector__column", {
                    'user-selector__column--center': users.value.length === 0,
                }])
      }, [
        (users.value.length > 0)
          ? (_openBlock(), _createElementBlock("ul", _hoisted_7, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(users.value, (user, index) => {
                return (_openBlock(), _createElementBlock("li", {
                  key: index,
                  class: "user-selector__item"
                }, [
                  _createVNode(VUserCard, {
                    fluid: __props.fluid,
                    user: user
                  }, null, 8, ["fluid", "user"]),
                  (!__props.disabled && __props.deletable)
                    ? (_openBlock(), _createBlock(VButton, {
                        key: 0,
                        class: "button--rounded button--transparent user-selector__button user-selector__button--deselect",
                        onClick: ($event: any) => (onDeselected(user!))
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_unref(TimesSvg))
                        ]),
                        _: 2
                      }, 1032, ["onClick"]))
                    : _createCommentVNode("", true)
                ]))
              }), 128))
            ]))
          : _createCommentVNode("", true),
        (users.value.length === 0)
          ? (_openBlock(), _createElementBlock("span", _hoisted_8, _toDisplayString(_unref(t)('users-not-selected')), 1))
          : _createCommentVNode("", true)
      ], 2)
    ]),
    _createElementVNode("div", {
      class: _normalizeClass(["user-selector__row user-selector__row--actions user-selector__row--right", {
                'user-selector__row--margin': __props.actionsMargin,
            }])
    }, [
      _renderSlot(_ctx.$slots, "actions", {}, () => [
        (__props.cancelButtonTitle)
          ? (_openBlock(), _createBlock(VButton, {
              key: 0,
              class: "button--primary button--negative button--vcenter",
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('cancel')))
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(__props.cancelButtonTitle) + " ", 1),
                _createVNode(_unref(TimesSvg))
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        (__props.submitButtonTitle)
          ? (_openBlock(), _createBlock(VButton, {
              key: 1,
              class: "button--primary button--green button--vcenter",
              onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('submit', users.value)))
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(__props.submitButtonTitle) + " ", 1),
                _createVNode(_unref(ArrowLeftSvg), { class: "rotate-180" })
              ]),
              _: 1
            }))
          : _createCommentVNode("", true)
      ])
    ], 2)
  ]))
}
}

})