<template>
    <VPopper class="task-filter" offset-distance="6" z-index="10000" placement="bottom-start" v-bind="$attrs">
        <VButton
            class="button--secondary button--default button--center button--vcenter task-filter__control"
            :class="{ 'task-filter__control--active': filtersCount > 0 }"
        >
            <FilterSvg></FilterSvg>

            <span :counter="filtersCount">
                {{ t('filter') }}
            </span>
        </VButton>

        <template #content="{ close }">
            <VGridContainer class="filter-popup">
                <VGridRow class="grid--space-between">
                    <VGridColumn class="grid--center">
                        <h5 class="filter-popup__header">
                            {{ t('filters') }}
                        </h5>
                    </VGridColumn>

                    <VGridColumn>
                        <VGridRow>
                            <VButton class="button--link button--vcenter button--margin-right" @click="clear">
                                {{ t('clear') }}
                            </VButton>

                            <VButton
                                class="button--rounded button--transparent button--vcenter button--margin-left"
                                @click="close"
                            >
                                <TimesSvg></TimesSvg>
                            </VButton>
                        </VGridRow>
                    </VGridColumn>
                </VGridRow>

                <VGridRow>
                    <VGridContainer>
                        <template v-for="(filter, index) in filters" :key="index">
                            <VGridRow class="grid--padding-top-100"> {{ filter.title }}: </VGridRow>

                            <VGridRow class="grid--padding-top-25">
                                <component
                                    :is="filter.component"
                                    v-on="filter.on"
                                    v-bind="filter.attrs"
                                    v-model="filter.modelValue"
                                ></component>
                            </VGridRow>
                        </template>

                        <VGridRow class="grid--padding-top-100">
                            <VCheckbox
                                :label="t('show-archived')"
                                v-model="showArchived"
                                @update:model-value="() => updateModelValue(true)"
                            ></VCheckbox>
                        </VGridRow>
                    </VGridContainer>
                </VGridRow>

                <VGridRow class="grid--padding-top-100" v-show="displayFilterOptions.length">
                    <VButtonDropdown placement="bottom-start" :options="displayFilterOptions">
                        <template #button>
                            <VButton class="button--secondary button--transparent">
                                <PlusSvg></PlusSvg>

                                {{ t('add-filter') }}
                            </VButton>
                        </template>
                    </VButtonDropdown>
                </VGridRow>
            </VGridContainer>
        </template>
    </VPopper>
</template>

<script lang="ts">
// Svg
import PlusSvg from '@/assets/plus.svg';
import TimesSvg from '@/assets/times.svg';
import FilterSvg from '@/assets/filter.svg';

// Components
import VPopper from 'vue3-popper';
import VButton from './VButton.vue';
import VButtonDropdown from './VButtonDropdown.vue';
import VGridRow from './VGridRow.vue';
import VGridColumn from './VGridColumn.vue';
import VGridContainer from './VGridContainer.vue';
import VSelect from './VSelect.vue';
import VCheckbox from './VCheckbox.vue';
import VDatePicker from './VDatePicker.vue';
import VUserSelectorSimple from './VUserSelectorSimple.vue';

// Other
import { defineComponent, Component, markRaw, ref } from 'vue';
import { IDropdownOption } from '@/core/Values/IDropdownOption';
import IUser from '@/core/Models/IUser';
import { QueryTaskRequest } from '@/core/Services/TaskService';
import Status from '@/core/Values/Status';
import kebabCase from 'lodash.kebabcase';
import { PropType } from 'vue';
import { required } from '@/utils/utils';
import { useI18n } from 'vue-i18n';

export interface IFilter extends IDropdownOption {
    type: TaskFilterTypes;
    on?: Record<string, () => void>;
    attrs?: Record<string, unknown>;
    modelValue: unknown;
    format: (value: unknown) => Partial<QueryTaskRequest>;
    component: Component;
}

export enum TaskFilterTypes {
    Author = 1,
    Assignee,
    Contributor,
    Status,
    Deadline,
    CreationDate,
    AuthorOrAssignee,
    WithArchived,
}

export type FilterOption = { [key: number]: IFilter };
export type FilterModelValue = { value: Partial<IFilter>[]; formattedValue: Partial<QueryTaskRequest> };

export default defineComponent({
    components: {
        PlusSvg,
        TimesSvg,
        FilterSvg,

        VPopper,
        VButton,
        VButtonDropdown,
        VDatePicker,
        VCheckbox,
        VGridRow,
        VGridColumn,
        VGridContainer,
        VUserSelectorSimple,
    },

    setup(props) {
        const { t } = useI18n();

        const showArchived = ref(props.modelValue.formattedValue?.withArchived ?? false);
        const filters = ref([] as IFilter[]);
        const filterOptions = ref({} as FilterOption);

        return { t, showArchived, filters, filterOptions };
    },

    props: {
        modelValue: {
            type: Object as PropType<FilterModelValue>,
            required: true,
            default: () => ({}),
        },
        filterTypes: { type: Array as PropType<TaskFilterTypes[]>, required: true, default: () => [] },
    },

    computed: {
        filtersCount(): number {
            // filters count + (number)showArchived (1 or 0)
            return (
                Object.values(this.filters).filter((filter) => required(filter.modelValue)).length + +this.showArchived
            );
        },

        displayFilterOptions(): IDropdownOption[] {
            return Object.values(this.filterOptions).filter(
                (option) => !this.filters.find((filter) => option.type === filter.type),
            );
        },
    },

    methods: {
        clear() {
            this.filters = [];
            this.showArchived = false;
            this.updateModelValue();
        },

        addFilter(option: IDropdownOption) {
            const filter = option as IFilter;

            if (this.filters.indexOf(filter) !== -1) {
                return;
            }

            this.filters.push({ ...filter });
            this.updateModelValue();
        },

        getModelValue(): Partial<FilterModelValue> {
            const value = this.filters.map((filter) => ({ type: filter.type, modelValue: filter.modelValue }));
            const formattedValue = this.filters.reduce(
                (carry, filter) => Object.assign(carry, filter.format(filter.modelValue)),
                {} as Record<string, unknown>,
            );

            if (this.showArchived) {
                value.push({ type: TaskFilterTypes.WithArchived, modelValue: this.showArchived });
                formattedValue.withArchived = this.showArchived;
            }

            return {
                value,
                formattedValue,
            };
        },

        updateModelValue(changed = true) {
            this.$nextTick(() => {
                const modelValue = this.getModelValue();
                this.$emit('update:modelValue', modelValue);

                if (changed) {
                    this.$emit('changed', modelValue);
                }
            });
        },

        calculateFilters() {
            if (!this.modelValue?.value) {
                this.filters = [];
                this.updateModelValue(false);
                return;
            }

            this.filters = this.modelValue.value.reduce((carry, filter) => {
                const key = filter.type as number;

                if (this.filterOptions[key]) {
                    carry.push({ ...this.filterOptions[key], ...filter });
                }

                return carry;
            }, [] as IFilter[]);
        },
    },

    watch: {
        modelValue: {
            handler: 'calculateFilters',
        },
    },

    created() {
        const options: IFilter[] = [
            {
                type: TaskFilterTypes.Author,
                action: this.addFilter,
                title: this.t('author'),
                on: {
                    'update:modelValue': this.updateModelValue,
                },
                attrs: {
                    multiple: true,
                },
                modelValue: [],
                format: (value: unknown) => (value ? { whereAuthor: (value as IUser[])?.map((item) => item.id) } : {}),
                component: markRaw(VUserSelectorSimple),
            },
            {
                type: TaskFilterTypes.Assignee,
                action: this.addFilter,
                title: this.t('assignee'),
                on: {
                    'update:modelValue': this.updateModelValue,
                },
                attrs: {
                    multiple: true,
                },
                modelValue: [],
                format: (value: unknown) =>
                    value ? { whereAssignee: (value as IUser[])?.map((item) => item.id) } : {},
                component: markRaw(VUserSelectorSimple),
            },
            {
                type: TaskFilterTypes.Contributor,
                action: this.addFilter,
                title: this.t('contributor'),
                on: {
                    'update:modelValue': this.updateModelValue,
                },
                attrs: {
                    multiple: true,
                },
                modelValue: [],
                format: (value: unknown) =>
                    value ? { whereContributor: (value as IUser[])?.map((item) => item.id) } : {},
                component: markRaw(VUserSelectorSimple),
            },
            {
                type: TaskFilterTypes.AuthorOrAssignee,
                action: this.addFilter,
                title: this.t('author') + '/' + this.t('assignee'),
                on: {
                    'update:modelValue': this.updateModelValue,
                },
                attrs: {
                    multiple: true,
                },
                modelValue: [],
                format: (value: unknown) =>
                    value ? { whereAuthorOrAssignee: (value as IUser[])?.map((item) => item.id) } : {},
                component: markRaw(VUserSelectorSimple),
            },
            {
                type: TaskFilterTypes.Status,
                action: this.addFilter,
                title: this.t('status'),
                on: {
                    'update:modelValue': this.updateModelValue,
                },
                attrs: {
                    label: 'title',
                    class: 'v-select--primary v-select--visible',
                    multiple: true,
                    placeholder: this.t('status'),
                    options: Object.keys(Status)
                        .filter((key) => !isNaN(parseInt(key)))
                        .map((key) => {
                            const status = parseInt(key);

                            return {
                                title: this.t('statuses.' + kebabCase(Status[status])),
                                value: status,
                            };
                        }),
                },
                modelValue: [],
                format: (value: unknown) =>
                    value ? { whereStatus: (value as Array<{ value: number }>)?.map((item) => item.value) } : {},
                component: markRaw(VSelect),
            },
            {
                type: TaskFilterTypes.Deadline,
                action: this.addFilter,
                title: this.t('deadline'),
                on: {
                    'update:modelValue': this.updateModelValue,
                },
                attrs: {
                    config: {
                        range: true,
                        format: 'dd.MM.yyyy',
                    },
                    class: 'date-picker--outline',
                },
                modelValue: '',
                format: (value) => (!value ? {} : { whereDeadlineBetween: value as string[] }),
                component: markRaw(VDatePicker),
            },
            {
                type: TaskFilterTypes.CreationDate,
                action: this.addFilter,
                title: this.t('creation-date'),
                on: {
                    'update:modelValue': this.updateModelValue,
                },
                attrs: {
                    config: {
                        range: true,
                        format: 'dd.MM.yyyy',
                    },
                    class: 'date-picker--outline',
                },
                modelValue: '',
                format: (value) => (!value ? {} : { whereCreatedBetween: value as string[] }),
                component: markRaw(VDatePicker),
            },
        ];

        this.filterOptions = options.reduce((carry, option) => {
            if (this.filterTypes.includes(option.type)) {
                carry[option.type] = option;
            }

            return carry;
        }, {} as FilterOption);
        this.calculateFilters();
    },
});
</script>

<style lang="scss">
.filter-popup {
    width: 18rem;

    @media (min-width: #{$breakpoint-tablet + 1}) {
        width: 22.25rem;
    }

    &__header {
        margin: 0;
        padding: 0;

        @include h5();
    }

    &__row {
        padding: 0.25rem 0;

        &--separate {
            border-top: 0.0625rem solid var(--background-tertiary);
        }
    }
}

.task-filter {
    &__control {
        line-height: 1.1875rem;

        &--active {
            color: var(--brand-green);

            span::after {
                content: attr(counter);
                flex-shrink: 0;
                margin-left: 0.25rem;
                padding: 0.1875rem 0.375rem;

                @include caption-tertiary(false);
                font-size: 0.6875rem;
                border-radius: 0.75rem;
                color: var(--text-white-primary);
                background: var(--background-color);
                --background-color: var(--brand-green);
            }
        }
    }
}
</style>
