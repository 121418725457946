<template>
    <div class="simple-textarea">
        <div
            class="simple-textarea__shadow"
            :class="{
                'simple-textarea__shadow--visibly': disabled,
            }"
        >
            {{ displayValue }}
        </div>

        <template v-if="!disabled">
            <div
                class="simple-textarea__placeholder"
                :class="{
                    'required': required,
                    'simple-textarea__placeholder--visible': !value && !focused,
                }"
                @click="focus"
            >
                {{ displayValue }}
            </div>

            <textarea
                ref="textarea"
                class="simple-textarea__input"
                autocomplete="off"
                :value="value"
                :maxlength="maxlength"
                @blur="onBlur"
                @focus="onFocus"
                @input="onInput"
                @keydown.enter.prevent
            >
            </textarea>
        </template>

        <span
            class="simple-textarea__legend"
            :class="{
                'simple-textarea__legend--negative': value.length >= maxlengthNumber,
            }"
            v-if="maxlengthNumber && value.length > maxlengthNumber * 0.8"
            v-show="focused"
        >
            <span>{{ value.length }}</span
            >/<span>{{ maxlengthNumber }}</span>
        </span>
    </div>
</template>

<script lang="ts">
// Components

// Other
import debounce from 'debounce';
import { markRaw, ref } from 'vue';
import { defineComponent } from 'vue';
import { useI18n } from 'vue-i18n';

type DebounceFunction = ((value: string) => void) & { clear(): void } & { flush(): void };

export default defineComponent({
    components: {},

    props: {
        required: { type: Boolean, default: false },
        disabled: { type: Boolean, default: false },
        editable: { type: Boolean, default: true },
        maxlength: { type: [Number, String], default: 0 },
        modelValue: { type: String, required: true },
        placeholder: { type: String, default: '' },
        trimNewLines: { type: Boolean, default: true },
        debounceMode: { type: Boolean, default: false },
        debounceInterval: { type: Number, default: 500 },
        concurrencyMode: { type: Boolean, default: false },
    },

    setup() {
        const { t } = useI18n();

        return {
            t,
            value: ref(''),
            focused: ref(false),
            debounce: ref(null as DebounceFunction | null),
        };
    },

    methods: {
        focus(): void {
            if (!this.$refs.textarea) {
                return;
            }

            (this.$refs.textarea as HTMLTextAreaElement).focus();
        },

        onBlur(): void {
            this.focused = false;
            this.debounce?.flush();
            this.debounce = null;
        },

        onFocus(): void {
            this.focused = true;
        },

        onInput(event: Event): void {
            if (!this.editable) {
                (event.target as HTMLInputElement).value = this.value;
                this.$emit('edit-request');
                return;
            }

            let value = (event.target as HTMLInputElement).value;

            if (this.trimNewLines) {
                value = value.replace(/\n/g, '');
            }

            // Fallback if the `maxlength` attribute does not work.
            if (this.maxlengthNumber && value.length > this.maxlengthNumber) {
                (event.target as HTMLInputElement).value = value.substring(0, this.maxlengthNumber);
                return;
            }

            this.value = value;

            const emit = (newValue: string) => {
                this.$emit('update:modelValue', newValue ?? '');
                this.debounce = null;
            };

            if (this.debounceMode && !this.debounce) {
                this.debounce = markRaw(debounce(emit, this.debounceInterval));
            }

            if (this.debounceMode && this.debounce) {
                this.debounce(value);
            } else {
                emit(value);
            }
        },
    },

    computed: {
        maxlengthNumber(): number {
            return parseInt(this.maxlength as string, 10) || 0;
        },

        displayValue(): string {
            if (this.value) {
                return this.value;
            }

            if (this.placeholder) {
                return this.value;
            }

            return this.t('untitled');
        },
    },

    watch: {
        modelValue(value: string) {
            // If the user is in the input window, ignore all changes.
            if (this.concurrencyMode && this.focused) {
                return;
            }

            this.value = value;
        },
    },

    created(): void {
        this.value = this.modelValue;
    },
});
</script>

<style lang="scss">
:root {
    --simple-textarea-padding-y: 0.75rem;
    --simple-textarea-padding-x: 1rem;
    --simple-textarea-border-width: 0.0625rem;
}

.simple-textarea {
    position: relative;
    flex: 1 1 auto;
    min-width: 0.0625rem;
    margin: var(--simple-textarea-padding-y) var(--simple-textarea-padding-x);
    color: var(--text-black-primary);

    &__shadow {
        display: block;
        visibility: hidden;

        &--visibly {
            visibility: visible;
        }
    }

    &__placeholder {
        display: none;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        color: var(--text-black-tertiary);

        &--visible {
            display: block;
        }
    }

    &__input {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: calc(100% - var(--simple-textarea-border-width) * 2); // 100% - border width * 2
        margin: calc(-1 * var(--simple-textarea-padding-y)) calc(-1 * var(--simple-textarea-padding-x));
        padding: var(--simple-textarea-padding-y) var(--simple-textarea-padding-x);
        resize: none;
        overflow: hidden;

        outline: none;
        font-size: inherit;
        font-weight: inherit;
        font-family: inherit;
        line-height: inherit;
        color: inherit;
        border-radius: 0.5rem;
        border-width: var(--simple-textarea-border-width);
        border-color: transparent;
        background: var(--background-color);
        --background-color: var(--background-primary);
    }

    &__input,
    &__shadow,
    &__placeholder {
        text-rendering: optimizeSpeed;
        overflow-wrap: break-word;
        box-sizing: content-box;
    }

    &--h1 {
        @include h1();
    }

    &--h2 {
        @include h2();
    }

    &--button {
        @include h2();
    }

    &--fluid {
        width: calc(
            100% + var(--simple-textarea-border-width) * 2 + var(--simple-textarea-padding-x) * 2
        ); // 100% + border * 2 + padding * 2
    }

    &__legend {
        position: absolute;
        padding: 0 0.25rem;
        right: 1.5rem;
        bottom: -1.375rem;
        background: var(--background-color);

        @include caption-secondary();

        & span:first-child {
            color: var(--brand-green);
        }

        &--negative span {
            color: var(--color-negative) !important;
        }
    }

    &:hover .simple-textarea__input {
        border-color: var(--text-black-tertiary);
    }

    & .simple-textarea__input:active,
    & .simple-textarea__input:focus-visible {
        border-color: var(--brand-dark-green);
    }

    &.error .simple-textarea__input {
        border-color: var(--color-negative);
    }
}
</style>
