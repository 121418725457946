const brandName = process.env.VUE_APP_BRAND_NAME;
const separator = ' · ';

export function setPageTitle(...titles: string[]) {
    let result = '';

    for (const title of titles) {
        result = result + title + separator;
    }

    if (brandName) {
        result = result + brandName;
    }

    document.title = result;
}

export function pullPageTitle(title = '') {
    let result = '';

    if (title === '') {
        const index = document.title.indexOf(separator);
        result = document.title.substring(index + separator.length);
    } else {
        const index = document.title.indexOf(title);

        if (index === -1) {
            return;
        }

        result = document.title.substring(index + title.length + separator.length);
    }

    document.title = result;
}

export function pushPageTitle(...titles: string[]) {
    let result = document.title;

    for (const title of titles) {
        result = title + separator + result;
    }

    document.title = result;
}

export function focusContentEditable(element: HTMLElement) {
    element.focus();

    /* eslint-disable */
    let selection: any;
    if ((selection = (document as any).selection)) {
        // IE
        const range = selection.createRange();
        range.moveToElementText(element);
        range.collapse(false);
        range.select();
    } else {
        // other browsers
        const range = document.createRange();
        range.selectNodeContents(element);
        range.collapse(false);
        selection = window.getSelection();
        selection.removeAllRanges();
        selection.addRange(range);
    }
    /* eslint-enable */
}
