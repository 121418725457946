<template>
    <div class="sidebar-explorer" v-if="projects && projects.length">
        <h4 class="sidebar-explorer__title">{{ t('all-projects') }}</h4>

        <nav>
            <ul class="sidebar-explorer__section-list">
                <template v-for="(project, index) in projects" :key="index">
                    <li class="sidebar-explorer__section-item">
                        <VProjectNavigator :project="project"></VProjectNavigator>
                    </li>
                </template>
            </ul>
        </nav>
    </div>
</template>

<script setup lang="ts">
// Components
import VProjectNavigator from './VProjectNavigator.vue';

// Other
import IProject from '@/core/Models/IProject';
import store from '@/store';
import orderBy from 'lodash.orderby';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const projects = computed((): IProject[] | null => orderBy(store.state.projects, (project) => project.title, 'desc'));
</script>

<style lang="scss">
.sidebar-explorer {
    &__title {
        margin: 0;
        @include h4();
        color: var(--text-black-primary);
    }

    &__section-item {
        margin-top: 0.75rem;
    }

    &__section-list {
        margin: 0 0;
    }
}
</style>
