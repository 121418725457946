<template>
    <div class="loader">
        <div class="pacman"></div>
    </div>
</template>

<style lang="scss">
$primaryClr: #fed75a; // Adjust this value to change the color
$pacman-zise: 4.375rem;

.pacman {
    border-radius: 50%;
    margin: 0;
    border-radius: 100em 100em 0 0;
    background: #f00;
    transform-origin: bottom;
    animation: eating-top 0.5s infinite;

    &,
    &::before {
        width: $pacman-zise;
        height: calc($pacman-zise/2);
        background: $primaryClr;
    }

    &::before {
        content: '';
        display: block;
        margin-top: calc($pacman-zise/2);
        position: absolute;
        transform-origin: top;
        border-radius: 0 0 100em 100em;
        transform: rotate(80deg);
        animation: eating-bottom 0.5s infinite;
    }

    &::after {
        position: absolute;
        border-radius: 100em;
        content: '';
        display: block;
        height: 1.25rem;
        width: 1.25rem;
        margin-top: calc(($pacman-zise / 2) - 10px);
        margin-left: calc(($pacman-zise / 2) - 10px);
        transform-origin: center;
        animation: center 0.5s infinite, ball 0.5s -0.33s infinite linear;
    }
}

@keyframes eating-top {
    0% {
        transform: rotate(-40deg);
    }
    50% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(-40deg);
    }
}

@keyframes eating-bottom {
    0% {
        transform: rotate(80deg);
    }
    50% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(80deg);
    }
}

@keyframes center {
    0% {
        transform: rotate(40deg);
    }
    50% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(40deg);
    }
}

@keyframes ball {
    0% {
        opacity: 0.7;
        box-shadow: 70px 0 0 0 $primaryClr, 120px 0 0 0 $primaryClr, 170px 0 0 0 $primaryClr, 220px 0 0 0 $primaryClr;
    }
    100% {
        box-shadow: 20px 0 0 0 $primaryClr, 70px 0 0 0 $primaryClr, 120px 0 0 0 $primaryClr, 170px 0 0 0 $primaryClr;
    }
}
</style>
