import { focusOnFirstTarget } from '@/utils/dom-utils';
import { Plugin } from 'vue';

const plugin: Plugin<unknown[]> = {
    install: (app) => {
        app.directive('focus', {
            // When the bound element is mounted into the DOM...
            mounted: focusOnFirstTarget,
        });
    },
};

export default plugin;
