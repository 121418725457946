import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import { computed } from 'vue';
import ApprovementStatus, { ApprovementTitle } from '../core/Values/ApprovementStatus';
import { useI18n } from 'vue-i18n';


export default /*@__PURE__*/_defineComponent({
  __name: 'VApprovalStatus',
  props: {
    status: { type: Number, required: true },
},
  setup(__props) {

// Other
const statusColorClassName = {
    [ApprovementStatus.Waiting]: 'status--black',
    [ApprovementStatus.Approved]: 'status--green',
    [ApprovementStatus.Disapproved]: 'status--negative',
    [ApprovementStatus.Skipped]: 'status--blurry',
};

const { t } = useI18n();
const props = __props;
const statusTitle = computed(
    () => (ApprovementTitle[props.status as keyof typeof ApprovementTitle] as string) ?? 'unknown',
);
const colorClassName = computed(
    () => statusColorClassName[props.status as unknown as keyof typeof statusColorClassName] ?? '',
);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("span", {
    class: _normalizeClass(["status", colorClassName.value])
  }, _toDisplayString(_unref(t)(statusTitle.value)), 3))
}
}

})