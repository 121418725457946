import { GetterTree } from 'vuex';
import { IState } from '.';

export class GetterNames {
    public static readonly Test = 'TEST';
}

const getters: GetterTree<IState, IState> = {};

export default getters;
