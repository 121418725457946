<template>
    <div class="users-list">
        <ul class="users-list__list" ref="list" :class="{ 'users-list__list--multiply': users.length > 1 }">
            <template v-for="(user, index) in users" :key="index">
                <li class="users-list__item">
                    <VUserCard :user="user" :picture-only="users.length > 1"></VUserCard>
                </li>
            </template>

            <template v-if="users.length === 0">
                <li class="users-list__item users-list__item--fluid">
                    <VUserCard class="button" :empty="t('default-placeholder', 2)" @click="open"></VUserCard>
                </li>
            </template>
        </ul>

        <div class="users-list__control">
            <VButton class="button--default button--secondary" @click="open" v-if="!disabled">
                {{ t('users-control') }}
            </VButton>
        </div>
    </div>
</template>

<script setup lang="ts">
// Svg

// Components
import { useI18n } from 'vue-i18n';
import VButton from './VButton.vue';
import VUserCard from './VUserCard.vue';

// Other
import IUser from '@/core/Models/IUser';
import { PropType, defineProps, defineEmits } from 'vue';

const { t } = useI18n();
const emit = defineEmits(['edit-request', 'open']);
const props = defineProps({
    users: { type: Array as PropType<IUser[]>, default: () => [] },
    editable: { type: Boolean, default: true },
    disabled: { type: Boolean, default: false },
});

function open() {
    if (props.disabled) {
        return;
    }

    if (!props.editable) {
        emit('edit-request');
        return;
    }

    emit('open');
}
</script>

<style lang="scss">
.users-list {
    display: flex;
    width: 100%;
    height: 2rem;
    overflow: hidden;

    &__list {
        flex: 2 0;
        width: 100%;
        min-width: 0;

        &--multiply {
            display: flex;
            flex-grow: 1;
            gap: 0.125rem;
            flex-wrap: wrap;
        }
    }

    &__button {
        display: inline;

        &--show {
            width: 2rem;
            height: 2rem;

            @include caption-tertiary();

            border: none;
            line-height: 2rem;
            border-radius: 50%;
            background-color: var(--background-color);
            --background-color: var(--background-tertiary);
            text-align: center;
        }
    }

    &__control {
        flex: 0 1;
        margin: 0.125rem 0 0.125rem 1rem;
    }
}
</style>
