<template>
    <div class="simple-contenteditable-wrapper">
        <div
            class="simple-contenteditable"
            :class="{ 'simple-contenteditable--disabled': disabled }"
            :placeholder="value && value !== '' ? '' : placeholder ? placeholder : t('default-placeholder', 1)"
            :contenteditable="!disabled"
            disabled="disabled"
            @blur="onBlur"
            @focus="onFocus"
            @paste="onPaste"
            @input="onInput"
            @keydown="onKeydown"
            @drop.prevent
            v-bind="$attrs"
            ref="element"
        ></div>

        <span
            class="simple-contenteditable-wrapper__legend"
            :class="{
                'simple-contenteditable-wrapper__legend--negative': value.length >= maxlengthNumber,
            }"
            v-if="maxlengthNumber && value.length > maxlengthNumber * 0.8"
            v-show="focused"
        >
            <span>{{ value.length }}</span
            >/<span>{{ maxlengthNumber }}</span>
        </span>
    </div>
</template>

<script setup lang="ts">
// Other
import { focusContentEditable } from '@/utils/document-utils';
import { debounce } from 'debounce';
import { markRaw, defineOptions, onMounted, ref, computed, watch, defineProps, defineEmits, defineExpose } from 'vue';
import { useI18n } from 'vue-i18n';

type DebounceFunction = ((value: string) => void) & { clear(): void } & { flush(): void };

defineOptions({
    inheritAttrs: false,
});

const { t } = useI18n();
const emit = defineEmits(['edit-request', 'submit', 'update:modelValue']);
const props = defineProps({
    editable: { type: Boolean, default: true },
    disabled: { type: Boolean, default: false },
    maxlength: { type: [Number, String], default: 0 },
    modelValue: { type: String, required: true, default: '' },
    placeholder: { type: String, default: '' },
    debounceMode: { type: Boolean, default: false },
    debounceInterval: { type: Number, default: 1000 },
    concurrencyMode: { type: Boolean, default: false },
});

let value = ref('');
let element = ref(null as HTMLElement | null);
let focused = ref(false);
let debounceInstance = null as DebounceFunction | null;

const maxlengthNumber = computed(() => parseInt(props.maxlength as string, 10) || 0);

defineExpose({
    focus,
    value,
    element,
    focused,
    debounceInstance,
    maxlengthNumber,
});

function getElementValue(): string {
    return element?.value?.innerText.trim() ?? '';
}

function setElementValue(value: string) {
    if (!element.value) {
        return;
    }

    if (getElementValue() === value) {
        return;
    }

    element.value.innerText = value ?? '';
}

function onPaste(event: Event) {
    if (!props.editable) {
        emit('edit-request');
        setElementValue(value.value);
        return;
    }

    const clipboardEvent = event as ClipboardEvent & { originalEvent: ClipboardEvent };
    event.preventDefault();

    const plainText = (clipboardEvent.originalEvent || clipboardEvent).clipboardData?.getData('text/plain') ?? '';
    window.document.execCommand('insertText', false, plainText);
}

function onKeydown(event: KeyboardEvent) {
    if (event.shiftKey && event.code === 'Enter') {
        event.stopPropagation();
        return;
    }

    if (event.code === 'Enter') {
        event.preventDefault();
        emit('submit', value);
    }

    /* Any Shortcut except Ctrl + V */
    const isValidShortcut = event.ctrlKey && event.keyCode != 86;
    /* Backspace - Delete - Arrow Keys - Ctrl - Shift */
    const isValidKeyCode = [8, 16, 17, 37, 38, 39, 40, 46, 116].includes(event.keyCode);
    if (maxlengthNumber.value && value.value.length >= maxlengthNumber.value && !isValidKeyCode && !isValidShortcut) {
        event.preventDefault();
    }
}

function onBlur() {
    focused.value = false;
    debounceInstance?.flush();
    debounceInstance = null;
}

function onFocus() {
    focused.value = true;
}

function onInput() {
    if (!props.editable) {
        emit('edit-request');
        setElementValue(value.value);
        return;
    }

    let elementValue = getElementValue();

    // Fallback if the `maxlength` attribute does not work.
    if (maxlengthNumber.value && elementValue.length > maxlengthNumber.value) {
        value.value = elementValue.substring(0, maxlengthNumber.value);
        setElementValue(value.value);
        // After changing the value, you need to move the carriage at the end of the line
        focus();
        return;
    }

    value.value = elementValue;
    setElementValue(value.value);

    const emitUpdate = (newValue: string) => {
        emit('update:modelValue', newValue ?? '');
        debounceInstance = null;
    };

    if (props.debounceMode && !debounceInstance) {
        debounceInstance = markRaw(debounce(emitUpdate, props.debounceInterval));
    }

    if (props.debounceMode && debounceInstance) {
        debounceInstance(elementValue);
    } else {
        emitUpdate(elementValue);
    }
}

async function focus() {
    if (!element.value) {
        return;
    }

    setTimeout(focusContentEditable, 0, element.value);
}

watch(
    () => props.modelValue,
    (newValue: string) => {
        // If the user is in the input window, ignore all changes.
        if (props.concurrencyMode && focused.value) {
            return;
        }

        value.value = newValue;
        setElementValue(newValue);
    },
);

onMounted(() => {
    value.value = props.modelValue;
    setElementValue(props.modelValue);
});
</script>

<style lang="scss">
.simple-contenteditable {
    min-height: auto;
    max-height: 12rem;
    overflow: auto;

    @include input-primary(1rem);

    &--bordered {
        border-color: var(--text-black-tertiary);
    }

    &--borderless {
        width: 100%;
        border: none;
        outline: none;
        padding: 0.75rem 1rem;

        line-height: 1.5rem;
    }

    &--great {
        min-height: 8rem;
    }

    &--unlimited {
        max-height: none;
    }

    &--disabled {
        &:focus,
        &:active,
        &:hover {
            border-color: transparent;
        }
    }

    &:focus {
        border-color: var(--brand-green);
    }

    &::before {
        content: attr(placeholder);

        @include h6();
        color: var(--text-black-tertiary);
    }

    &.error {
        border-color: var(--color-negative);
    }
}

.simple-contenteditable-wrapper {
    position: relative;
    width: 100%;

    &__legend {
        position: absolute;
        padding: 0 0.25rem;
        right: 1.75rem;
        bottom: -0.5rem;
        background: var(--background-color);

        @include caption-tertiary();

        & span:first-child {
            color: var(--brand-green);
        }

        &--negative span {
            color: var(--color-negative) !important;
        }
    }
}
</style>
