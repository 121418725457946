import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import oidc from '../vue-oidc-client';
import qs from 'qs';

const BASE_URL = process.env.VUE_APP_API_BASE_URL ?? '';

function createAxiosInstance(baseURL = BASE_URL) {
    const instance = axios.create({
        baseURL,
    });

    instance.defaults.headers.common.Accept = 'application/json';
    instance.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
    instance.defaults.paramsSerializer = (params) => qs.stringify(params);

    return instance;
}

function useJwtAuthorization(instance: AxiosInstance) {
    instance.interceptors.request.use((request) => {
        if (oidc.accessToken) {
            request.headers.authorization = 'Bearer ' + oidc.accessToken;
        }

        return request;
    });

    instance.interceptors.response.use(undefined, async (error) => {
        if (error.config?.params?.__retry === true) {
            return Promise.reject(error);
        }

        if (error && error.response && error.response.status === 401) {
            try {
                await oidc.mgr.signinSilent();
            } catch (error) {
                await oidc.mgr.signinRedirect();
            }

            const request = error.config as AxiosRequestConfig;

            request.params = { ...request.params, __retry: true };

            return instance.request(request);
        }

        return Promise.reject(error);
    });

    return instance;
}

function createApiInstance(baseURL: string) {
    const client = createAxiosInstance(baseURL);

    useJwtAuthorization(client);

    return client;
}

export { createApiInstance, createAxiosInstance, useJwtAuthorization };
export default createApiInstance(BASE_URL);
