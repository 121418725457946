import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, unref as _unref, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createVNode as _createVNode } from "vue"

const _hoisted_1 = ["task-id"]
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "goal-card__content" }
const _hoisted_4 = { class: "goal-card__row goal-card__row--header goal-card__row--horizontal goal-card__row--space-between" }
const _hoisted_5 = { class: "goal-card__row goal-card__row--status" }
const _hoisted_6 = { class: "goal-card__row goal-card__row--horizontal goal-card__row--gap" }
const _hoisted_7 = {
  key: 0,
  class: "goal-card__hint"
}
const _hoisted_8 = {
  key: 0,
  class: "goal-card__counter"
}
const _hoisted_9 = { class: "goal-card__hint goal-card__hint--space-right" }
const _hoisted_10 = {
  key: 1,
  class: "goal-card__hint"
}
const _hoisted_11 = {
  key: 2,
  class: "goal-card__hint"
}
const _hoisted_12 = {
  key: 3,
  class: "goal-card__hint"
}

import TextSvg from '@/assets/text.svg';
import EmailSvg from '@/assets/email.svg';
import AttachmentSvg from '@/assets/attachment.svg';

// Components
import VTaskStatus from './VTaskStatus.vue';
import VUserCard from './VUserCard.vue';

// Other
import ITask from '@/core/Models/ITask';
import StoryType from '@/core/Values/StoryType';
import { formatDateOnly } from '@/utils/date-utils';
import { DateTime } from 'luxon';
import Status from '@/core/Values/Status';
import CollaboratorRole from '@/core/Values/CollaboratorRole';
import ICollaborator from '@/core/Models/ICollaborator';
import { PropType, computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useTaskContextMenu } from '@/mixins/TaskApi';
import { useRoute } from 'vue-router';


export default /*@__PURE__*/_defineComponent({
  __name: 'VGoalCard',
  props: {
    goal: { type: Object as PropType<ITask>, required: true },
},
  setup(__props) {

// Svg
const props = __props;

const goal = ref(props.goal);

const { t } = useI18n();
const route = useRoute();
const { openContextMenu } = useTaskContextMenu();

const numberOfComments = computed((): number => {
    if (typeof props.goal.commentsCount === 'number') {
        return props.goal.commentsCount;
    }

    return props.goal.stories?.filter((story) => story.type === StoryType.Comment)?.length ?? 0;
});

const numberOfAttachments = computed((): number => {
    if (typeof props.goal.attachmentsCount === 'number') {
        return props.goal.attachmentsCount;
    }

    return props.goal.stories?.filter((story) => story.type === StoryType.Attachment)?.length ?? 0;
});

const hasDescription = computed((): boolean => {
    return !!props.goal.description;
});

const formattedDeadline = computed((): string => {
    if (!props.goal?.deadline) {
        return '';
    }

    return formatDateOnly(props.goal.deadline);
});

const isExpired = computed((): boolean => {
    if (!props.goal.deadline || props.goal.status !== Status.InProgress) {
        return false;
    }

    return DateTime.fromISO(props.goal.deadline, { zone: 'UTC' }) < DateTime.now();
});

const isCompleted = computed((): boolean => {
    return props.goal.status === Status.Completed;
});

const isSelected = computed((): boolean => {
    return props.goal.id === parseInt(route.query.task as string, 10);
});

const contributors = computed((): ICollaborator[] => {
    return props.goal.collaborators?.filter((collaborator) => collaborator.role === CollaboratorRole.Contributor) ?? [];
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["goal-card prevent-close", {
            'goal-card--expired': isExpired.value,
            'goal-card--completed': isCompleted.value,
            'goal-card--selected': isSelected.value,
        }]),
    "task-id": goal.value.id,
    onContextmenu: _cache[0] || (_cache[0] = ($event: any) => (_unref(openContextMenu)($event, goal.value)))
  }, [
    (goal.value.previewDownloadUri)
      ? (_openBlock(), _createElementBlock("img", {
          key: 0,
          class: "goal-card__preview",
          src: goal.value.previewDownloadUri,
          alt: ""
        }, null, 8, _hoisted_2))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("p", {
          class: _normalizeClass(["goal-card__row goal-card__title", {
                        'goal-card__title--blurry': !goal.value.title,
                    }])
        }, _toDisplayString(goal.value.title ? goal.value.title : _unref(t)('untitled')), 3),
        _createElementVNode("div", _hoisted_5, [
          _createVNode(VTaskStatus, { task: goal.value }, null, 8, ["task"])
        ])
      ]),
      _createElementVNode("div", _hoisted_6, [
        (contributors.value.length > 0 && contributors.value[0].user)
          ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
              _createVNode(VUserCard, {
                user: contributors.value[0].user,
                "picture-only": "",
                tiny: ""
              }, null, 8, ["user"]),
              (contributors.value.length > 1)
                ? (_openBlock(), _createElementBlock("span", _hoisted_8, " +" + _toDisplayString(contributors.value.length - 1), 1))
                : _createCommentVNode("", true)
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("span", _hoisted_9, _toDisplayString(formattedDeadline.value ? formattedDeadline.value : _unref(t)('no-deadline')), 1),
        (hasDescription.value)
          ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
              _createVNode(_unref(TextSvg))
            ]))
          : _createCommentVNode("", true),
        (numberOfComments.value > 0)
          ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
              _createVNode(_unref(EmailSvg)),
              _createElementVNode("span", null, _toDisplayString(numberOfComments.value), 1)
            ]))
          : _createCommentVNode("", true),
        (numberOfAttachments.value > 0)
          ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
              _createVNode(_unref(AttachmentSvg))
            ]))
          : _createCommentVNode("", true)
      ])
    ])
  ], 42, _hoisted_1))
}
}

})