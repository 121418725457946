import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, unref as _unref, createBlock as _createBlock, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "inline-link" }
const _hoisted_2 = {
  key: 0,
  class: "inline-link__subject"
}

import LinkSvg from '@/assets/link.svg';

// Other
import { PropType } from 'vue';
import { RouteLocationRaw } from 'vue-router';


export default /*@__PURE__*/_defineComponent({
  __name: 'VInlineLink',
  props: {
    to: { type: Object as PropType<RouteLocationRaw>, required: true },
    icon: { type: Boolean, default: true },
    subject: { type: String, default: '' },
},
  setup(__props) {

// Svg


return (_ctx: any,_cache: any) => {
  const _component_RouterLink = _resolveComponent("RouterLink")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (__props.subject)
      ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(__props.subject), 1))
      : _createCommentVNode("", true),
    _createVNode(_component_RouterLink, {
      class: "inline-link__button",
      to: __props.to
    }, {
      default: _withCtx(() => [
        _renderSlot(_ctx.$slots, "default"),
        (__props.icon)
          ? (_openBlock(), _createBlock(_unref(LinkSvg), {
              key: 0,
              class: "inline-link__button-icon"
            }))
          : _createCommentVNode("", true)
      ]),
      _: 3
    }, 8, ["to"])
  ]))
}
}

})