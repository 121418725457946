import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "sidebar-button__title" }

import { PropType } from 'vue';
import { RouteLocationNamedRaw, RouteLocationPathRaw } from 'vue-router';


export default /*@__PURE__*/_defineComponent({
  __name: 'VAppSidebarButton',
  props: {
    route: { type: [String, Object] as PropType<string | RouteLocationPathRaw | RouteLocationNamedRaw>, default: '' },
    counter: { type: [Number, String], default: 0 },
    attention: { type: Boolean, default: false },
},
  setup(__props) {

// Other


return (_ctx: any,_cache: any) => {
  const _component_RouterLink = _resolveComponent("RouterLink")!

  return (_openBlock(), _createBlock(_component_RouterLink, {
    to: __props.route,
    class: _normalizeClass(["button button--fluid button--vcenter sidebar-button prevent-close", {
            'sidebar-button--attention': __props.attention,
            'sidebar-button--with-counter': __props.counter != 0, // not !==
        }]),
    counter: __props.counter
  }, {
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "icon"),
      _createElementVNode("span", _hoisted_1, [
        _renderSlot(_ctx.$slots, "default")
      ])
    ]),
    _: 3
  }, 8, ["to", "class", "counter"]))
}
}

})