import Methods from '../Methods';
import Transport, { ITransport } from '../Transports';
import IObjectStoreModel from '../Values/IObjectStoreModel';
import IRequestObjectDownloadUri from '../Values/IRequestObjectDownloadUri';

class ObjectStorage {
    private _transport: ITransport;

    constructor(transport: ITransport) {
        this._transport = transport;
    }

    public getDownloadUri(object: IRequestObjectDownloadUri): Promise<IObjectStoreModel> {
        return this._transport.invokeAsync<undefined, IObjectStoreModel>(
            Methods.Get,
            `/api/v1/object-storage/objects/${object.objectName}/${object.downloadName}`,
        );
    }

    public getDownloadUris(objects: IRequestObjectDownloadUri[]): Promise<IObjectStoreModel[]> {
        return this._transport.invokeAsync<IRequestObjectDownloadUri[], IObjectStoreModel[]>(
            Methods.Post,
            `/api/v1/object-storage/objects/query`,
            objects,
        );
    }
}

const service = new ObjectStorage(Transport);

Object.freeze(service);

export default service;
