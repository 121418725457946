import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import { computed } from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'VPage',
  props: {
    fluid: { type: Boolean, default: false },
    layer: { type: Boolean, default: false },
    noShrink: { type: Boolean, default: false },
},
  setup(__props) {

// Svg
// ...

// Components
// ...

// Other
const props = __props;

const classes = computed(() => ({
    'app-page--layer': props.layer,
    'app-page--no-shrink': props.noShrink,
}));

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["app-page", classes.value])
  }, [
    _renderSlot(_ctx.$slots, "default")
  ], 2))
}
}

})