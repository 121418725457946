import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createVNode as _createVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "sidebar-explorer"
}
const _hoisted_2 = { class: "sidebar-explorer__title" }
const _hoisted_3 = { class: "sidebar-explorer__section-list" }

import VProjectNavigator from './VProjectNavigator.vue';

// Other
import IProject from '@/core/Models/IProject';
import store from '@/store';
import orderBy from 'lodash.orderby';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';


export default /*@__PURE__*/_defineComponent({
  __name: 'VAppSidebarExplorer',
  setup(__props) {

// Components
const { t } = useI18n();

const projects = computed((): IProject[] | null => orderBy(store.state.projects, (project) => project.title, 'desc'));

return (_ctx: any,_cache: any) => {
  return (projects.value && projects.value.length)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("h4", _hoisted_2, _toDisplayString(_unref(t)('all-projects')), 1),
        _createElementVNode("nav", null, [
          _createElementVNode("ul", _hoisted_3, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(projects.value, (project, index) => {
              return (_openBlock(), _createElementBlock("li", {
                key: index,
                class: "sidebar-explorer__section-item"
              }, [
                _createVNode(VProjectNavigator, { project: project }, null, 8, ["project"])
              ]))
            }), 128))
          ])
        ])
      ]))
    : _createCommentVNode("", true)
}
}

})