import VCheckbox from '@/components/VCheckbox.vue';
import VSelect from '@/components/VSelect.vue';
import IPageSettings, { IPageSettingsOption, TaskViewMode } from '@/core/Values/IPageSettings';
import { markRaw } from 'vue';
import vueI18n from '@/plugins/VueI18n';

type SelectOption = {
    title: string;
    value: string;
};

const defaultSettings = {
    viewMode: markRaw({
        title: vueI18n.global.t('task-view-mode'),
        component: markRaw(VSelect),
        save: (settings: IPageSettings, modelValue: TaskViewMode) => (settings.viewMode = modelValue),
        read: (settings: IPageSettings) => settings.viewMode ?? 'board',
        attrs: {
            label: 'title',
            class: 'v-select--primary v-select--visible',
            placeholder: vueI18n.global.t('status'),
            options: [
                {
                    title: vueI18n.global.t('task-view-mode-board'),
                    value: 'board',
                },
                {
                    title: vueI18n.global.t('task-view-mode-table'),
                    value: 'table',
                },
            ] as SelectOption[],
            reduce: (value: SelectOption) => value.value,
        },
        changeTrigger: 'update:modelValue',
    } as IPageSettingsOption<TaskViewMode>),
    fullscreen: markRaw({
        component: markRaw(VCheckbox),
        save: (settings: IPageSettings, value: boolean) => (settings.fullscreen = !!value),
        read: (settings: IPageSettings) => settings.fullscreen ?? false,
        attrs: {
            label: vueI18n.global.t('page-fullscreen'),
        },
        changeTrigger: 'update:modelValue',
    } as IPageSettingsOption<boolean>),
};

export function usePageOptions() {
    return defaultSettings as Record<keyof typeof defaultSettings, IPageSettingsOption>;
}
