import emitter from '@/core/Emitter';
import { EventNames } from '@/core/EventNames';
import AlertContext from '@/core/Values/AlertContext';
import ConfirmContext from '@/core/Values/ConfirmContext';
import ShowImageContext from '@/core/Values/ShowImageContext';

const env = process.env.NODE_ENV;

export function $error(error: unknown) {
    if (env !== 'production') {
        console.error(error);
    }

    emitter.emit(EventNames.Error, error);
}

export function $alert(context: AlertContext) {
    emitter.emit(EventNames.Alert, context);
}

export function $confirm(context: ConfirmContext) {
    emitter.emit(EventNames.Confirm, context);
}

export function $showImage(context: ShowImageContext) {
    emitter.emit(EventNames.ShowImage, context);
}
