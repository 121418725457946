import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment, resolveDirective as _resolveDirective, withDirectives as _withDirectives, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "goal-panel" }
const _hoisted_2 = { class: "goal-panel__column goal-panel__column--primary" }
const _hoisted_3 = {
  key: 0,
  class: "goal-panel__block goal-panel__block--center goal-panel__block--pillow"
}
const _hoisted_4 = { class: "goal-panel__alert" }
const _hoisted_5 = { class: "goal-panel__block goal-panel__block--semi-padding goal-panel__block--pillow" }
const _hoisted_6 = { class: "goal-panel__row" }
const _hoisted_7 = { class: "goal-panel__row goal-panel__row--padding" }
const _hoisted_8 = { class: "goal-panel__row goal-panel__row--padding goal-panel__row--separate" }
const _hoisted_9 = { class: "info-panel goal-panel__info-panel" }
const _hoisted_10 = { class: "info-panel__row" }
const _hoisted_11 = { class: "task-panel__label info-panel__label info-panel__label--vcenter inline-space-75-right" }
const _hoisted_12 = { class: "info-panel__content info-panel__content--vcenter" }
const _hoisted_13 = { class: "info-panel__row" }
const _hoisted_14 = { class: "goal-panel__label info-panel__label info-panel__label--vcenter inline-space-75-right" }
const _hoisted_15 = { class: "info-panel__content info-panel__content--vcenter info-panel__content--buttons" }
const _hoisted_16 = { class: "info-panel__row" }
const _hoisted_17 = { class: "goal-panel__label info-panel__label info-panel__label--vcenter inline-space-75-right" }
const _hoisted_18 = { class: "info-panel__content" }
const _hoisted_19 = { class: "goal-panel__row goal-panel__row--padding goal-panel__row--separate" }
const _hoisted_20 = { class: "goal-panel__label goal-panel__label--separate required" }
const _hoisted_21 = { class: "goal-panel__row goal-panel__row--padding goal-panel__row--separate" }
const _hoisted_22 = { class: "goal-panel__label goal-panel__label--separate-x2" }
const _hoisted_23 = { class: "goal-panel__block goal-panel__block--nesting goal-panel__block--no-padding goal-panel__block--collaborators goal-panel__block--fixed-height" }
const _hoisted_24 = { class: "goal-panel__sub-block goal-panel__sub-block--pillow goal-panel__sub-block--collaborators" }
const _hoisted_25 = { class: "goal-panel__button-block" }
const _hoisted_26 = { class: "column__list column__list--no-padding" }
const _hoisted_27 = {
  key: 1,
  id: "approvers-block",
  ref: "approversBlock",
  class: "goal-panel__block goal-panel__block--pillow goal-panel__block--fixed-height goal-panel__block--flex"
}
const _hoisted_28 = {
  key: 2,
  ref: "authorBlock",
  class: "goal-panel__block goal-panel__block--pillow goal-panel__block--fixed-height goal-panel__block--flex"
}
const _hoisted_29 = {
  key: 3,
  class: "goal-panel__block goal-panel__block--pillow"
}
const _hoisted_30 = { class: "goal-panel__header goal-panel__header--title" }
const _hoisted_31 = { class: "goal-panel__stories" }
const _hoisted_32 = { class: "goal-panel__header goal-panel__header--selector" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NoticeSvg = _resolveComponent("NoticeSvg")!
  const _component_VTextarea = _resolveComponent("VTextarea")!
  const _component_VInlineLink = _resolveComponent("VInlineLink")!
  const _component_VUsersList = _resolveComponent("VUsersList")!
  const _component_VTaskStatus = _resolveComponent("VTaskStatus")!
  const _component_VButton = _resolveComponent("VButton")!
  const _component_DotsSvg = _resolveComponent("DotsSvg")!
  const _component_VButtonDropdown = _resolveComponent("VButtonDropdown")!
  const _component_VDatePicker = _resolveComponent("VDatePicker")!
  const _component_VContentEditable = _resolveComponent("VContentEditable")!
  const _component_VFileList = _resolveComponent("VFileList")!
  const _component_VCollaboratorsList = _resolveComponent("VCollaboratorsList")!
  const _component_VTaskCard = _resolveComponent("VTaskCard")!
  const _component_RouterLink = _resolveComponent("RouterLink")!
  const _component_VColumn = _resolveComponent("VColumn")!
  const _component_VUserSelector = _resolveComponent("VUserSelector")!
  const _component_VApprovementList = _resolveComponent("VApprovementList")!
  const _component_TimesSvg = _resolveComponent("TimesSvg")!
  const _component_VSelect = _resolveComponent("VSelect")!
  const _component_VFeedStory = _resolveComponent("VFeedStory")!
  const _component_VChatInput = _resolveComponent("VChatInput")!
  const _directive_focus = _resolveDirective("focus")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.goal.archivedAt)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createVNode(_component_NoticeSvg, { class: "goal-panel__alert-icon" }),
            _createElementVNode("h4", _hoisted_4, _toDisplayString(_ctx.t('goal-archived')), 1)
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", _hoisted_6, [
          _createVNode(_component_VTextarea, {
            class: _normalizeClass(["simple-textarea--h2", {
                            error: _ctx.formErrors?.title,
                        }]),
            required: "",
            "debounce-mode": "",
            "concurrency-mode": "",
            maxlength: "512",
            editable: _ctx.editable,
            disabled: !_ctx.canUpdate || _ctx.disabled,
            "model-value": _ctx.goal.title,
            onEditRequest: _ctx.confirmToDraft,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_ctx.changeTitleAsync(_ctx.goal, $event)))
          }, null, 8, ["class", "editable", "disabled", "model-value", "onEditRequest"])
        ]),
        _createElementVNode("div", _hoisted_7, [
          (_ctx.project && _ctx.board)
            ? (_openBlock(), _createBlock(_component_VInlineLink, {
                key: 0,
                class: "prevent-close",
                to: {
                            name: 'projects.board',
                            params: {
                                projectId: _ctx.project.id,
                                boardId: _ctx.board.id,
                            },
                        },
                subject: _ctx.t('board-link-text')
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.tryTranslate(_ctx.board.title)) + ", " + _toDisplayString(_ctx.tryTranslate(_ctx.project.title)), 1)
                ]),
                _: 1
              }, 8, ["to", "subject"]))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_8, [
          _createElementVNode("ul", _hoisted_9, [
            _createElementVNode("li", _hoisted_10, [
              _createElementVNode("span", _hoisted_11, _toDisplayString(_ctx.t('author')), 1),
              _createElementVNode("div", _hoisted_12, [
                _createVNode(_component_VUsersList, {
                  users: [_ctx.goal.author!],
                  editable: _ctx.canUpdateAuthor && !_ctx.disabled,
                  disabled: !_ctx.canUpdateAuthor || _ctx.disabled,
                  onOpen: _ctx.showAuthorSelector
                }, null, 8, ["users", "editable", "disabled", "onOpen"])
              ])
            ]),
            _createElementVNode("li", _hoisted_13, [
              _createElementVNode("span", _hoisted_14, _toDisplayString(_ctx.t('status')), 1),
              _createElementVNode("div", _hoisted_15, [
                _createVNode(_component_VTaskStatus, {
                  class: "status--space-right",
                  task: _ctx.goal
                }, null, 8, ["task"]),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.actions, (action, index) => {
                  return (_openBlock(), _createElementBlock(_Fragment, { key: index }, [
                    (action.isMainAction)
                      ? (_openBlock(), _createBlock(_component_VButton, {
                          key: 0,
                          class: _normalizeClass(action.panelClasses),
                          onClick: action.action
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(action.title), 1)
                          ]),
                          _: 2
                        }, 1032, ["class", "onClick"]))
                      : _createCommentVNode("", true)
                  ], 64))
                }), 128)),
                (_ctx.actions.length)
                  ? (_openBlock(), _createBlock(_component_VButtonDropdown, {
                      key: 0,
                      options: _ctx.actions,
                      placement: "left-start"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_DotsSvg)
                      ]),
                      _: 1
                    }, 8, ["options"]))
                  : _createCommentVNode("", true)
              ])
            ]),
            _createElementVNode("li", _hoisted_16, [
              _createElementVNode("span", _hoisted_17, _toDisplayString(_ctx.t('deadline')), 1),
              _createElementVNode("div", _hoisted_18, [
                _createVNode(_component_VDatePicker, {
                  class: "inline-50-x inline-fluid",
                  editable: _ctx.editable,
                  disabled: !_ctx.canUpdate || _ctx.disabled,
                  "model-value": _ctx.goal.deadline,
                  onEditRequest: _ctx.confirmToDraft,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.changeDeadlineAsync(_ctx.goal, $event)))
                }, null, 8, ["editable", "disabled", "model-value", "onEditRequest"])
              ])
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_19, [
          _createElementVNode("span", _hoisted_20, _toDisplayString(_ctx.t('description')), 1),
          _createVNode(_component_VContentEditable, {
            class: _normalizeClass(["simple-contenteditable--great simple-contenteditable--unlimited inline-100-x inline-fluid", {
                            error: _ctx.formErrors?.description,
                        }]),
            "debounce-mode": "",
            "concurrency-mode": "",
            maxlength: "4096",
            editable: _ctx.editable,
            disabled: !_ctx.canUpdate || _ctx.disabled,
            "model-value": _ctx.goal.description,
            onEditRequest: _ctx.confirmToDraft,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (_ctx.changeDescriptionAsync(_ctx.goal, $event)))
          }, null, 8, ["class", "editable", "disabled", "model-value", "onEditRequest"])
        ]),
        _createElementVNode("div", _hoisted_21, [
          _createElementVNode("span", _hoisted_22, _toDisplayString(_ctx.t('attachments')), 1),
          _createVNode(_component_VFileList, {
            uploader: _ctx.uploader,
            attachments: _ctx.attachments,
            onRemoved: _cache[3] || (_cache[3] = ($event: any) => (_ctx.removeStoryAsync(_ctx.goal, $event))),
            onUploaded: _ctx.uploadedAttachmentHandler
          }, null, 8, ["uploader", "attachments", "onUploaded"])
        ])
      ]),
      _createElementVNode("div", _hoisted_23, [
        _createElementVNode("div", _hoisted_24, [
          _createElementVNode("div", _hoisted_25, [
            _createVNode(_component_VButton, {
              class: _normalizeClass(["goal-panel__button goal-panel__button--collaborators", {
                                'goal-panel__button--active': _ctx.collaboratorsBlockRole === _ctx.roles.Assignee,
                            }]),
              onClick: _ctx.showMangers
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.t('assignees')), 1)
              ]),
              _: 1
            }, 8, ["class", "onClick"]),
            _createVNode(_component_VButton, {
              class: _normalizeClass(["goal-panel__button goal-panel__button--collaborators", {
                                'goal-panel__button--active': _ctx.collaboratorsBlockRole === _ctx.roles.Collaborator,
                            }]),
              onClick: _ctx.showCollaborators
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.t('collaborators')), 1)
              ]),
              _: 1
            }, 8, ["class", "onClick"])
          ]),
          _createVNode(_component_VCollaboratorsList, {
            fluid: "",
            multiple: "",
            role: _ctx.collaboratorsBlockRole,
            taskId: _ctx.goal.id,
            editable: _ctx.editable || _ctx.collaboratorsBlockRole !== _ctx.roles.Assignee,
            disabled: !_ctx.canManageCollaborators || _ctx.disabled,
            collaborators: _ctx.goal.collaborators,
            onEditRequest: _ctx.confirmToDraft,
            onAdded: _cache[4] || (_cache[4] = ($event: any) => (_ctx.addCollaboratorAsync(_ctx.goal, $event))),
            onRemoved: _cache[5] || (_cache[5] = ($event: any) => (_ctx.removeCollaboratorAsync(_ctx.goal, $event))),
            onReplaced: _cache[6] || (_cache[6] = ($event: any) => (_ctx.replaceCollaboratorAsync(_ctx.goal, $event)))
          }, null, 8, ["role", "taskId", "editable", "disabled", "collaborators", "onEditRequest"])
        ]),
        _createVNode(_component_VColumn, {
          class: "goal-panel__sub-block goal-panel__sub-block--no-padding goal-panel__sub-block--tasks",
          "wheel-propagation": "",
          column: {
                        id: -1,
                        boardId: -1,
                        projectId: -1,
                        order: -1,
                        title: _ctx.t('task', 3),
                    },
          "create-button": !_ctx.disabled,
          onCreateEmptyTask: _cache[7] || (_cache[7] = ($event: any) => (_ctx.$emit('createEmptyTask', _ctx.goal)))
        }, {
          default: _withCtx(() => [
            _createElementVNode("ul", _hoisted_26, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tasks, (task, taskIndex) => {
                return (_openBlock(), _createElementBlock("li", {
                  key: taskIndex,
                  class: "column__item"
                }, [
                  _createVNode(_component_RouterLink, {
                    to: { query: { task: task.id } }
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_VTaskCard, { task: task }, null, 8, ["task"])
                    ]),
                    _: 2
                  }, 1032, ["to"])
                ]))
              }), 128))
            ])
          ]),
          _: 1
        }, 8, ["column", "create-button"])
      ]),
      (_ctx.showApproversBlock)
        ? (_openBlock(), _createElementBlock("div", _hoisted_27, [
            _createVNode(_component_VUserSelector, {
              multiple: "",
              deletable: "",
              title: _ctx.t('approvers'),
              "cancel-button-title": _ctx.t('cancel'),
              "submit-button-title": _ctx.t('send-for-approvement'),
              onCancel: _ctx.hideApproverSelector,
              onSubmit: _ctx.startApprovementProcess,
              modelValue: _ctx.showApproversBlockApprovers,
              "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.showApproversBlockApprovers) = $event))
            }, null, 8, ["title", "cancel-button-title", "submit-button-title", "onCancel", "onSubmit", "modelValue"])
          ], 512))
        : _createCommentVNode("", true),
      (_ctx.showAuthorBlock)
        ? (_openBlock(), _createElementBlock("div", _hoisted_28, [
            _createVNode(_component_VUserSelector, {
              title: _ctx.t('author'),
              clearable: false,
              "cancel-button-title": _ctx.t('cancel'),
              "submit-button-title": _ctx.t('apply'),
              onCancel: _ctx.hideAuthorSelector,
              onSubmit: _ctx.setAuthor,
              modelValue: _ctx.showAuthorBlockAuthors,
              "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.showAuthorBlockAuthors) = $event))
            }, null, 8, ["title", "cancel-button-title", "submit-button-title", "onCancel", "onSubmit", "modelValue"])
          ], 512))
        : _createCommentVNode("", true),
      (_ctx.goal.approvements && _ctx.goal.approvements.length)
        ? (_openBlock(), _createElementBlock("div", _hoisted_29, [
            _createElementVNode("h3", _hoisted_30, _toDisplayString(_ctx.t('approval-list-title')), 1),
            _createVNode(_component_VApprovementList, {
              "model-value": _ctx.goal.approvements,
              onApprove: _cache[10] || (_cache[10] = ($event: any) => (_ctx.approveAsync(_ctx.goal, $event))),
              onDisapprove: _cache[11] || (_cache[11] = ($event: any) => (_ctx.disapproveAsync(_ctx.goal, $event)))
            }, null, 8, ["model-value"])
          ]))
        : _createCommentVNode("", true)
    ]),
    (_ctx.showStories)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(["goal-panel__column goal-panel__column--secondary", { 'goal-panel__column--top-layer': _ctx.$device.isTablet }]),
          onClick: _cache[18] || (_cache[18] = ($event: any) => (_ctx.$emit('update:showStories', _ctx.$device.isTablet ? !_ctx.showStories : _ctx.showStories)))
        }, [
          _createElementVNode("div", {
            class: "goal-panel__block goal-panel__block--no-padding goal-panel__block--stories goal-panel__block--sticky goal-panel__block--pillow",
            onClick: _cache[17] || (_cache[17] = _withModifiers(() => {}, ["stop"]))
          }, [
            _createElementVNode("div", _hoisted_31, [
              _createVNode(_component_VButton, {
                class: "button--rounded button--transparent goal-panel__button goal-panel__button--close",
                onClick: _cache[12] || (_cache[12] = ($event: any) => (_ctx.$emit('update:showStories', !_ctx.showStories)))
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_TimesSvg)
                ]),
                _: 1
              }),
              _createElementVNode("h3", _hoisted_32, [
                _createVNode(_component_VSelect, {
                  class: "v-select v-select--primary",
                  label: "title",
                  reduce: _ctx.getOptionValue,
                  searchable: false,
                  placeholder: _ctx.t('default-placeholder', 2),
                  options: _ctx.viewModeOptions,
                  modelValue: _ctx.storyViewMode,
                  "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.storyViewMode) = $event))
                }, null, 8, ["reduce", "placeholder", "options", "modelValue"])
              ]),
              _createVNode(_component_VFeedStory, {
                title: "",
                scrollable: "",
                selectable: "",
                task: _ctx.goal,
                stories: _ctx.goal.stories,
                "view-mode": _ctx.storyViewMode,
                onStartEditStory: _ctx.startEdit,
                onRemoveStory: _cache[14] || (_cache[14] = ($event: any) => (_ctx.removeStoryAsync(_ctx.goal, $event)))
              }, null, 8, ["task", "stories", "view-mode", "onStartEditStory"])
            ]),
            (_ctx.canComment)
              ? _withDirectives((_openBlock(), _createBlock(_component_VChatInput, {
                  key: 0,
                  class: "goal-panel__chat chat-input--shadow",
                  uploader: _ctx.uploader,
                  onUpdate: _ctx.updateComment,
                  onSubmit: _ctx.createComment,
                  onStopEdit: _ctx.stopEdit,
                  onStartEdit: _ctx.startEdit,
                  newModel: _ctx.newComment,
                  "onUpdate:newModel": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.newComment) = $event)),
                  editModel: _ctx.editComment,
                  "onUpdate:editModel": _cache[16] || (_cache[16] = ($event: any) => ((_ctx.editComment) = $event))
                }, null, 8, ["uploader", "onUpdate", "onSubmit", "onStopEdit", "onStartEdit", "newModel", "editModel"])), [
                  [_directive_focus]
                ])
              : _createCommentVNode("", true)
          ])
        ], 2))
      : _createCommentVNode("", true)
  ]))
}