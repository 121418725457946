import i18n from '@/plugins/VueI18n';
import Pipeline, { Stage } from '../Pipeline';
import UploadableFile from './UploadableFile';
import UploadableFileStatus from './UploadableFileStatus';
import { filesize } from 'filesize';
import IUploader from './IUploader';
import axios, { AxiosError, AxiosHeaders, AxiosInstance, AxiosProgressEvent, RawAxiosRequestHeaders } from 'axios';
import Http from '../Http';
import Methods from '../Methods';
import IObjectStoreModel from '../Values/IObjectStoreModel';
import { store as defaultObjectStorageStore } from '../ObjectStorageMapper';
import IApiResult from '../IApiResult';
import { markRaw } from 'vue';

export default class UploaderBuilder<TModel> {
    private _fileSizeLimit?: number;
    private _uploadStageAdded = false;
    protected readonly pipeline: Pipeline<UploadableFile<TModel>>;

    constructor() {
        this.pipeline = new Pipeline<UploadableFile<TModel>>();
    }

    public withFileSizeLimit(limit: number): UploaderBuilder<TModel> {
        this._fileSizeLimit = limit;

        this.pipeline.pipe((uploadable) => {
            if (uploadable.status === UploadableFileStatus.ERROR) {
                return uploadable;
            }

            if (uploadable.size >= limit) {
                uploadable.status = UploadableFileStatus.ERROR;
                uploadable.errorMessage = i18n.global
                    .t('application.errors.max-file-size-limit', [filesize(limit, { standard: 'jedec' })])
                    .toString();
                throw new Error('File size limit exceeded');
            }

            return uploadable;
        });

        return this;
    }

    public withFileNameLimit(limit: number): UploaderBuilder<TModel> {
        this.pipeline.pipe((uploadable) => {
            if (uploadable.status === UploadableFileStatus.ERROR) {
                return uploadable;
            }

            if (uploadable.name.length >= limit) {
                uploadable.status = UploadableFileStatus.ERROR;
                uploadable.errorMessage = i18n.global.t('application.errors.max-file-name-limit', [limit]).toString();
            }

            return uploadable;
        });

        return this;
    }

    public withHeaders(headers: RawAxiosRequestHeaders | AxiosHeaders = {}): UploaderBuilder<TModel> {
        this.pipeline.pipe((uploadable) => {
            if (uploadable.status === UploadableFileStatus.ERROR) {
                return uploadable;
            }

            uploadable.request.headers = headers;

            return uploadable;
        });

        return this;
    }

    public withEndpoint(endpoint: string, method = 'POST'): UploaderBuilder<TModel> {
        this.pipeline.pipe((uploadable) => {
            if (uploadable.status === UploadableFileStatus.ERROR) {
                return uploadable;
            }

            uploadable.request.url = endpoint;
            uploadable.request.method = method;

            return uploadable;
        });

        return this;
    }

    public withAxios(instance: AxiosInstance): UploaderBuilder<TModel> {
        this.pipeline.pipe((uploadable) => {
            if (uploadable.status === UploadableFileStatus.ERROR) {
                return uploadable;
            }

            uploadable.axios = markRaw(instance);

            return uploadable;
        });

        return this;
    }

    public withProgress(): UploaderBuilder<TModel> {
        this.pipeline.pipe((uploadable) => {
            if (uploadable.status === UploadableFileStatus.ERROR) {
                return uploadable;
            }

            uploadable.request.onUploadProgress = this._onUploadProgress(uploadable);

            return uploadable;
        });

        return this;
    }

    public withUploadStage(): UploaderBuilder<TModel> {
        this.pipeline.pipe(this._uploadStage());
        this._uploadStageAdded = true;

        return this;
    }

    public with(stage: Stage<UploadableFile<TModel>>): UploaderBuilder<TModel> {
        this.pipeline.pipe(stage);

        return this;
    }

    public build(): IUploader<TModel> {
        let pipeline = this.pipeline.clone();

        if (!this._uploadStageAdded) {
            pipeline = pipeline.pipe(this._uploadStage());
        }

        const uploader = {
            upload: this._uploadFunctionAsync(pipeline),
            fileSizeLimit: this._fileSizeLimit,
        };

        Object.freeze(uploader);

        return uploader;
    }

    private _uploadFunctionAsync(
        pipeline: Pipeline<UploadableFile<TModel>>,
    ): (file: UploadableFile<TModel>) => Promise<UploadableFile<TModel>> {
        return (uploadable: UploadableFile<TModel>) => pipeline.process(uploadable);
    }

    private _uploadStage(): (uploadable: UploadableFile<TModel>) => Promise<UploadableFile<TModel>> {
        return async (uploadable: UploadableFile<TModel>) => {
            uploadable.status = UploadableFileStatus.UPLOADING;
            uploadable.controller = markRaw(new AbortController());
            uploadable.request.data = this._prepareRequestData(uploadable);
            uploadable.request.signal = uploadable.controller.signal;

            let response = null;

            try {
                response = await (uploadable.axios ?? axios).request<TModel>(uploadable.request);

                uploadable.model = response.data;
                uploadable.response = markRaw(response);

                uploadable.status = UploadableFileStatus.UPLOADED;
            } catch (error) {
                uploadable.model = null;
                uploadable.response = !response ? response : markRaw(response);
                uploadable.errorMessage = (error as AxiosError).message;

                uploadable.status = UploadableFileStatus.ERROR;
            }

            return uploadable;
        };
    }

    private _prepareRequestData(uploadable: UploadableFile<TModel>) {
        const data = new FormData();

        if (uploadable.file instanceof File) {
            data.append(uploadable.fieldName, uploadable.file);
        }

        if (uploadable.additionalData) {
            const props: { [key: string]: string } = uploadable.additionalData;

            Object.keys(props).forEach((key: string) => {
                data.append(key, props[key]);
            });
        }

        return data;
    }

    private _onUploadProgress(uploadable: UploadableFile<TModel>): (event: AxiosProgressEvent) => void {
        return (progressEvent: AxiosProgressEvent) => {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            uploadable.progress = Math.round((progressEvent.loaded * 100) / progressEvent.total!);
        };
    }
}

const objectStorageUploader = new UploaderBuilder<IApiResult<IObjectStoreModel>>()
    .withProgress()
    .withAxios(Http)
    .withEndpoint('/api/v1/object-storage/objects', Methods.Post)
    .withFileNameLimit(128) // 128 symbols
    .withFileSizeLimit(41_943_040) // 40mb
    .withUploadStage()
    .with((uploadable) => {
        // Adds the uploaded entry to the object-storage mapper store.
        if (uploadable.model) {
            defaultObjectStorageStore.set(uploadable.model.data.objectName, uploadable.model.data.downloadUri);
        }

        return uploadable;
    })
    .build();

export { objectStorageUploader };
