import vueI18n from '@/plugins/VueI18n';

enum StoryViewMode {
    All = 1,
    Actions = 2,
    Comments = 3,
}

const viewModeOptions = [
    {
        title: vueI18n.global.t('feed-story-title'),
        value: StoryViewMode.All,
    },
    {
        title: vueI18n.global.t('actions'),
        value: StoryViewMode.Actions,
    },
    {
        title: vueI18n.global.t('comments'),
        value: StoryViewMode.Comments,
    },
];

Object.freeze(viewModeOptions);

export { viewModeOptions };
export default StoryViewMode;
