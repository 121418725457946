<template>
    <Teleport to="body">
        <div
            class="v-modal-overlay v-modal-overlay--fullscreen prevent-close"
            @keydown.esc="close"
            v-if="visible && context"
        >
            <div ref="modal" class="v-modal v-modal--image-viewer image-viewer" tabindex="-1" v-bind="$attrs">
                <div class="image-viewer__row image-viewer__row--header">
                    <h2 class="image-viewer__file-name">{{ context.fileName }}</h2>

                    <div class="image-viewer__actions">
                        <a
                            target="_blank"
                            class="button button--transparent button--rounded image-viewer__button image-viewer__button--with-icon"
                            :href="context.downloadUri"
                            :download="context.fileName"
                        >
                            <DownloadSvg></DownloadSvg>

                            {{ t('download') }}
                        </a>

                        <VButton
                            class="button--transparent button--rounded image-viewer__button image-viewer__button--close"
                            @click="close"
                        >
                            <TimesSvg></TimesSvg>
                        </VButton>
                    </div>
                </div>

                <div class="image-viewer__row image-viewer__row--center image-viewer__row--viewer">
                    <img class="image-viewer__picture" :src="context.downloadUri" :alt="context.fileName" />
                </div>
            </div>
        </div>
    </Teleport>
</template>

<script lang="ts">
// Svg
import TimesSvg from '@/assets/times.svg';
import DownloadSvg from '@/assets/download.svg';

// Components
import VButton from './VButton.vue';

// Other
import emitter from '@/core/Emitter';
import { EventNames } from '@/core/EventNames';
import ShowImageContext, { ShowImageEvent } from '@/core/Values/ShowImageContext';
import { defineComponent } from 'vue';
import { useI18n } from 'vue-i18n';

export default defineComponent({
    components: {
        TimesSvg,
        DownloadSvg,

        VButton,
    },

    setup() {
        const { t } = useI18n();
        return { t };
    },

    data: () => ({
        visible: false,
        context: null as ShowImageContext | null,
    }),

    methods: {
        open(event: ShowImageEvent) {
            this.visible = true;
            this.context = event;

            this.applyModalStyles();
        },

        close(): void {
            this.visible = false;
            this.context = null;
            this.applyModalStyles();
        },

        applyModalStyles() {
            if (this.visible && !document.body.classList.contains('v-modal-opened')) {
                if (this.$refs.modal) {
                    (this.$refs.modal as HTMLDivElement).focus();
                }
                document.body.classList.add('v-modal-opened');
            } else {
                document.body.classList.remove('v-modal-opened');
            }
        },
    },

    mounted(): void {
        this.applyModalStyles();
    },

    created(): void {
        emitter.on(EventNames.ShowImage, this.open);
    },
});
</script>

<style lang="scss">
.v-modal {
    &--image-viewer {
        width: 100%;
        height: 100%;
        padding: 0;
        border-radius: 0;
    }
}

.v-modal-overlay {
    &--fullscreen {
        padding: 0;
    }
}

.image-viewer {
    display: flex;
    flex-direction: column;
    overflow: hidden;

    &__row {
        &--header {
            display: flex;
            justify-content: space-between;
            width: 100%;
            height: 4rem;
            flex-shrink: 0;
            box-shadow: 0 0 0.6875rem 0 rgba(0, 0, 0, 0.07);
        }

        &--viewer {
            width: 100%;
            min-height: 0;
            height: 100%;
            margin: 2rem 0;
        }

        &--center {
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    &__picture {
        height: auto;
        max-width: 100%;
        max-height: 100%;
        flex-shrink: 0;
        border-radius: 0.5rem;
    }

    &__file-name {
        @include h3();

        margin: 0 0 0 0.75rem;
        line-height: 4rem;
    }

    &__actions {
        display: flex;
        gap: 0.5rem;
        align-items: center;
        margin: 0 0 0 1rem;
    }

    &__button {
        padding: 0.5rem;

        line-height: 1.5rem;
        color: var(--text-black-primary);

        &--close {
            margin: 0.75rem 0.75rem 0.75rem 2rem;
        }

        &--with-icon {
            & svg {
                margin-right: 0.25rem;
            }
        }
    }
}
</style>
