import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "notification-content notification-content--as-story" }
const _hoisted_2 = {
  key: 2,
  class: "notification-content notification-content--as-story"
}

import VFeedStory from './VFeedStory.vue';

// Other
import INotification from '@/core/Models/INotification';
import { PropType } from 'vue';
import { useI18n } from 'vue-i18n';


export default /*@__PURE__*/_defineComponent({
  __name: 'VNotificationCardContentDefault',
  props: {
    notification: { type: Object as PropType<INotification>, required: true },
},
  setup(__props) {

// Svg

// Components
const { t, te } = useI18n();


return (_ctx: any,_cache: any) => {
  return (__props.notification.payload?.task && __props.notification.payload?.stories?.length)
    ? (_openBlock(), _createBlock(VFeedStory, {
        key: 0,
        "force-collapse-first": "",
        "show-count": 1000,
        task: __props.notification.payload?.task,
        stories: __props.notification.payload?.stories
      }, null, 8, ["task", "stories"]))
    : (__props.notification.payload.content.length)
      ? (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(__props.notification.payload.content, (content, index) => {
          return (_openBlock(), _createElementBlock(_Fragment, { key: index }, [
            _createElementVNode("span", _hoisted_1, _toDisplayString(_unref(te)(content.text) ? _unref(t)(content.text, content.parameters ?? []) : content.text), 1),
            _cache[0] || (_cache[0] = _createElementVNode("br", null, null, -1))
          ], 64))
        }), 128))
      : (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_unref(t)('deleted')), 1))
}
}

})