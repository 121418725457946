<template>
    <div class="app-error app-error--center">
        <div class="app-error__content app-error__content--illustration">
            <template v-if="errorMessage === errorCodes.NotEnoughPermissions">
                <VIllustration :tip="t(errorMessage)">
                    <DontEnterSvg></DontEnterSvg>
                </VIllustration>
            </template>

            <template v-else-if="errorMessage === errorCodes.WebsocketConnectionError">
                <VIllustration class="illustration--text-left" :tip="t(errorMessage)">
                    <Error500Svg></Error500Svg>
                </VIllustration>
            </template>

            <template v-else>
                <VIllustration :tip="t(errorMessage)">
                    <Error500Svg></Error500Svg>
                </VIllustration>
            </template>

            <div class="app-error__actions">
                <template v-if="errorMessage === errorCodes.NotEnoughPermissions">
                    <VButton class="button--rectangle button--center button--blurry app-error__action" @click="logout">
                        {{ t('change-account') }}</VButton
                    >
                </template>

                <template v-else>
                    <VButton class="button--rectangle button--center button--blurry app-error__action" @click="reload">
                        {{ t('reload') }}</VButton
                    >
                </template>

                <a
                    target="_blank"
                    :href="serviceDeskUri"
                    class="button button--rectangle button--center button--negative app-error__action"
                >
                    {{ t('goto-service-desk') }}
                </a>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
// Svg
import Error500Svg from '../assets/error-500.svg';
import DontEnterSvg from '../assets/dont-enter.svg';

// Components
import VButton from './VButton.vue';
import VIllustration from './VIllustration.vue';

// Other
import { ErrorCodes } from '@/core/ErrorCodes';
import { markRaw, defineProps, PropType } from 'vue';
import oidc from '@/vue-oidc-client';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const props = defineProps({
    error: {
        type: Object as PropType<Record<string, unknown>>,
        default: null,
    },
});

const errorCodes = markRaw(ErrorCodes);
const serviceDeskUri = markRaw(process.env.VUE_APP_SERVICE_DESK_URI);

const errorMessage =
    props.error?.message && typeof props.error?.message === 'string' ? props.error.message : 'unknown-error';

function reload() {
    location.reload();
    return false;
}

function logout() {
    oidc.signOut();
    return false;
}
</script>

<style lang="scss">
.app-error {
    display: flex;
    width: 100%;
    min-height: 100%;

    &__action {
        flex: 1 1 0;
        @include h4();
        text-wrap: nowrap;
        line-height: 1.625rem;
    }

    &__actions {
        display: flex;
        gap: 0.5rem;
    }

    &__content {
        padding: 3rem 0;
    }

    &--center {
        align-items: center;
        justify-content: center;
    }
}
</style>
