import { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import { ITransport } from './Transports';
import Http from './Http';
import IApiResult from './IApiResult';
import Methods from './Methods';

class HttpTransport implements ITransport {
    private _instance: AxiosInstance;

    public constructor(axiosInstance: AxiosInstance) {
        this._instance = axiosInstance;
    }

    async invokeAsync<TPayload, TResult>(
        method: string,
        endpoint: string,
        payload?: TPayload | undefined,
    ): Promise<TResult> {
        const response = await this.invokeRawAsync<TPayload, AxiosResponse<IApiResult<TResult>>>(
            method,
            endpoint,
            payload,
        );

        return response.data.data;
    }

    async invokeApiAsync<TPayload, TResult>(
        method: string,
        endpoint: string,
        payload?: TPayload | undefined,
    ): Promise<IApiResult<TResult>> {
        const response = await this.invokeRawAsync<TPayload, AxiosResponse<IApiResult<TResult>>>(
            method,
            endpoint,
            payload,
        );

        return response.data;
    }

    async invokeRawAsync<TPayload, TResult>(
        method: string,
        endpoint: string,
        payload?: TPayload | undefined,
    ): Promise<TResult> {
        const request: AxiosRequestConfig<TPayload> = {
            url: endpoint,
            method: method,
        };

        if (method == Methods.Get) {
            request.params = payload;
        } else {
            request.data = payload;
        }

        const response = await this._instance.request<TResult, TResult, TPayload>(request);

        return response;
    }
}
const transport = new HttpTransport(Http);

Object.freeze(transport);

export default transport;
