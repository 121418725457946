import escapeStringRegexp from 'escape-string-regexp';

export const scrollBarWidth = ((): number => {
    const inner = document.createElement('p');
    inner.style.width = '100%';
    inner.style.height = '200px';

    const outer = document.createElement('div');
    outer.style.position = 'absolute';
    outer.style.top = '0px';
    outer.style.left = '0px';
    outer.style.visibility = 'hidden';
    outer.style.width = '200px';
    outer.style.height = '150px';
    outer.style.overflow = 'hidden';
    outer.appendChild(inner);

    document.body.appendChild(outer);
    const w1 = inner.offsetWidth;
    outer.style.overflow = 'scroll';
    let w2 = inner.offsetWidth;
    if (w1 == w2) {
        w2 = outer.clientWidth;
    }

    document.body.removeChild(outer);

    return w1 - w2;
})();

function hasParentWithClassNameString(element: HTMLElement, className: string): boolean {
    // Enumerate all parent elements and if one of the parents contains the `prevent-close` class, we stop the hide operation.
    while (element.parentNode) {
        if (element.className === 'string' && element.className.indexOf(className) !== -1) {
            return true;
        }

        element = element.parentNode as HTMLElement;
    }

    return false;
}

function hasParentWithClassNameArray(element: HTMLElement, classNames: string[]): boolean {
    let pattern = '';
    let className = classNames.pop();
    while (className) {
        pattern = pattern + '|' + escapeStringRegexp(className);
        className = classNames.pop() ?? '';
    }

    pattern = pattern.substring(1, pattern.length);

    if (!pattern) {
        return false;
    }

    // Enumerate all parent elements and if one of the parents contains the `prevent-close` class, we stop the hide operation.
    while (element.parentNode) {
        if (typeof element.className === 'string' && element.className.match(pattern)) {
            return true;
        }

        element = element.parentNode as HTMLElement;
    }

    return false;
}

export function hasParentWithClassName(element: HTMLElement, className: string | string[]): boolean {
    return typeof className === 'string'
        ? hasParentWithClassNameString(element, className as string)
        : hasParentWithClassNameArray(element, className as string[]);
}

export function isClickOnScrollbar(event: MouseEvent): boolean {
    const element = event.target as HTMLElement;

    if (element.scrollWidth > element.clientWidth || element.scrollHeight > element.clientHeight) {
        // Get bounding rect of the scrollable element
        const rect = element.getBoundingClientRect();

        // Check if the click is close to the right edge (where the vertical scrollbar usually is)
        return event.clientX > rect.right - scrollBarWidth || event.clientY > rect.bottom - scrollBarWidth;
    }

    return false;
}

const focusElements = [
    'a[href]',
    'area[href]',
    'input:not([disabled]):not([type="hidden"]):not([aria-hidden])',
    'select:not([disabled]):not([aria-hidden])',
    'textarea:not([disabled]):not([aria-hidden])',
    'button:not([disabled]):not([aria-hidden])',
    'iframe',
    'object',
    'embed',
    '[contenteditable]',
    '[tabindex]:not([tabindex^="-"])',
];

export function focusOnFirstTarget(container: HTMLElement): void {
    if (container.matches(focusElements as unknown as string)) {
        container.focus();
        return;
    }

    // Force cast to use the modern method.
    const focusElement = container?.querySelector(focusElements as unknown as string) as HTMLElement | null;

    if (focusElement) {
        focusElement.focus();
    }
}
