<template>
    <div class="loader">
        <svg class="spinner" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
            <circle class="path" fill="none" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
        </svg>
    </div>
</template>

<style lang="scss">
.loader {
    display: flex;
    width: 100%;
    height: 100%;

    & .spinner {
        width: 4rem;
        height: 4rem;

        & circle {
            stroke-width: 0.1875rem;
        }
    }

    &--mini {
        & .spinner {
            width: 2rem;
            height: 2rem;
        }

        & circle {
            stroke-width: 0.25rem;
        }
    }

    &--center {
        align-items: center;
        justify-content: center;
    }
}

$offset: 187;
$duration: 1.4s;

.spinner {
    animation: rotator $duration linear infinite;
}

@keyframes rotator {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(270deg);
    }
}

.path {
    stroke-dasharray: $offset;
    stroke-dashoffset: 0;
    transform-origin: center;
    stroke: var(--brand-green, #18a452);
    animation: dash $duration ease-in-out infinite, colors ($duration * 4) ease-in-out infinite;
}

@keyframes colors {
    0% {
        stroke: #18a452;
    }
    100% {
        stroke: #18a452;
    }
}

@keyframes dash {
    0% {
        stroke-dashoffset: $offset;
    }
    50% {
        stroke-dashoffset: $offset/4;
        transform: rotate(135deg);
    }
    100% {
        stroke-dashoffset: $offset;
        transform: rotate(450deg);
    }
}
</style>
