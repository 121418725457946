import { DateTime } from 'luxon';

const serverTimezone = 'UTC';
const clientTimezone = 'local';
export function formatDate(date: Date | string, format: string, locale = 'local'): string {
    const dateTime =
        date instanceof Date
            ? DateTime.fromJSDate(date, { zone: serverTimezone })
            : DateTime.fromISO(date, { zone: serverTimezone });

    return dateTime.setZone(clientTimezone).toFormat(format, {
        locale,
    });
}

export function formatWithTime(date: Date | string, locale = 'local'): string {
    return formatDate(date, 'ff', locale);
}

export function formatDateOnly(date: Date | string, locale = 'local'): string {
    return formatDate(date, 'DD', locale);
}
