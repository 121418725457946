import Methods from '../Methods';
import ITextDiff from '../Models/ITextDiff';
import Transport, { ITransport } from '../Transports';

class TextDiffService {
    private _transport: ITransport;

    constructor(transport: ITransport) {
        this._transport = transport;
    }

    public find(id: number): Promise<ITextDiff> {
        return this._transport.invokeAsync<undefined, ITextDiff>(Methods.Get, `/api/v1/text-diffs/${id}`);
    }
}

const service = new TextDiffService(Transport);

Object.freeze(service);

export default service;
