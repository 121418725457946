<template>
    <span class="status" :class="colorClassName">
        {{ t(statusTitle) }}
    </span>
</template>

<script setup lang="ts">
// Other
import { computed, defineProps } from 'vue';
import ApprovementStatus, { ApprovementTitle } from '../core/Values/ApprovementStatus';
import { useI18n } from 'vue-i18n';

const statusColorClassName = {
    [ApprovementStatus.Waiting]: 'status--black',
    [ApprovementStatus.Approved]: 'status--green',
    [ApprovementStatus.Disapproved]: 'status--negative',
    [ApprovementStatus.Skipped]: 'status--blurry',
};

const { t } = useI18n();
const props = defineProps({
    status: { type: Number, required: true },
});
const statusTitle = computed(
    () => (ApprovementTitle[props.status as keyof typeof ApprovementTitle] as string) ?? 'unknown',
);
const colorClassName = computed(
    () => statusColorClassName[props.status as unknown as keyof typeof statusColorClassName] ?? '',
);
</script>
