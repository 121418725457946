<script lang="ts">
// Svg

// Components
import { I18nT } from 'vue-i18n';

// Other
import { tryTranslate } from '@/plugins/VueI18n';
import { formatDate } from '@/utils/date-utils';
import { defineComponent, h, VNode, Text } from 'vue';
import vueI18n from '@/plugins/VueI18n';
import store from '@/store';
import VTextDiff from './VTextDiff.vue';

const templates: Record<string, (key: string, params: string[]) => string | VNode> = {};

templates['kpi-tasks.v2.goal-story.deadline-changed'] = templates['kpi-tasks.v2.task-story.deadline-changed'] = (
    key,
    params,
) =>
    h(
        I18nT,
        {
            keypath: key + '-' + (!params[2] ? '1' : '2'),
        },
        {
            old: () => h('b', h('s', formatDate(params[2], 'D'))),
            new: () => h('b', formatDate(params[3], 'D')),
        },
    );

templates['kpi-tasks.v2.goal-story.approved'] =
    templates['kpi-tasks.v2.task-story.approved'] =
    templates['kpi-tasks.v2.goal-story.disapproved'] =
    templates['kpi-tasks.v2.task-story.disapproved'] =
    templates['kpi-tasks.v2.goal-story.return-in-progress'] =
    templates['kpi-tasks.v2.task-story.return-in-progress'] =
        (key, params) =>
            h(
                I18nT,
                {
                    keypath: key,
                },
                {
                    comment: () =>
                        params[2]
                            ? [h('span', [' ' + vueI18n.global.t('with-comment') + ' ', h('b', params[2])])]
                            : null,
                },
            );

templates['kpi-tasks.v2.goal-story.author-changed'] =
    templates['kpi-tasks.v2.task-story.author-changed'] =
    templates['kpi-tasks.v2.goal-story.number-of-assignee-changed'] =
    templates['kpi-tasks.v2.task-story.number-of-assignee-changed'] =
    templates['kpi-tasks.v2.goal-story.number-of-contributors-changed'] =
    templates['kpi-tasks.v2.task-story.number-of-contributors-changed'] =
        (key, params) =>
            h(
                I18nT,
                {
                    keypath: key,
                },
                {
                    action: () => h(Text, vueI18n.global.t(params[3] + '-action')),
                    user: () =>
                        h(
                            'b',
                            {
                                className: 'story-record-content story-record-content--week',
                            },
                            store.state.usersMap.get(params[2])?.displayName ?? vueI18n.global.t('unknown'),
                        ),
                    preposition: () => h(Text, vueI18n.global.t(params[3] + '-preposition')),
                },
            );

templates['kpi-tasks.v2.task-story.title-changed'] =
    templates['kpi-tasks.v2.goal-story.title-changed'] =
    templates['kpi-tasks.v2.task-story.description-changed'] =
    templates['kpi-tasks.v2.goal-story.description-changed'] =
        (key, params) =>
            h(
                I18nT,
                {
                    keypath: key,
                },
                {
                    showDiff: () => {
                        return params[2] !== 'text-diff' || isNaN(parseInt(params[3], 10))
                            ? h(Text, vueI18n.global.t(key.replace('v2', 'v1')))
                            : h(VTextDiff, {
                                  className: 'story-record-content',
                                  textDiffId: parseInt(params[3], 10),
                              });
                    },
                },
            );

export default defineComponent({
    props: {
        content: { type: String, required: true },
    },

    setup(props) {
        if (!props.content.startsWith('@|')) {
            return () => h('span', props.content);
        }

        // index: 0  1    2     3    n
        // value: @|key|prop1|prop2|...
        const params = props.content.split('|');
        const template = templates?.[params[1]];
        if (template && params.length > 1) {
            return () => template?.(params[1], params);
        }

        const content = tryTranslate(props.content ? props.content : '@|kpi-tasks.v1.task-story.unknown');
        return () => h('span', {}, [content]);
    },
});
</script>

<style lang="scss">
.story-record-content {
    &,
    * {
        font-size: inherit;
        font-weight: inherit;
        line-height: inherit;
    }

    &--week {
        color: var(--text-black-tertiary);
    }
}
</style>
