import { DiffPatcher } from 'jsondiffpatch';
import DiffMatchPatch from 'diff-match-patch';

const diffPatcher = new DiffPatcher({
    textDiff: {
        minLength: 50,
        diffMatchPatch: DiffMatchPatch,
    },
});

export default diffPatcher;
