<template>
    <VModal class="v-modal--error" v-model:visible="visible" @close="close">
        <h2 class="v-modal__row v-modal__row--header v-modal__title">
            {{ context?.title }}
        </h2>

        <p class="v-modal__row v-modal__row--content v-modal__content">
            {{ context?.message }}
        </p>

        <div class="v-modal__row v-modal__row--actions">
            <VButton class="button--rectangle button--center button--negative v-modal__action" @click="ok">
                {{ context?.buttonOkName }}
            </VButton>
        </div>
    </VModal>
</template>

<script setup lang="ts">
// Svg
// ...

// Components
import VModal from './VModal.vue';
import VButton from './VButton.vue';

// Other
import emitter from '@/core/Emitter';
import { EventNames } from '@/core/EventNames';
import AlertContext, { AlertOpenEvent } from '@/core/Values/AlertContext';
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const visible = ref(false);
const context = ref(null as AlertContext | null);

emitter.on(EventNames.Alert, open);

function open(event: AlertOpenEvent) {
    visible.value = true;
    context.value = {
        title: t('alert-title'),
        buttonOkName: t('ok'),
        ...event,
    };
}

function ok() {
    if (context.value?.okCallback) {
        context.value.okCallback();
    }

    context.value = null;
    visible.value = false;
}

function close() {
    if (context.value?.cancelCallback) {
        context.value.cancelCallback();
    }

    context.value = null;
    visible.value = false;
}
</script>
