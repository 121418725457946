import Methods from '../Methods';
import IUser from '../Models/IUser';
import Transport, { ITransport } from '../Transports';

export interface QueryUserRequest {
    page?: number;
    perPage?: number;
    search?: string;
    whereId?: string | string[];
}

class UserService {
    private _transport: ITransport;

    constructor(transport: ITransport) {
        this._transport = transport;
    }

    public find(userId: string): Promise<IUser> {
        return this._transport.invokeAsync<undefined, IUser>(Methods.Get, `/api/v1/accounts/${userId}`);
    }

    public query(request: QueryUserRequest): Promise<IUser[]> {
        return this._transport.invokeAsync<QueryUserRequest, IUser[]>(Methods.Post, '/api/v1/accounts/query', request);
    }

    public getAll(): Promise<IUser[]> {
        return this._transport.invokeAsync<undefined, IUser[]>(Methods.Get, '/api/v1/accounts');
    }

    public getMyAccountAsync(): Promise<IUser> {
        return this._transport.invokeAsync<undefined, IUser>(Methods.Get, '/api/v1/accounts/me');
    }

    public getMyPermissionsAsync(): Promise<string[]> {
        return this._transport.invokeAsync<undefined, string[]>(Methods.Get, '/api/v1/accounts/my/permissions');
    }
}

const service = new UserService(Transport);

Object.freeze(service);

export default service;
