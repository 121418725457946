import IMutation from '../Mutations/IMutation';
import ObjectType from '../Mutations/ObjectType';
import { IMutator } from '../Mutations/IMutator';
import IMutatorContext from '../Mutations/IMutatorContext';
import INotification from '../Models/INotification';
import diffPatcher from '../Mutations/Patcher';
import IMutationPatch from '../Mutations/IMutationPatch';
import AggregatorService from '../Services/AggregatorService';
import { EventNames } from '../EventNames';

export class NotificationMutator implements IMutator {
    private _matrix = {
        [ObjectType.Notification]: {
            [EventNames.NotificationRead]: this.readNotification,
            [EventNames.NotificationCreated]: this.createNotification,
            [EventNames.NotificationDeleted]: this.deleteNotification,
        },
    };

    public mutate(context: IMutatorContext, mutations: IMutation[], eventName: string) {
        mutations.forEach((mutation) => {
            const objectType = mutation.objectType as keyof typeof this._matrix;

            if (
                !Object.prototype.hasOwnProperty.call(this._matrix, mutation.objectType) ||
                !Object.prototype.hasOwnProperty.call(this._matrix[objectType], eventName)
            ) {
                return;
            }

            const action = this._matrix[objectType][eventName] as (
                context: NotificationMutatorContext,
                objectState: unknown,
            ) => void;

            action.bind(this)(context as NotificationMutatorContext, mutation.objectState);
        });
    }

    private readNotification(context: NotificationMutatorContext, patch: IMutationPatch) {
        const carry = context.notifications.find((item) => item.id === patch.id);

        if (!carry) {
            return;
        }

        diffPatcher.patch(carry, patch.patch);
    }

    private createNotification(context: NotificationMutatorContext, notification: { id: string }) {
        AggregatorService.queryNotificationsAsync({
            perPage: 1,
            whereId: notification.id,
        }).then((notifications) => {
            if (notifications.length > 0) {
                context.notifications.push(notifications[0]);
            }
        });
    }

    private deleteNotification(context: NotificationMutatorContext, notification: { id: string }) {
        const index = context.notifications.findIndex((item) => item.id === notification.id);

        if (index !== -1) {
            context.notifications.splice(index, 1);
        }
    }
}

const defaultMutator = new NotificationMutator();

export class NotificationMutatorContext implements IMutatorContext {
    public mutator: IMutator;
    public notifications: INotification[];

    public constructor(notifications: INotification[], mutator: IMutator = defaultMutator) {
        this.mutator = mutator;
        this.notifications = notifications;
    }
}
