<template>
    <div
        class="notification-card prevent-close"
        :class="{
            'notification-card--unread': !isRead,
            'notification-card--selected': isSelected,
        }"
        :s="notification.id"
    >
        <div class="notification-card__row notification-card__row--header">
            <VUserCard :user="notification.payload.actor"></VUserCard>

            <span class="notification-card__timestamp"> {{ createdAt }} </span>
        </div>

        <p class="notification-card__row notification-card__row--title">
            {{ notification.payload?.task?.title }}
        </p>

        <div class="notification-card__row notification-card__row--content">
            <component :is="template" :notification="notification"></component>
        </div>

        <div class="notification-card__row notification-card__row--footer">
            <VInlineLink
                class="prevent-close"
                :to="{
                    name: 'projects.board',
                    params: {
                        projectId: project.id,
                        boardId: board.id,
                    },
                    query: {
                        task: notification.payload?.task?.id,
                    },
                }"
                :subject="t('board-link-text')"
                @click.prevent
                v-if="project && board"
            >
                {{ $tryTranslate(board.title) }}, {{ $tryTranslate(project.title) }}
            </VInlineLink>

            <div class="notification-card__actions" @click.prevent>
                <VButtonDropdown placement="bottom-end" :options="dropdownOptions">
                    <DotsSvg></DotsSvg>
                </VButtonDropdown>

                <RouterLink :to="gotoLink" class="button button--secondary button--green">{{
                    isGoal ? t('goto-goal') : t('goto-task')
                }}</RouterLink>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
// Svg
import DotsSvg from '@/assets/dots.svg';
import RemoveSvg from '@/assets/remove.svg';

// Components
import VUserCard from './VUserCard.vue';
import VInlineLink from './VInlineLink.vue';
import VButtonDropdown from './VButtonDropdown.vue';
import VNotificationCardContentDefault from './VNotificationCardContentDefault.vue';

// Other
import INotification from '@/core/Models/INotification';
import { formatWithTime } from '@/utils/date-utils';
import store from '@/store';
import TaskType from '@/core/Values/TaskType';
import { IDropdownOption } from '@/core/Values/IDropdownOption';
import { markRaw, PropType, defineProps, computed, defineEmits } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';

const { t } = useI18n();
const route = useRoute();
const emit = defineEmits(['remove']);
const props = defineProps({
    notification: { type: Object as PropType<INotification>, required: true },
});

const isRead = computed((): boolean => !!props.notification.readAt);
const isGoal = computed((): boolean => props.notification.payload?.task?.type === TaskType.Goal);
const isSelected = computed((): boolean => props.notification.id == route.query.selected);
const createdAt = computed(() => formatWithTime(props.notification.createdAt));

const template = computed(() => {
    switch (props.notification.template) {
        default:
            return markRaw(VNotificationCardContentDefault);
    }
});

const gotoLink = computed(() => {
    const taskId = props.notification.payload?.taskId;

    if (isGoal.value) {
        return { name: 'goals.view', params: { goalId: taskId } };
    }

    return { query: { task: taskId, selected: props.notification.id } };
});

const dropdownOptions = computed((): IDropdownOption[] => {
    return [
        {
            icon: markRaw(RemoveSvg),
            title: t('remove-notification'),
            action: () => emit('remove', props.notification),
        },
    ];
});

const board = computed(() =>
    store.state.boards?.find((board) => board.id === props.notification.payload?.task?.boardId),
);

const project = computed(() =>
    store.state.projects?.find((project) => project.id === props.notification.payload?.task?.projectId),
);
</script>

<style lang="scss">
.notification-card {
    position: relative;
    width: 52.5rem;
    max-width: 100%;
    padding: 1rem 1.5rem 1rem 1.5rem;
    border-radius: 1.5rem;
    overflow: hidden;
    background-color: var(--background-color);
    --background-color: var(--background-primary);

    &--unread::after {
        content: ' ';
        position: absolute;
        top: 1.5rem;
        right: 1.5rem;
        margin: 0.0625rem;
        width: 1rem;
        height: 1rem;

        border-radius: 50%;
        background-color: var(--background-color);
        --background-color: var(--brand-green);
    }

    &--selected {
        background: var(--background-color);
        --background-color: var(--background-blue);
        border-color: var(--color-blue);
    }

    &__row {
        &--header,
        &--footer {
            display: flex;
        }

        &--footer {
            align-items: center;
        }

        &--header {
            margin: 0 0 0.5rem 0;
        }

        &--title {
            margin: 0 0 1rem 2.5rem;

            @include h4();
            word-wrap: break-word;
            color: var(--text-black-primary);
        }

        &--content {
            margin: 0 0 1rem 0;

            & .feed-story__list {
                padding-bottom: 0;
            }

            & .story-record {
                padding: 0 0 0 2.5rem;
            }

            & .story-record__layer--left {
                left: 0;
            }

            & .user-card__avatar {
                display: none;
            }
        }
    }

    &__timestamp {
        margin: 0 2.5rem 0 auto;

        line-height: 2rem;
        text-wrap: nowrap;
        color: var(--text-black-primary);
    }

    &__actions {
        display: flex;
        gap: 0.5rem;
        margin: 0 0 0 auto;
    }
}
</style>
