import { mergeProps as _mergeProps, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, vShow as _vShow, normalizeClass as _normalizeClass, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "input-field-wrapper" }
const _hoisted_2 = ["value", "maxlength"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("input", _mergeProps({
      class: "input-field",
      value: _ctx.value,
      maxlength: _ctx.maxlength,
      onFocus: _cache[0] || (_cache[0] = ($event: any) => (_ctx.focused = true)),
      onBlur: _cache[1] || (_cache[1] = ($event: any) => (_ctx.focused = false)),
      onInput: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.onInput && _ctx.onInput(...args)))
    }, _ctx.$attrs), null, 16, _hoisted_2),
    (_ctx.maxlength)
      ? _withDirectives((_openBlock(), _createElementBlock("span", {
          key: 0,
          class: _normalizeClass(["input-field-wrapper__legend", {
                'input-field-wrapper__legend--negative': _ctx.value.length >= _ctx.maxlengthNumber,
            }])
        }, [
          _createElementVNode("span", null, _toDisplayString(_ctx.value.length), 1),
          _cache[3] || (_cache[3] = _createTextVNode("/")),
          _createElementVNode("span", null, _toDisplayString(_ctx.maxlengthNumber), 1)
        ], 2)), [
          [_vShow, _ctx.focused]
        ])
      : _createCommentVNode("", true)
  ]))
}