<template>
    <ul
        class="v-table__row v-table__row--hover"
        :class="{
            'v-table__row--active': props.active,
        }"
    >
        <template v-for="(column, cellIndex) in props.table.columns" :key="column.title">
            <li
                class="v-table__column v-table__column--border v-table__column--borderless"
                :class="{
                    'v-table__column--indexed': cellIndex === 0,
                }"
                :style="getColumnStyles(props.table, column)"
                :row-index="index + 1"
                v-if="column.visible"
            >
                <template v-if="column.text">
                    <span
                        class="v-table__content v-table__content--text"
                        :style="getContentStyles(props.table, column, cellIndex)"
                    >
                        {{ column.text(data, index) }}
                    </span>
                </template>

                <template v-else-if="column.render">
                    <component
                        :is="column.render(data, index)"
                        :style="getContentStyles(props.table, column, cellIndex)"
                    />
                </template>

                <template v-else-if="column.component">
                    <component
                        :is="column.component"
                        :data="data"
                        :index="index"
                        :style="getContentStyles(props.table, column, cellIndex)"
                    />
                </template>
            </li>
        </template>
    </ul>
</template>

<script setup lang="ts">
// Svg
// ...

// Components

// Other
import { PropType, computed, defineProps } from 'vue';
import ITable, { ITableColumn } from '@/core/Values/ITable';
import ITask from '@/core/Models/ITask';

const props = defineProps({
    table: {
        type: Object as PropType<ITable>,
        required: true,
    },
    data: {
        type: Object as PropType<unknown>,
        required: true,
    },
    index: {
        type: Number,
        required: true,
    },
    offset: {
        type: Number,
        default: 0,
    },
    active: {
        type: Boolean,
        default: false,
    },
});

const firstIndex = computed(() => props.table.columns.findIndex((column) => column.visible));

function getColumnStyles(table: ITable<ITask>, cell: ITableColumn<ITask>) {
    const styles: Record<string, string> = {
        width: (cell.width ? cell.width : 100 / table.columns.length) + '%',
    };

    return styles;
}

function getContentStyles(table: ITable<ITask>, cell: ITableColumn<ITask>, index: number) {
    const styles: Record<string, string> = {};

    if (props.offset && index === firstIndex.value) {
        styles.paddingLeft = props.offset + 'rem';
    }

    return styles;
}
</script>

<style lang="scss">
.v-table {
    &__content {
        width: 100%;
        height: 100%;

        @include h7(false);

        &--flex {
            display: flex;
        }

        &--center {
            display: flex;
            align-items: center;
        }

        &--margin {
            margin: 0 0.4375rem;
        }

        &--shrink {
            min-width: 0;
            flex-shrink: 1;
        }

        &--text {
            width: 100%;
            padding: 0 0.5rem;
            @include overflow-ellipsis();
        }

        &--red {
            background: var(--background-color);
            --background-color: var(--background-red);
        }

        &--blue {
            border-color: var(--color-blue);
            background: var(--background-color);
            --background-color: var(--background-blue);
        }

        &--green {
            background: var(--background-color);
            --background-color: var(--background-green);
        }
    }
}
</style>
