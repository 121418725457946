<template>
    <div
        class="goal-card prevent-close"
        :class="{
            'goal-card--expired': isExpired,
            'goal-card--completed': isCompleted,
            'goal-card--selected': isSelected,
        }"
        :task-id="goal.id"
        @contextmenu="openContextMenu($event, goal)"
    >
        <template v-if="goal.previewDownloadUri">
            <img class="goal-card__preview" :src="goal.previewDownloadUri" alt="" />
        </template>

        <div class="goal-card__content">
            <div class="goal-card__row goal-card__row--header goal-card__row--horizontal goal-card__row--space-between">
                <p
                    class="goal-card__row goal-card__title"
                    :class="{
                        'goal-card__title--blurry': !goal.title,
                    }"
                >
                    {{ goal.title ? goal.title : t('untitled') }}
                </p>

                <div class="goal-card__row goal-card__row--status">
                    <VTaskStatus :task="goal"></VTaskStatus>
                </div>
            </div>

            <div class="goal-card__row goal-card__row--horizontal goal-card__row--gap">
                <div class="goal-card__hint" v-if="contributors.length > 0 && contributors[0].user">
                    <VUserCard :user="contributors[0].user" picture-only tiny></VUserCard>

                    <span class="goal-card__counter" v-if="contributors.length > 1">
                        +{{ contributors.length - 1 }}
                    </span>
                </div>

                <span class="goal-card__hint goal-card__hint--space-right">
                    {{ formattedDeadline ? formattedDeadline : t('no-deadline') }}
                </span>

                <div class="goal-card__hint" v-if="hasDescription">
                    <TextSvg></TextSvg>
                </div>

                <div class="goal-card__hint" v-if="numberOfComments > 0">
                    <EmailSvg></EmailSvg>

                    <span>
                        {{ numberOfComments }}
                    </span>
                </div>

                <div class="goal-card__hint" v-if="numberOfAttachments > 0">
                    <AttachmentSvg></AttachmentSvg>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
// Svg
import TextSvg from '@/assets/text.svg';
import EmailSvg from '@/assets/email.svg';
import AttachmentSvg from '@/assets/attachment.svg';

// Components
import VTaskStatus from './VTaskStatus.vue';
import VUserCard from './VUserCard.vue';

// Other
import ITask from '@/core/Models/ITask';
import StoryType from '@/core/Values/StoryType';
import { formatDateOnly } from '@/utils/date-utils';
import { DateTime } from 'luxon';
import Status from '@/core/Values/Status';
import CollaboratorRole from '@/core/Values/CollaboratorRole';
import ICollaborator from '@/core/Models/ICollaborator';
import { PropType, defineProps, computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useTaskContextMenu } from '@/mixins/TaskApi';
import { useRoute } from 'vue-router';

const props = defineProps({
    goal: { type: Object as PropType<ITask>, required: true },
});

const goal = ref(props.goal);

const { t } = useI18n();
const route = useRoute();
const { openContextMenu } = useTaskContextMenu();

const numberOfComments = computed((): number => {
    if (typeof props.goal.commentsCount === 'number') {
        return props.goal.commentsCount;
    }

    return props.goal.stories?.filter((story) => story.type === StoryType.Comment)?.length ?? 0;
});

const numberOfAttachments = computed((): number => {
    if (typeof props.goal.attachmentsCount === 'number') {
        return props.goal.attachmentsCount;
    }

    return props.goal.stories?.filter((story) => story.type === StoryType.Attachment)?.length ?? 0;
});

const hasDescription = computed((): boolean => {
    return !!props.goal.description;
});

const formattedDeadline = computed((): string => {
    if (!props.goal?.deadline) {
        return '';
    }

    return formatDateOnly(props.goal.deadline);
});

const isExpired = computed((): boolean => {
    if (!props.goal.deadline || props.goal.status !== Status.InProgress) {
        return false;
    }

    return DateTime.fromISO(props.goal.deadline, { zone: 'UTC' }) < DateTime.now();
});

const isCompleted = computed((): boolean => {
    return props.goal.status === Status.Completed;
});

const isSelected = computed((): boolean => {
    return props.goal.id === parseInt(route.query.task as string, 10);
});

const contributors = computed((): ICollaborator[] => {
    return props.goal.collaborators?.filter((collaborator) => collaborator.role === CollaboratorRole.Contributor) ?? [];
});
</script>

<style lang="scss">
.goal-card {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    gap: 1.25rem;

    border-radius: 1rem;
    border: 0.0625rem solid var(--background-tertiary);
    background: var(--background-color);
    --background-color: var(--background-primary);

    &--expired {
        background: var(--background-color);
        --background-color: var(--background-red);
    }

    &--completed {
        background: var(--background-color);
        --background-color: var(--background-green);
    }

    &--selected {
        border-color: #def4ff;
        background: var(--background-color);
        --background-color: #def4ff;
    }

    &__row {
        &--gap {
            gap: 0.5rem;
        }

        &--status {
            margin-top: 0.375rem;

            @media (min-width: #{$breakpoint-tablet + 1}) {
                margin-top: 0;
                margin-left: 2rem;
            }
        }

        &--header {
            flex-direction: column;

            @media (min-width: #{$breakpoint-tablet + 1}) {
                flex-direction: row;
            }
        }

        &--horizontal {
            display: flex;
        }

        &--center {
            align-items: center;
        }

        &--space-between {
            justify-content: space-between;
        }
    }

    &__hint {
        display: flex;
        height: 1.5rem;
        line-height: 1.5rem;

        color: var(--text-black-secondary);

        &--space-right {
            margin-right: auto;
        }
    }

    &__counter {
        margin-left: 0.25rem;
    }

    &__title {
        word-wrap: break-word;
        overflow: hidden;
        margin: 0;
        @include h7();

        color: var(--text-black-primary);

        &--blurry {
            color: var(--text-black-tertiary);
        }
    }

    &__preview {
        width: 100%;
        height: auto;
        max-height: 10rem;
        margin-bottom: -0.5rem;
        object-fit: cover;
        border-top-left-radius: 1rem;
        border-top-right-radius: 1rem;
    }

    &__content {
        padding: 0.75rem 1rem 1rem 1rem;
    }
}
</style>
