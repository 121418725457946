import { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import UploadableFileStatus from './UploadableFileStatus';
import { fileExtension } from '@/utils/utils';
import { Raw, markRaw } from 'vue';

export default class UploadableFile<TModelType> {
    public file: File;
    public name: string;
    public size: number;
    public type: string;
    public extension: string;

    public request: Raw<AxiosRequestConfig> = markRaw({});
    public response: Raw<AxiosResponse<TModelType>> | null;
    public controller?: Raw<AbortController>;
    public axios?: Raw<AxiosInstance>;
    public fieldName = 'file';
    public additionalData = markRaw({});

    public model: TModelType | null;
    public total: number;
    public progress: number;
    public errorMessage: string;
    public status: UploadableFileStatus = UploadableFileStatus.WAITING;

    constructor(value: File) {
        this.file = value;
        this.name = this.file.name;
        this.size = this.file.size;
        this.type = this.file.type;
        this.total = this.file.size;
        this.extension = fileExtension(this.file.name);
        this.progress = 0;

        this.model = null;
        this.response = null;
        this.errorMessage = '';
    }
}
