<template>
    <div class="v-table__data-set">
        <h3 class="v-table__data-set-title">
            {{ props.title }}
        </h3>

        <div class="v-table__data">
            <slot></slot>
        </div>
    </div>
</template>

<script setup lang="ts">
// Svg
// ...

// Components

// Other
import { defineProps } from 'vue';

const props = defineProps({
    title: {
        type: String,
        default: '',
    },
});
</script>

<style lang="scss">
.v-table {
    &__data-set {
        flex-grow: 1;
        margin-top: 1.875rem;

        &:last-child {
            margin-bottom: 3.75rem;
        }
    }

    &__data-set-title {
        margin-left: 1.5625rem;
        @include h3();
    }
}
</style>
