<template>
    <VPage :class="{ 'app-page--fullscreen': pageSettings.fullscreen }">
        <VPageHeader show-third-block :title="pageTitle">
            <template v-slot:second>
                <div class="app-page-header__column app-page-header__column--tools">
                    <VPageSettings class="prevent-close" :options="pageOptions" v-model="pageSettings"></VPageSettings>

                    <VTaskFilter
                        class="prevent-close"
                        :filter-types="[
                            TaskFilterTypes.Author,
                            TaskFilterTypes.Status,
                            TaskFilterTypes.Assignee,
                            TaskFilterTypes.Contributor,
                            TaskFilterTypes.AuthorOrAssignee,
                            TaskFilterTypes.Deadline,
                            TaskFilterTypes.CreationDate,
                        ]"
                        v-model="filters"
                    ></VTaskFilter>

                    <VButtonDropdown class="prevent-close" placement="left-start" :options="regularActions">
                        <DotsSvg class="rotate-90"></DotsSvg>
                    </VButtonDropdown>

                    <VSearchField class="prevent-close" debounce-mode v-model="searchString"></VSearchField>
                </div>
            </template>
        </VPageHeader>

        <VPageContent :vertical="pageSettings.viewMode === 'table'">
            <VBoard center vertical v-if="pageSettings.viewMode === 'board'">
                <template v-for="(column, columnIndex) in columns" :key="columnIndex">
                    <VColumn
                        class="column--goals column--shrink column--padding-bottom"
                        no-scrolling
                        wheel-propagation
                        :column="column"
                        :editable="canUpdateColumnTitle(column)"
                        @set-title="setColumnTitle(column, $event)"
                    >
                        <VDraggable
                            tag="ul"
                            class="column__list"
                            group="tasks"
                            draggable=".column__item"
                            ghost-class="task-card-ghost"
                            item-key="id"
                            :force-fallback="true"
                            :scroll-sensitivity="200"
                            @add="onMove(column, $event)"
                            @update="onMove(column, $event)"
                            v-model="groupedTasks[column.id]"
                        >
                            <template v-slot:item="{ element }">
                                <li class="column__item">
                                    <RouterLink :to="{ name: 'goals.view', params: { goalId: element.id } }">
                                        <VGoalCard :goal="element"></VGoalCard>
                                    </RouterLink>
                                </li>
                            </template>
                        </VDraggable>
                    </VColumn>
                </template>

                <div class="column column--padding-bottom">
                    <div class="column__row">
                        <VButton class="button--link-underline" @click="createColumn">
                            {{ t('add-new-column') }}
                        </VButton>
                    </div>
                </div>
            </VBoard>

            <VTable v-model:table="table" v-model:order="order" v-else-if="pageSettings.viewMode === 'table'">
                <template v-for="(column, columnIndex) in columns" :key="columnIndex">
                    <VTableDataSet :title="column.title" v-if="groupedTasks[column.boardId].length">
                        <template v-for="(task, index) in groupedTasks[column.boardId]" :key="task.id">
                            <RouterLink class="prevent-close" :to="{ query: { task: task.id } }">
                                <VTableRow
                                    :table="table"
                                    :data="task"
                                    :index="index"
                                    :active="task.id.toString() == route.query.task"
                                    @contextmenu="openContextMenu($event, task)"
                                ></VTableRow>
                            </RouterLink>
                        </template>
                    </VTableDataSet>
                </template>
            </VTable>
        </VPageContent>
    </VPage>
</template>

<script lang="ts">
// Svg
import DotsSvg from '@/assets/dots.svg';
import DownloadSvg from '@/assets/download.svg';

// Components
import VGoalCard from '../components/VGoalCard.vue';
import VPage from '../components/VPage.vue';
import VPageHeader from '../components/VPageHeader.vue';
import VPageContent from '../components/VPageContent.vue';
import VTaskFilter, { TaskFilterTypes } from '../components/VTaskFilter.vue';
import VSearchField from '../components/VSearchField.vue';
import VBoard from '../components/VBoard.vue';
import VColumn from '../components/VColumn.vue';
import VButton from '../components/VButton.vue';
import VDraggable from 'vuedraggable';
import VTable from '../components/VTable.vue';
import VTableRow from '../components/VTableRow.vue';
import VTableDataSet from '../components/VTableDataSet.vue';
import VPageSettings from '../components/VPageSettings.vue';
import VButtonDropdown from '../components/VButtonDropdown.vue';

// Other
import TaskType from '@/core/Values/TaskType';
import ProjectBoardPageMixin from './_ProjectBoardPageMixin';
import { defineComponent } from 'vue';
import { markRaw } from 'vue';
import { useI18n } from 'vue-i18n';
import { useTaskContextMenu } from '@/mixins/TaskApi';
import useTaskTableViewer, { defaultColumns } from '@/mixins/TaskTableViewer';
import Settings from '@/core/Settings';
import { usePageOptions } from '@/mixins/PageSettings';
import { useRoute } from 'vue-router';
import { IDropdownOption } from '@/core/Values/IDropdownOption';
import { downloadFile } from '@/utils/utils';
import TaskExportSchema from '@/core/Values/TaskExportSchema';
import store from '@/store';
import { ExportTaskRequest } from '@/core/Services/TaskService';

export default defineComponent({
    components: {
        DotsSvg,

        VGoalCard,
        VBoard,
        VColumn,
        VButton,
        VDraggable,
        VPage,
        VPageHeader,
        VPageContent,
        VTaskFilter,
        VSearchField,
        VTable,
        VTableRow,
        VTableDataSet,
        VPageSettings,
        VButtonDropdown,
    },
    mixins: [ProjectBoardPageMixin],

    setup() {
        const { t } = useI18n();
        const route = useRoute();

        const contextMenu = useTaskContextMenu();
        const table = useTaskTableViewer(Settings.UI.Table + '.shared', {
            header: true,
            columns: defaultColumns,
        });
        const defaultPageOptions = usePageOptions();
        const pageOptions = [defaultPageOptions.viewMode, defaultPageOptions.fullscreen];

        return { t, route, table, pageOptions, ...contextMenu };
    },

    data() {
        return {
            loadOnly: [TaskType.Goal],

            TaskFilterTypes: markRaw(TaskFilterTypes),
        };
    },

    computed: {
        regularActions(): IDropdownOption[] {
            return [
                {
                    icon: markRaw(DownloadSvg),
                    title: this.t('export'),
                    action: () => {
                        downloadFile('/api/v1/tasks/export', {
                            ...this.tasksFilters,
                            withColumns: this.table.columns
                                .filter((column) => column.visible)
                                .map((column) => column.key),
                            schema: TaskExportSchema.List,
                            language: store.state.userSettings.locale,
                        } as ExportTaskRequest);
                    },
                },
            ];
        },
    },

    created() {
        this.searchString = '';
    },
});
</script>
