<template>
    <div
        class="user-card"
        :class="{
            'user-card--tiny': tiny,
            'user-card--empty': empty,
            'user-card--fluid': fluid,
        }"
    >
        <div class="user-card__avatar">
            <template v-if="empty">
                <span class="user-card__picture user-card__picture--empty">
                    <UserSvg></UserSvg>
                </span>
            </template>

            <template v-else-if="user && user.id === '00000000-0000-0000-0000-000000000001'">
                <span class="user-card__picture user-card__picture--ninja">
                    <VUserNinjaSvg></VUserNinjaSvg>
                </span>
            </template>

            <template v-else-if="user">
                <img
                    class="user-card__picture"
                    :src="user?.picture"
                    @error="onPictureLoadError"
                    v-show="isPictureLoaded"
                    v-if="user.picture"
                />

                <span class="user-card__picture user-card__picture--symbols" v-if="!user.picture || !isPictureLoaded">
                    {{ userPictureSymbols }}
                </span>
            </template>
        </div>

        <span class="user-card__display-name" :class="{ 'user-card__display-name--blurry': empty }" v-if="!pictureOnly">
            {{ displayName }}
        </span>
    </div>
</template>

<script setup lang="ts">
// Svg
import UserSvg from '@/assets/user.svg';
import VUserNinjaSvg from '@/assets/user-ninja.svg';

// Components

// Other
import IUser from '@/core/Models/IUser';
import { computed, PropType, ref, defineProps } from 'vue';

const props = defineProps({
    user: { type: Object as PropType<IUser | null>, default: null },
    tiny: { type: Boolean, default: false },
    fluid: { type: Boolean, default: false },
    empty: { type: String, default: '' },
    pictureOnly: { type: Boolean, default: false },
});

let isPictureLoaded = ref(true);

const displayName = computed((): string => {
    return props.user?.displayName ?? props.empty ?? '';
});
const userPictureSymbols = computed((): string => {
    if (!props.user) {
        return '';
    }

    let symbols = '_';

    if (props.user.displayName?.length > 0) {
        var split = props.user.displayName.split(' ');

        if (split.length > 1) {
            symbols = split[0][0] + split[1][0]; // Evgeny Kahabrov -> EK
        } else {
            symbols = props.user.displayName.substring(0, 1); // Evgeny -> E
        }
    }

    return symbols.toUpperCase();
});

function onPictureLoadError() {
    isPictureLoaded.value = false;
}
</script>

<style lang="scss">
.user-card {
    position: relative;
    display: flex;
    width: 100%;
    height: 2rem;
    max-width: 18rem;
    overflow: hidden;

    &--fluid {
        max-width: none;
    }

    &__picture {
        display: block;
        width: 2rem;
        height: 2rem;

        cursor: default;
        user-select: none;
        border-radius: 50%;
        object-fit: cover;

        &--empty {
            color: var(--text-black-tertiary);
            border: 0.0625rem dashed var(--text-black-tertiary);

            & svg {
                width: 1.875rem;
                height: 1.875rem;
                padding: 0.1875rem;
            }
        }

        &--symbols {
            @include caption-secondary(false);
            line-height: 2rem;
            color: var(--text-black-primary);
            text-align: center;
            background: var(--background-color);
            --background-color: var(--color-avatar);
        }

        &--ninja {
            padding: 0.25rem;
        }
    }

    &__display-name {
        margin: 0 0.5rem 0 0.5rem;

        @include h6();
        @include overflow-ellipsis();
        line-height: 2rem;
        color: var(--text-black-primary);

        &--blurry {
            color: var(--text-black-tertiary);
        }
    }

    &--tiny {
        height: 1.5rem;

        & .user-card__picture {
            width: 1.5rem;
            height: 1.5rem;

            @include caption-quaternary();
            line-height: 1.625rem;
        }

        & .user-card__display-name {
            @include caption-secondary();
            line-height: 1.5rem;
        }
    }
}
</style>
