export type Stage<TValue> = (value: TValue) => TValue | Promise<TValue>;

/**
 * Creates a new pipeline. Optionally pass an array of stages
 *
 * @param presetStages[]
 * @constructor
 */
export default class Pipeline<TInValue, TOutValue = TInValue> {
    private _stages: Stage<TInValue>[] = [];

    public clone(): Pipeline<TInValue, TOutValue> {
        const clone = new Pipeline<TInValue, TOutValue>();

        clone._stages = this._stages.slice(); // copy

        return clone;
    }

    /**
     * Adds a new stage. Stage can be a function or some literal value. In case
     * of literal values. That specified value will be passed to the next stage and the
     * output from last stage gets ignored
     *
     * @param stage
     * @returns {Pipeline}
     */
    public pipe(stage: Stage<TInValue>) {
        this._stages.push(stage);

        return this;
    }

    /**
     * Processes the pipeline with passed value
     */
    public process(value: TInValue | Promise<TInValue>): Promise<TOutValue> {
        const promise = new Promise<TOutValue>((resolve, reject) => {
            if (this._stages.length === 0) {
                resolve(value as unknown as TOutValue); // force cast
                return;
            }

            // Set the stageOutput to be args
            // as there is no output to start with
            let stageOutput = value;

            for (const stage of this._stages) {
                // Output from the last stage was promise
                if (stageOutput && stageOutput instanceof Promise) {
                    // Call the next stage only when the promise is fulfilled
                    stageOutput = stageOutput.then(stage).catch(reject) as Promise<TInValue>;
                } else {
                    // Otherwise, call the next stage with the last stage output
                    if (typeof stage === 'function') {
                        try {
                            stageOutput = stage(stageOutput);
                        } catch (error) {
                            reject(error);
                            return;
                        }
                    } else {
                        stageOutput = stage;
                    }
                }
            }

            if (stageOutput && stageOutput instanceof Promise) {
                stageOutput.then((value) => resolve(value as unknown as TOutValue)); // force cast
            } else {
                resolve(stageOutput as unknown as TOutValue); // force cast
            }
        });

        return promise;
    }
}
