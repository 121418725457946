<template>
    <div class="app-content">
        <slot></slot>
    </div>
</template>

<style lang="scss">
.app-content {
    display: flex;
    position: relative;
}
</style>
