import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDirective as _resolveDirective, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives, withModifiers as _withModifiers, mergeProps as _mergeProps, createElementVNode as _createElementVNode, withKeys as _withKeys, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Teleport as _Teleport } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TimesSvg = _resolveComponent("TimesSvg")!
  const _component_VButton = _resolveComponent("VButton")!
  const _directive_focus = _resolveDirective("focus")!

  return (_openBlock(), _createBlock(_Teleport, { to: "body" }, [
    (_ctx.visible)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "v-modal-overlay prevent-close",
          onKeydown: _cache[1] || (_cache[1] = _withKeys(
//@ts-ignore
(...args) => (_ctx.close && _ctx.close(...args)), ["esc"])),
          onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.close && _ctx.close(...args)))
        }, [
          _createElementVNode("div", _mergeProps({
            ref: "modal",
            class: "v-modal",
            tabindex: "-1",
            onClick: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["stop"]))
          }, _ctx.$attrs), [
            _renderSlot(_ctx.$slots, "default"),
            _withDirectives((_openBlock(), _createBlock(_component_VButton, {
              ref: "close",
              class: "button--transparent button--rounded v-modal__button--close",
              onClick: _ctx.close
            }, {
              default: _withCtx(() => [
                _createVNode(_component_TimesSvg)
              ]),
              _: 1
            }, 8, ["onClick"])), [
              [_directive_focus]
            ])
          ], 16)
        ], 32))
      : _createCommentVNode("", true)
  ]))
}