import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode } from "vue"

import NoDataSvg from '@/assets/no-data.svg';
import NoDataDarkSvg from '@/assets/no-data-dark.svg';

// Components
import VPage from '../components/VPage.vue';
import VPageContent from '../components/VPageContent.vue';
import VIllustration from '../components/VIllustration.vue';

// Other
import Storages from '@/core/Storages';
import Settings from '@/core/Settings';
import { useI18n } from 'vue-i18n';


export default /*@__PURE__*/_defineComponent({
  __name: 'NotFoundPage',
  setup(__props) {

// Svg
const { t } = useI18n();

const theme = Storages.Settings.get(Settings.UI.Theme);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(VPage, null, {
    default: _withCtx(() => [
      _createVNode(VPageContent, {
        "align-center": "",
        "justify-center": ""
      }, {
        default: _withCtx(() => [
          _createVNode(VIllustration, {
            tip: _unref(t)('page-not-found')
          }, {
            default: _withCtx(() => [
              (_unref(theme) === 'light')
                ? (_openBlock(), _createBlock(_unref(NoDataSvg), { key: 0 }))
                : (_unref(theme) === 'dark')
                  ? (_openBlock(), _createBlock(_unref(NoDataDarkSvg), { key: 1 }))
                  : _createCommentVNode("", true)
            ]),
            _: 1
          }, 8, ["tip"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}
}

})