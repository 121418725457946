<template>
    <div class="app-page" :class="classes">
        <slot></slot>
    </div>
</template>

<script setup lang="ts">
// Svg
// ...

// Components
// ...

// Other
import { computed, defineProps } from 'vue';

const props = defineProps({
    fluid: { type: Boolean, default: false },
    layer: { type: Boolean, default: false },
    noShrink: { type: Boolean, default: false },
});

const classes = computed(() => ({
    'app-page--layer': props.layer,
    'app-page--no-shrink': props.noShrink,
}));
</script>

<style lang="scss">
.app-page {
    display: flex;
    flex-grow: 1;
    width: 100%;
    height: 100%;
    flex-direction: column;

    &--panel {
        border: 0.0625rem solid var(--background-tertiary);
        background: var(--background-color);
        --background-color: var(--background-primary);
        box-shadow: 0 0 0.6875rem 0 rgba(0, 0, 0, 0.07);
    }

    &--no-shrink {
        flex-shrink: 1;
    }

    &--fullscreen {
        position: absolute;
        left: 0;
        right: 0;
    }
}
</style>
