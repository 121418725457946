<template>
    <VModal class="v-modal--error" v-model:visible="visible" @close="close">
        <h2 class="v-modal__row v-modal__row--header v-modal__title">
            {{ errorTitle }}
        </h2>

        <p class="v-modal__row v-modal__row--content v-modal__content">
            <template v-for="(chunk, index) in errorMessage.split('\n')" :key="index">
                {{ chunk }}
                <br />
            </template>
        </p>

        <div class="v-modal__row v-modal__row--actions">
            <VButton class="button--rectangle button--center button--blurry v-modal__action" @click="reload">
                {{ t('reload') }}</VButton
            >

            <a
                target="_blank"
                :href="serviceDeskUri"
                class="button--rectangle button--center button--negative v-modal__action"
            >
                {{ t('goto-service-desk') }}
            </a>
        </div>
    </VModal>
</template>

<script setup lang="ts">
// Svg
// ...

// Components
import VModal from './VModal.vue';
import VButton from './VButton.vue';

// Other
import emitter from '@/core/Emitter';
import { EventNames } from '@/core/EventNames';
import { markRaw, ref } from 'vue';
import { useI18n } from 'vue-i18n';

const { t, te } = useI18n();

let visible = ref(false);
let serviceDeskUri = markRaw(process.env.VUE_APP_SERVICE_DESK_URI);
let errorTitle = ref('');
let errorMessage = ref('');

emitter.on(EventNames.Error, open);

function open(error: unknown) {
    visible.value = true;

    let localizationKey = '';

    if (error instanceof Error) {
        localizationKey = error.message;
    }

    if (typeof error === 'string') {
        localizationKey = error;
    }

    errorTitle.value = t('error');
    errorMessage.value = te('errors.' + localizationKey) ? t('errors.' + localizationKey) : t('unknown-error');
}

function close() {
    visible.value = false;

    errorTitle.value = '';
    errorMessage.value = '';
}

function reload() {
    location.reload();
    return false;
}
</script>

<style lang="scss">
.v-modal {
    &--error {
        width: 32rem;
    }
}
</style>
