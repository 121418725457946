<template>
    <VPage>
        <VPageContent align-center justify-center>
            <VIllustration :tip="t('page-not-found')">
                <NoDataSvg v-if="theme === 'light'"></NoDataSvg>
                <NoDataDarkSvg v-else-if="theme === 'dark'"></NoDataDarkSvg>
            </VIllustration>
        </VPageContent>
    </VPage>
</template>

<script setup lang="ts">
// Svg
import NoDataSvg from '@/assets/no-data.svg';
import NoDataDarkSvg from '@/assets/no-data-dark.svg';

// Components
import VPage from '../components/VPage.vue';
import VPageContent from '../components/VPageContent.vue';
import VIllustration from '../components/VIllustration.vue';

// Other
import Storages from '@/core/Storages';
import Settings from '@/core/Settings';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const theme = Storages.Settings.get(Settings.UI.Theme);
</script>
