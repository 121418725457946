import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, unref as _unref, createVNode as _createVNode, Fragment as _Fragment, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "attachment__uploader"
}
const _hoisted_2 = { class: "attachment__progressbar" }
const _hoisted_3 = {
  key: 0,
  class: "attachment__error"
}
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "attachment__picture attachment__picture--default" }
const _hoisted_6 = ["href"]

import EyeSvg from '@/assets/eye.svg';
import TimesSvg from '@/assets/times.svg';
import DownloadSvg from '@/assets/download.svg';
import UnknownFileSvg from '@/assets/unknown-file.svg';

// Components
// ...

// Other
import IAttachment from '@/core/Values/IAttachment';
import UploadableFile from '@/core/Uploader/UploadableFile';
import IApiResult from '@/core/IApiResult';
import IObjectStoreModel from '@/core/Values/IObjectStoreModel';
import UploadableFileStatus from '@/core/Uploader/UploadableFileStatus';
import { $showImage } from '@/utils/app-utils';
import AuthorizationProvider from '@/core/Authorization/AuthorizationProvider';
import Operations from '@/core/Authorization/Operations';
import { computed, PropType } from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'VAttachment',
  props: {
    attachment: { type: Object as PropType<IAttachment | null>, default: null },
    uploadable: { type: Object as PropType<UploadableFile<IApiResult<IObjectStoreModel>> | null>, default: null },
    removable: { type: Boolean, default: false },
    nowrap: { type: Boolean, default: false },
},
  setup(__props) {

// Svg
const browsableExtensions = ['.jpg', '.jpeg', '.png', '.svg', '.tiff', '.webp'];

const props = __props;

const isError = computed((): boolean => {
    return props.uploadable?.status === UploadableFileStatus.ERROR;
});

const isBrowsable = computed((): boolean => {
    if (!props.attachment) {
        return false;
    }

    return browsableExtensions.includes(props.attachment.extension);
});

const generalHint = computed((): string => {
    if (props.attachment) {
        return props.attachment.fileName;
    }

    if (props.uploadable) {
        return props.uploadable?.errorMessage ? props.uploadable.errorMessage : props.uploadable.name;
    }

    return '';
});

const canDelete = computed((): boolean => {
    if (props.uploadable) {
        return true;
    }

    if (props.attachment && props.attachment.authorId) {
        return AuthorizationProvider.authorize(props.attachment, Operations.DeleteStory);
    }

    return false;
});

function open() {
    if (!props.attachment) {
        return;
    }

    $showImage(props.attachment);
}

return (_ctx: any,_cache: any) => {
  const _directive_tooltip = _resolveDirective("tooltip")!

  return _withDirectives((_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["attachment", {
            'attachment--preview': isBrowsable.value,
        }])
  }, [
    (__props.uploadable)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("span", {
            class: _normalizeClass(["attachment__filename", { 'attachment__filename--nowrap': __props.nowrap }])
          }, _toDisplayString(__props.uploadable.name), 3),
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", {
              class: _normalizeClass(["attachment__progressbar-inner", {
                            'attachment__progressbar-inner--error': isError.value,
                        }]),
              style: _normalizeStyle({
                            width: __props.uploadable.progress + '%',
                        })
            }, null, 6)
          ]),
          (__props.uploadable.errorMessage)
            ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(__props.uploadable.errorMessage), 1))
            : _createCommentVNode("", true)
        ]))
      : (__props.attachment)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
            (isBrowsable.value)
              ? (_openBlock(), _createElementBlock("img", {
                  key: 0,
                  class: "attachment__picture",
                  src: __props.attachment.downloadUri
                }, null, 8, _hoisted_4))
              : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                  _createElementVNode("div", _hoisted_5, [
                    _createVNode(_unref(UnknownFileSvg))
                  ]),
                  _createElementVNode("span", {
                    class: _normalizeClass(["attachment__filename", { 'attachment__filename--nowrap': __props.nowrap }])
                  }, _toDisplayString(__props.attachment.fileName), 3)
                ], 64)),
            (isBrowsable.value)
              ? (_openBlock(), _createElementBlock("button", {
                  key: 2,
                  class: "attachment__button attachment__button--borderless attachment__button--download",
                  onClick: open
                }, [
                  _createVNode(_unref(EyeSvg))
                ]))
              : (_openBlock(), _createElementBlock("a", {
                  key: 3,
                  target: "_blank",
                  class: "attachment__button attachment__button--borderless attachment__button--download",
                  href: __props.attachment.downloadUri
                }, [
                  _createVNode(_unref(DownloadSvg))
                ], 8, _hoisted_6))
          ], 64))
        : _createCommentVNode("", true),
    (__props.removable && canDelete.value)
      ? (_openBlock(), _createElementBlock("button", {
          key: 2,
          class: "attachment__button attachment__button--remove",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('removed', __props.attachment ?? __props.uploadable)))
        }, [
          _createVNode(_unref(TimesSvg))
        ]))
      : _createCommentVNode("", true)
  ], 2)), [
    [_directive_tooltip, generalHint.value]
  ])
}
}

})