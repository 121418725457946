<template>
    <button class="button">
        <slot></slot>
    </button>
</template>

<style lang="scss">
.button {
    display: flex;
    margin: 0;
    padding: 0;
    border: none;
    cursor: pointer;
    user-select: none;
    border-radius: 0;
    background-color: var(--background-color);
    --background-color: transparent;
    text-decoration: none;
    transition: 0.06s;

    &:focus-visible {
        outline: none;
        box-shadow: 0 0 0 0.0625rem var(--color-blue), 0 0 0 0.1875rem var(--color-lightskyblue);
    }

    & svg {
        flex-shrink: 0;
        width: 1.5rem;
        height: 1.5rem;
        margin-right: 0.125rem;
    }

    &--primary {
        flex-shrink: 0;
        padding: 0.4375rem 0.75rem;
        overflow: hidden;

        @include button-primary(false);
        white-space: nowrap;
        border-radius: 1.125rem;
        line-height: 1.375rem;

        & svg {
            width: 1.375rem;
            height: 1.375rem;
        }
    }

    &--secondary {
        flex-shrink: 0;
        padding: 0.3125rem 0.75rem;
        overflow: hidden;

        @include button-secondary(false);
        white-space: nowrap;
        line-height: 1.125rem;
        border-radius: 1.125rem;

        & svg {
            width: 1.125rem;
            height: 1.125rem;
        }
    }

    &--rounded {
        padding: 0.125rem;
        border-radius: 0.5rem;
    }

    &--wide {
        width: 11.5rem;
    }

    &--fluid {
        width: 100%;
    }

    &--center {
        justify-content: center;
    }

    &--vcenter {
        align-items: center;
    }

    &--circle {
        padding: 0.25rem;
        border-radius: 50%;
    }

    &--rectangle {
        padding: 0.875rem 1rem;
        border-radius: 0.5rem;
    }

    &--link,
    &--link-underline {
        @include link();
        color: var(--text-black-secondary);

        &:hover,
        &:focus-visible,
        &.active {
            color: var(--brand-green);
        }

        &:active {
            color: var(--font-size-button-primary);
        }

        &:disabled {
            color: var(--text-black-tertiary);
        }
    }

    &--link-green {
        @include link();
        color: var(--brand-green);

        &:hover,
        &:focus-visible,
        &.active {
            color: var(--text-black-secondary);
        }

        &:active {
            color: var(--font-button-primary);
        }

        &:disabled {
            color: var(--text-black-tertiary);
        }
    }

    &--link-inline {
        display: inline-block;
    }

    &--link-underline {
        text-decoration-line: underline;
    }

    &--default {
        color: var(--text-black-primary);
        background: var(--background-color);
        --background-color: var(--background-tertiary);

        &:hover,
        &:focus-visible,
        &.active {
            color: var(--brand-green);
        }

        &:active {
            color: var(--font-size-button-primary);
        }

        &:disabled {
            color: var(--text-black-tertiary);
        }

        & svg {
            width: 1.125rem;
            height: 1.125rem;
        }
    }

    &--blurry {
        color: var(--text-black-secondary);
        background: var(--background-color);
        --background-color: var(--background-tertiary);

        &:hover,
        &:focus-visible,
        &.active {
            color: var(--text-black-primary);
        }

        &:active {
            color: var(--text-black-secondary);
        }

        &:disabled {
            color: var(--text-white-tertiary);
        }
    }

    &--negative {
        color: var(--text-white-primary);
        background: var(--background-color);
        --background-color: var(--color-negative);

        &:hover,
        &:focus-visible,
        &.active {
            color: var(--text-white-primary);
        }

        &:active {
            color: var(--color-negative);
            background: var(--background-color);
            --background-color: none;
        }

        &:disabled {
            color: var(--text-white-tertiary);
        }
    }

    &--green {
        color: var(--text-white-primary);
        background: var(--background-color);
        --background-color: var(--brand-green);

        &:hover,
        &:focus-visible,
        &.active {
            color: var(--text-white-primary);
        }

        &:active {
            color: var(--brand-green);
            background: var(--background-color);
            --background-color: none;
        }

        &:disabled {
            opacity: 0.6;
        }
    }

    &--transparent {
        color: var(--text-black-primary);
        background-color: var(--background-color);
        --background-color: transparent;

        &:hover,
        &:focus-visible,
        &.active {
            background: var(--background-color);
            --background-color: var(--background-tertiary);
        }

        &:active {
            background: var(--background-color);
            --background-color: none;
        }

        &:disabled {
            color: var(--text-black-tertiary);
        }

        & svg {
            color: var(--text-black-primary);
        }
    }

    &--transparent-negative {
        background-color: var(--background-color);
        --background-color: transparent;
        color: var(--color-negative) !important;

        &:active {
            background: var(--background-color);
            --background-color: none;
        }

        &:disabled {
            color: var(--text-black-tertiary);
        }

        & svg {
            color: var(--color-negative) !important;
        }
    }

    &--overflow-ellipsis {
        @include overflow-ellipsis();
    }

    &--margin-left {
        margin-left: 0.375rem;
    }

    &--margin-right {
        margin-right: 0.375rem;
    }

    &--lowercase {
        text-transform: lowercase;
    }

    &:disabled {
        cursor: not-allowed;
        pointer-events: none;
    }
}
</style>
