import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, isRef as _isRef, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, createVNode as _createVNode, withDirectives as _withDirectives, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "v-modal__row v-modal__row--header v-modal__title" }
const _hoisted_2 = { class: "v-modal__row v-modal__row--content v-modal__content" }
const _hoisted_3 = { class: "v-modal__hint" }
const _hoisted_4 = { class: "error-message" }
const _hoisted_5 = { class: "v-modal__row v-modal__row--actions" }

import VModal from './VModal.vue';
import VButton from './VButton.vue';

// Other
import emitter from '@/core/Emitter';
import { EventNames } from '@/core/EventNames';
import ConfirmContext, { ConfirmOpenEvent } from '@/core/Values/ConfirmContext';
import VContentEditable from './VContentEditable.vue';
import { debounce } from 'debounce';
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';

type DebounceFunction = (() => void) & { clear(): void } & { flush(): void };


export default /*@__PURE__*/_defineComponent({
  __name: 'VModalConfirm',
  setup(__props) {

// Svg
// ...

// Components
const { t } = useI18n();

let comment = ref('' as string | undefined);
let commentErrorMessage = ref(null as string | null);
let commentErrorDebounce = ref(null as DebounceFunction | null);
let visible = ref(false);
let context = ref(null as ConfirmContext | null);

emitter.on(EventNames.Confirm, open);

function open(event: ConfirmOpenEvent) {
    comment.value = '';
    visible.value = true;
    context.value = {
        title: t('confirm-title'),
        buttonOkName: t('ok'),
        buttonCancelName: t('cancel'),
        ...event,
    };
}

function ok() {
    if (context.value?.commentRequired && !comment.value) {
        commentErrorMessage.value = t('field-required');

        if (!commentErrorDebounce.value) {
            commentErrorDebounce.value = debounce(
                () => (commentErrorMessage.value = commentErrorDebounce.value = null),
                3000,
            ); // 3s
        }

        if (commentErrorDebounce.value) {
            commentErrorDebounce.value();
        }

        return;
    }

    if (context.value?.okCallback) {
        context.value.okCallback(comment.value);
    }

    context.value = null;
    visible.value = false;
}

function close() {
    commentErrorDebounce.value?.clear();
    commentErrorDebounce.value = null;
    commentErrorMessage.value = null;

    if (context.value?.cancelCallback) {
        context.value.cancelCallback(comment.value);
    }

    context.value = null;
    visible.value = false;
}

return (_ctx: any,_cache: any) => {
  const _directive_focus = _resolveDirective("focus")!

  return (_openBlock(), _createBlock(VModal, {
    class: "v-modal--error",
    visible: _unref(visible),
    "onUpdate:visible": _cache[1] || (_cache[1] = ($event: any) => (_isRef(visible) ? (visible).value = $event : visible = $event)),
    onClose: close
  }, {
    default: _withCtx(() => [
      _createElementVNode("h2", _hoisted_1, _toDisplayString(_unref(context)?.title), 1),
      _createElementVNode("p", _hoisted_2, _toDisplayString(_unref(context)?.message), 1),
      (_unref(context)?.withComment)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            _withDirectives(_createVNode(VContentEditable, {
              class: _normalizeClass(["simple-contenteditable--great simple-contenteditable--bordered", { error: _unref(commentErrorMessage) }]),
              maxlength: _unref(context).commentMaxLength,
              placeholder: _unref(t)('your-comment'),
              modelValue: _unref(comment),
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_isRef(comment) ? (comment).value = $event : comment = $event))
            }, null, 8, ["class", "maxlength", "placeholder", "modelValue"]), [
              [_directive_focus]
            ]),
            _createElementVNode("small", _hoisted_3, _toDisplayString(_unref(context).commentHint), 1),
            _createElementVNode("small", _hoisted_4, _toDisplayString(_unref(commentErrorMessage)), 1)
          ], 64))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_5, [
        _createVNode(VButton, {
          class: _normalizeClass(["button--rectangle button--center button--blurry v-modal__action", _unref(context)?.buttonCancelClasses]),
          onClick: close
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_unref(context)?.buttonCancelName), 1)
          ]),
          _: 1
        }, 8, ["class"]),
        _createVNode(VButton, {
          class: _normalizeClass(["button--rectangle button--center button--negative v-modal__action", _unref(context)?.buttonOkClasses]),
          onClick: ok
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_unref(context)?.buttonOkName), 1)
          ]),
          _: 1
        }, 8, ["class"])
      ])
    ]),
    _: 1
  }, 8, ["visible"]))
}
}

})