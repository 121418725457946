import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "illustration" }
const _hoisted_2 = {
  key: 0,
  class: "illustration__tip"
}
const _hoisted_3 = { class: "illustration__tip-text" }




export default /*@__PURE__*/_defineComponent({
  __name: 'VIllustration',
  props: {
    tip: { type: String, default: '' },
},
  setup(__props) {

// Svg
// ...

// Components
// ...

// Other


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _renderSlot(_ctx.$slots, "default"),
    (__props.tip)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("p", _hoisted_3, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.tip.split('\n'), (chunk, index) => {
              return (_openBlock(), _createElementBlock(_Fragment, { key: index }, [
                _createTextVNode(_toDisplayString(chunk) + " ", 1),
                _cache[0] || (_cache[0] = _createElementVNode("br", null, null, -1))
              ], 64))
            }), 128))
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}
}

})