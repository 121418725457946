import HttpTransport from './HttpTransport';
import IApiResult from './IApiResult';

interface ITransport {
    invokeAsync<TPayload, TResult>(method: string, endpoint: string, payload?: TPayload | undefined): Promise<TResult>;

    invokeApiAsync<TPayload, TResult>(
        method: string,
        endpoint: string,
        payload?: TPayload | undefined,
    ): Promise<IApiResult<TResult>>;

    invokeRawAsync<TPayload, TResult>(
        method: string,
        endpoint: string,
        payload?: TPayload | undefined,
    ): Promise<TResult>;
}

export { ITransport };
export default HttpTransport;
