import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VUserCard = _resolveComponent("VUserCard")!
  const _component_VSelect = _resolveComponent("VSelect")!

  return (_openBlock(), _createBlock(_component_VSelect, {
    ref: "select",
    class: "v-select--primary v-select--visible v-select--no-option-padding",
    label: "id",
    options: _ctx.options,
    multiple: _ctx.multiple,
    filterable: false,
    placeholder: _ctx.t('add-user'),
    disabled: _ctx.disabled,
    onOpen: _ctx.fetchOptionsIfNotExists,
    onSearch: _ctx.fetchOptions,
    modelValue: _ctx.users,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.users) = $event))
  }, {
    "no-options": _withCtx(() => [
      _createElementVNode("span", null, _toDisplayString(_ctx.t('no-options')), 1)
    ]),
    option: _withCtx((option) => [
      _createElementVNode("div", {
        class: _normalizeClass(["user-selector__select-option", {
                    'user-selector__select-option--selected': _ctx.contains(option),
                }])
      }, [
        _createVNode(_component_VUserCard, {
          tiny: "",
          user: option
        }, null, 8, ["user"])
      ], 2)
    ]),
    "selected-option": _withCtx((option) => [
      _createVNode(_component_VUserCard, {
        tiny: "",
        user: option
      }, null, 8, ["user"])
    ]),
    _: 1
  }, 8, ["options", "multiple", "placeholder", "disabled", "onOpen", "onSearch", "modelValue"]))
}