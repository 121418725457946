<template>
    <nav class="app-tabbar">
        <RouterLink :to="{ name: 'user.tasks' }" class="button button--fluid app-tabbar__button">
            <SidebarMyTasksSvg></SidebarMyTasksSvg>

            <span class="app-tabbar__button-title">
                {{ t('my-tasks') }}
            </span>
        </RouterLink>

        <RouterLink :to="{ name: 'user.goals' }" class="button button--fluid app-tabbar__button">
            <SidebarMyGoalsSvg></SidebarMyGoalsSvg>

            <span class="app-tabbar__button-title">
                {{ t('my-goals') }}
            </span>
        </RouterLink>

        <VButton class="button--fluid app-tabbar__button" @click="showCreateModal = true">
            <PlusSvg></PlusSvg>

            <span class="app-tabbar__button-title">
                {{ t('create') }}
            </span>
        </VButton>

        <RouterLink
            :to="{ name: 'user.notifications' }"
            class="button button--fluid app-tabbar__button app-tabbar__button--attention"
            :class="{
                'app-tabbar__button--with-counter': notificationsCounter != 0, // not !==
            }"
            :counter="notificationsCounter"
        >
            <SidebarNotificationsSvg></SidebarNotificationsSvg>

            <span class="app-tabbar__button-title">
                {{ t('notifications') }}
            </span>
        </RouterLink>

        <VButton class="button--fluid app-tabbar__button" @click="router.push({ query: { sidebar: 1 } })">
            <DotsSvg></DotsSvg>

            <span class="app-tabbar__button-title">
                {{ t('menu') }}
            </span>
        </VButton>

        <VModal v-model:visible="showCreateModal">
            <h2 class="v-modal__row v-modal__row--header v-modal__title">
                {{ t('select-what-create') }}
            </h2>

            <div class="v-modal__row v-modal__row--actions v-modal__row--vertical">
                <VButton
                    class="button--blurry button--rectangle button--center v-modal__action"
                    @click="createEmptyGoal"
                >
                    {{ t('create-goal') }}
                </VButton>

                <VButton
                    class="button--blurry button--rectangle button--center v-modal__action"
                    @click="createEmptyTask"
                >
                    {{ t('create-task') }}
                </VButton>
            </div>
        </VModal>
    </nav>
</template>

<script setup lang="ts">
// Svg
import PlusSvg from '../assets/plus.svg';
import DotsSvg from '../assets/dots.svg';
import SidebarMyTasksSvg from '../assets/sidebar-my-tasks.svg';
import SidebarMyGoalsSvg from '../assets/sidebar-my-goals.svg';
import SidebarNotificationsSvg from '../assets/sidebar-notifications.svg';

// Components
import VButton from './VButton.vue';

// Other
import VModal from './VModal.vue';
import context from '@/core/Mutators/NotificationCounter';
import TaskType from '@/core/Values/TaskType';
import TaskService from '@/core/Services/TaskService';
import MutationType from '../core/Mutations/MutationType';
import ObjectType from '../core/Mutations/ObjectType';
import { $error } from '@/utils/app-utils';
import ITask from '../core/Models/ITask';
import { useRouter } from 'vue-router';
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();
const router = useRouter();

context.initialize();

let isLocked = ref(false);
let showCreateModal = ref(false);

const notificationsCounter = context.counter;

async function createEmptyGoal() {
    isLocked.value = true;

    try {
        const mutations = await TaskService.createAsync(TaskType.Goal);

        const mutation = mutations.find(
            (mutation) => mutation.type === MutationType.Created && mutation.objectType === ObjectType.Task,
        );

        if (!mutation) {
            return;
        }

        router.push({ name: 'goals.view', params: { goalId: (mutation.objectState as ITask).id } });
    } catch (error) {
        $error(error);
    } finally {
        isLocked.value = false;
    }
}

async function createEmptyTask() {
    isLocked.value = true;

    try {
        const mutations = await TaskService.createAsync(TaskType.Task);

        const mutation = mutations.find(
            (mutation) => mutation.type === MutationType.Created && mutation.objectType === ObjectType.Task,
        );

        if (!mutation) {
            return;
        }

        router.replace({ name: 'user.tasks', query: { task: (mutation.objectState as ITask).id } });
    } catch (error) {
        $error(error);
    } finally {
        isLocked.value = false;
    }
}
</script>

<style lang="scss">
$tabbar-height: 3.75rem;

.app-layout {
    padding-bottom: #{$tabbar-height};

    @media (min-width: #{$breakpoint-tablet + 1}) {
        padding-bottom: 0;
    }
}

.app-tabbar {
    position: fixed;
    display: flex;
    justify-content: space-around;
    left: 0;
    right: 0;
    bottom: 0;
    height: #{$tabbar-height};
    justify-content: center;
    gap: 0.375rem;
    padding: 0 0.375rem;
    border-top: 0.0625rem solid var(--background-tertiary);
    background-color: var(--background-color);
    --background-color: var(--background-primary);

    @media (min-width: #{$breakpoint-tablet + 1}) {
        display: none;
    }

    &__button {
        display: flex;
        width: 20%;
        padding: 0.6875rem 0;
        flex-direction: column;
        overflow: hidden;

        color: var(--text-black-primary);

        & svg {
            width: 1.5rem;
            height: 1.5rem;
            margin: 0 auto;
        }

        &:hover,
        &:focus-visible {
            color: var(--brand-green);
        }

        &:active {
            color: var(--button-primary);
        }

        &:disabled {
            color: var(--text-black-tertiary);
        }

        &--attention,
        &--attention &--with-counter::after {
            color: var(--brand-orange);
        }

        &--active,
        &.router-link-active {
            color: var(--brand-green);
        }
    }

    &__button-title {
        width: 100%;

        @include h6();
        @include overflow-ellipsis();
        font-size: var(--font-size-caption-fifth);
        line-height: 100%;
        text-align: center;
    }
}
</style>
