enum Status {
    Draft = 1,
    Approvement = 2,
    InProgress = 3,
    Completed = 4,
    Rejected = 5,
    Finished = 6,
}

class StatusTitle {
    static [Status.Draft] = 'statuses.draft';
    static [Status.Approvement] = 'statuses.approvement';
    static [Status.InProgress] = 'statuses.in-progress';
    static [Status.Completed] = 'statuses.completed';
    static [Status.Rejected] = 'statuses.rejected';
    static [Status.Finished] = 'statuses.finished';
}

Object.freeze(Status);
Object.freeze(StatusTitle);

export { StatusTitle };
export default Status;
