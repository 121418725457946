<template>
    <div
        class="board"
        :class="{
            'board--center': center,
            'board--vertical': vertical,
            'board--horizontal': horizontal,
        }"
    >
        <slot></slot>
    </div>
</template>

<script setup lang="ts">
// Svg
// ...

// Components

// Other
import { computed, defineProps } from 'vue';

const props = defineProps({
    center: { type: Boolean, default: false },
    vertical: { type: Boolean, default: false },
});

const horizontal = computed((): boolean => {
    return !props.vertical;
});
</script>

<style lang="scss">
.board {
    display: flex;
    gap: 0.75rem;
    margin: 0 0.75rem;
    padding-top: 0.5rem;
    flex-grow: 1;

    @media (min-width: #{$breakpoint-tablet + 1}) {
        margin: 0 2rem;
    }

    &--center {
        align-items: center;
    }

    &--horizontal {
        height: 100%; // DON'T TOUCH
        min-height: 0; // DON'T TOUCH
    }

    &--vertical {
        flex-direction: column;
    }
}
</style>
