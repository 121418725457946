import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, isRef as _isRef, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "app-tabbar" }
const _hoisted_2 = { class: "app-tabbar__button-title" }
const _hoisted_3 = { class: "app-tabbar__button-title" }
const _hoisted_4 = { class: "app-tabbar__button-title" }
const _hoisted_5 = { class: "app-tabbar__button-title" }
const _hoisted_6 = { class: "app-tabbar__button-title" }
const _hoisted_7 = { class: "v-modal__row v-modal__row--header v-modal__title" }
const _hoisted_8 = { class: "v-modal__row v-modal__row--actions v-modal__row--vertical" }

import PlusSvg from '../assets/plus.svg';
import DotsSvg from '../assets/dots.svg';
import SidebarMyTasksSvg from '../assets/sidebar-my-tasks.svg';
import SidebarMyGoalsSvg from '../assets/sidebar-my-goals.svg';
import SidebarNotificationsSvg from '../assets/sidebar-notifications.svg';

// Components
import VButton from './VButton.vue';

// Other
import VModal from './VModal.vue';
import context from '@/core/Mutators/NotificationCounter';
import TaskType from '@/core/Values/TaskType';
import TaskService from '@/core/Services/TaskService';
import MutationType from '../core/Mutations/MutationType';
import ObjectType from '../core/Mutations/ObjectType';
import { $error } from '@/utils/app-utils';
import ITask from '../core/Models/ITask';
import { useRouter } from 'vue-router';
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';


export default /*@__PURE__*/_defineComponent({
  __name: 'VAppSidebarMobile',
  setup(__props) {

// Svg
const { t } = useI18n();
const router = useRouter();

context.initialize();

let isLocked = ref(false);
let showCreateModal = ref(false);

const notificationsCounter = context.counter;

async function createEmptyGoal() {
    isLocked.value = true;

    try {
        const mutations = await TaskService.createAsync(TaskType.Goal);

        const mutation = mutations.find(
            (mutation) => mutation.type === MutationType.Created && mutation.objectType === ObjectType.Task,
        );

        if (!mutation) {
            return;
        }

        router.push({ name: 'goals.view', params: { goalId: (mutation.objectState as ITask).id } });
    } catch (error) {
        $error(error);
    } finally {
        isLocked.value = false;
    }
}

async function createEmptyTask() {
    isLocked.value = true;

    try {
        const mutations = await TaskService.createAsync(TaskType.Task);

        const mutation = mutations.find(
            (mutation) => mutation.type === MutationType.Created && mutation.objectType === ObjectType.Task,
        );

        if (!mutation) {
            return;
        }

        router.replace({ name: 'user.tasks', query: { task: (mutation.objectState as ITask).id } });
    } catch (error) {
        $error(error);
    } finally {
        isLocked.value = false;
    }
}

return (_ctx: any,_cache: any) => {
  const _component_RouterLink = _resolveComponent("RouterLink")!

  return (_openBlock(), _createElementBlock("nav", _hoisted_1, [
    _createVNode(_component_RouterLink, {
      to: { name: 'user.tasks' },
      class: "button button--fluid app-tabbar__button"
    }, {
      default: _withCtx(() => [
        _createVNode(_unref(SidebarMyTasksSvg)),
        _createElementVNode("span", _hoisted_2, _toDisplayString(_unref(t)('my-tasks')), 1)
      ]),
      _: 1
    }),
    _createVNode(_component_RouterLink, {
      to: { name: 'user.goals' },
      class: "button button--fluid app-tabbar__button"
    }, {
      default: _withCtx(() => [
        _createVNode(_unref(SidebarMyGoalsSvg)),
        _createElementVNode("span", _hoisted_3, _toDisplayString(_unref(t)('my-goals')), 1)
      ]),
      _: 1
    }),
    _createVNode(VButton, {
      class: "button--fluid app-tabbar__button",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_isRef(showCreateModal) //@ts-ignore
 ? showCreateModal.value = true : showCreateModal = true))
    }, {
      default: _withCtx(() => [
        _createVNode(_unref(PlusSvg)),
        _createElementVNode("span", _hoisted_4, _toDisplayString(_unref(t)('create')), 1)
      ]),
      _: 1
    }),
    _createVNode(_component_RouterLink, {
      to: { name: 'user.notifications' },
      class: _normalizeClass(["button button--fluid app-tabbar__button app-tabbar__button--attention", {
                'app-tabbar__button--with-counter': _unref(notificationsCounter) != 0, // not !==
            }]),
      counter: _unref(notificationsCounter)
    }, {
      default: _withCtx(() => [
        _createVNode(_unref(SidebarNotificationsSvg)),
        _createElementVNode("span", _hoisted_5, _toDisplayString(_unref(t)('notifications')), 1)
      ]),
      _: 1
    }, 8, ["class", "counter"]),
    _createVNode(VButton, {
      class: "button--fluid app-tabbar__button",
      onClick: _cache[1] || (_cache[1] = ($event: any) => (_unref(router).push({ query: { sidebar: 1 } })))
    }, {
      default: _withCtx(() => [
        _createVNode(_unref(DotsSvg)),
        _createElementVNode("span", _hoisted_6, _toDisplayString(_unref(t)('menu')), 1)
      ]),
      _: 1
    }),
    _createVNode(VModal, {
      visible: _unref(showCreateModal),
      "onUpdate:visible": _cache[2] || (_cache[2] = ($event: any) => (_isRef(showCreateModal) ? (showCreateModal).value = $event : showCreateModal = $event))
    }, {
      default: _withCtx(() => [
        _createElementVNode("h2", _hoisted_7, _toDisplayString(_unref(t)('select-what-create')), 1),
        _createElementVNode("div", _hoisted_8, [
          _createVNode(VButton, {
            class: "button--blurry button--rectangle button--center v-modal__action",
            onClick: createEmptyGoal
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_unref(t)('create-goal')), 1)
            ]),
            _: 1
          }),
          _createVNode(VButton, {
            class: "button--blurry button--rectangle button--center v-modal__action",
            onClick: createEmptyTask
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_unref(t)('create-task')), 1)
            ]),
            _: 1
          })
        ])
      ]),
      _: 1
    }, 8, ["visible"])
  ]))
}
}

})