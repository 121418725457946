import Methods from '../Methods';
import IBoard from '../Models/IBoard';
import IColumn from '../Models/IColumn';
import IMutation from '../Mutations/IMutation';
import Transport, { ITransport } from '../Transports';

class BoardService {
    private _transport: ITransport;

    constructor(transport: ITransport) {
        this._transport = transport;
    }

    public findAsync(boardId: number): Promise<IBoard> {
        return this._transport.invokeAsync<undefined, IBoard>(Methods.Get, `/api/v1/boards/${boardId}`);
    }

    public getAllAsync(): Promise<IBoard[]> {
        return this._transport.invokeAsync<undefined, IBoard[]>(Methods.Get, `/api/v1/boards`);
    }

    public getBoardColumnsAsync(boardId: number): Promise<IColumn[]> {
        return this._transport.invokeAsync<undefined, IColumn[]>(Methods.Get, `/api/v1/boards/${boardId}/columns`);
    }

    public createColumnAsync(boardId: number): Promise<IMutation[]> {
        return this._transport.invokeAsync<{ title: string }, IMutation[]>(
            Methods.Post,
            `/api/v1/boards/${boardId}/columns`,
        );
    }
}

const service = new BoardService(Transport);

Object.freeze(service);

export default service;
