<template>
    <component :is="component" :project="project" :board="board"></component>
</template>

<script lang="ts">
// Components
import ProjectBoardPageGoals from './ProjectBoardPageGoals.vue';
import ProjectBoardPageTasks from './ProjectBoardPageTasks.vue';

// Other
import store from '@/store';
import IBoard from '@/core/Models/IBoard';
import IProject from '@/core/Models/IProject';
import ProjectBoardType from '@/core/Values/ProjectBoardType';
import { markRaw } from 'vue';
import { Raw } from 'vue';
import { defineComponent } from 'vue';
import { useRoute, useRouter } from 'vue-router';

export default defineComponent({
    components: {
        ProjectBoardPageGoals,
        ProjectBoardPageTasks,
    },
    setup() {
        const route = useRoute();
        const router = useRouter();

        return {
            route,
            router,
        };
    },
    data: () => ({
        component: null as Raw<unknown> | null,
    }),
    computed: {
        board(): IBoard | undefined {
            const boardId = parseInt(this.route.params.boardId as string, 10);
            return store.state.boards?.find((board) => board.id == boardId);
        },

        project(): IProject | undefined {
            const projectId = parseInt(this.route.params.projectId as string, 10);
            return store.state.projects?.find((project) => project.id == projectId);
        },
    },
    methods: {
        onBoardChanged(): void {
            switch (this.board?.type) {
                case ProjectBoardType.Tasks:
                    this.component = markRaw(ProjectBoardPageTasks);
                    break;
                case ProjectBoardType.Goals:
                    this.component = markRaw(ProjectBoardPageGoals);
                    break;
                default:
                    this.component = markRaw(ProjectBoardPageTasks);
            }
        },
    },
    watch: {
        '$route.params.boardId': {
            handler: 'onBoardChanged',
        },
    },
    created(): void {
        this.onBoardChanged();
    },
});
</script>
