<template>
    <div class="task-panel-layer" v-if="visible">
        <div class="task-panel-layer__navigation" v-if="header">
            <VButton class="task-panel-layer__button task-panel-layer__button--back" @click="close">
                <ArrowLeftSvg></ArrowLeftSvg>

                <span>{{ t('go-back') }}</span>
            </VButton>

            <VButton
                class="button--transparent task-panel-layer__button task-panel-layer__button--close"
                @click="close"
            >
                <TimesSvg></TimesSvg>
            </VButton>
        </div>

        <div class="task-panel-layer__content">
            <slot :visible="visible" :open="open" :close="close"></slot>
        </div>
    </div>
</template>

<script setup lang="ts">
// Svg
import TimesSvg from '@/assets/times.svg';
import ArrowLeftSvg from '@/assets/arrow-left.svg';

// Components
import VButton from './VButton.vue';

// Other
import { defineEmits, defineProps } from 'vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();
const emit = defineEmits(['update:visible']);
defineProps({
    header: { type: Boolean, default: false },
    visible: { type: Boolean, default: false },
});

function open() {
    emit('update:visible', true);
}

function close() {
    emit('update:visible', false);
}
</script>

<style lang="scss">
.task-panel {
    position: relative;
}

.task-panel-layer {
    display: flex;
    flex-direction: column;

    & {
        position: absolute;
        width: 100%;
        height: 100%; // DON'T TOUCH
        background-color: var(--background-color);
        --background-color: inherit;
        z-index: 1;
    }

    &__content {
        width: 100%;
        height: 100%; // DON'T TOUCH
        min-height: 0; // DON'T TOUCH
    }

    &__content {
        display: flex;
        margin: 0.5rem 0 0 0;
        padding: 0 2rem 0 2rem;
    }

    &__navigation {
        display: flex;
        justify-content: space-between;
        margin: 1.5rem 0 1rem 0;
        padding: 0 2rem 0 2rem;
    }

    &__button {
        line-height: 1.5rem;

        &--back {
            @include h6();
            line-height: 1.5rem;
            color: var(--brand-green);

            & svg {
                margin-right: 0.5rem;
            }
        }

        &--close {
            padding: 0.5rem;
            margin: -0.5rem;

            color: var(--text-black-primary);
        }

        & svg {
            width: 1.5rem;
            height: 1.5rem;
        }
    }
}
</style>
