<template>
    <div class="approval-list">
        <ul class="approval-list__list">
            <template v-for="(approvement, index) in modelValue" :key="index">
                <li class="approval-list__item">
                    <div class="approval-cart">
                        <div class="approval-cart__row approval-cart__row--content">
                            <VUserCard class="approval-cart__approver" :user="approvement.approver"></VUserCard>

                            <div
                                class="approval-cart__actions"
                                v-if="!disabled && canApprove(approvement) && isWaiting(approvement)"
                            >
                                <VButton class="button--secondary button--green" @click="approve(approvement)">
                                    {{ t('approve') }}
                                </VButton>

                                <VButton class="button--secondary button--negative" @click="disapprove(approvement)">
                                    {{ t('disapprove') }}
                                </VButton>
                            </div>

                            <VApprovalStatus
                                class="approval-cart__status"
                                :status="approvement.status"
                            ></VApprovalStatus>
                        </div>

                        <p class="approval-cart__row approval-cart__row--comment" v-if="approvement.comment">
                            <template v-for="(chunk, chunkIndex) in getCommentChunks(approvement)" :key="chunkIndex">
                                {{ chunk }} <br />
                            </template>
                        </p>
                    </div>
                </li>
            </template>
        </ul>
    </div>
</template>

<script setup lang="ts">
// Svg
// ...

// Components
import VButton from './VButton.vue';
import VUserCard from './VUserCard.vue';
import VApprovalStatus from './VApprovalStatus.vue';

// Other
import IApprovement from '@/core/Models/IApprovement';
import AuthorizationProvider from '@/core/Authorization/AuthorizationProvider';
import Operations from '@/core/Authorization/Operations';
import ApprovementStatus from '@/core/Values/ApprovementStatus';
import { $confirm } from '@/utils/app-utils';
import { PropType, defineProps, defineEmits } from 'vue';
import { useI18n } from 'vue-i18n';

const emit = defineEmits(['approve', 'update:modelValue', 'disapprove', 'update:modelValue']);
const { t } = useI18n();

const props = defineProps({
    disabled: { type: Boolean, default: false },
    modelValue: { type: Array as PropType<IApprovement[]>, required: true, default: () => [] },
});

function getCommentChunks(approvement: IApprovement): string[] | undefined {
    return approvement.comment?.split('\n');
}

function approve(approvement: IApprovement) {
    $confirm({
        title: t('confirm-task-or-goal-approve'),
        message: '',
        withComment: true,
        commentHint: t('confirm-task-or-goal-approve-hint'),
        commentRequired: false,
        commentMaxLength: 512,
        buttonOkName: t('approve'),
        buttonOkClasses: 'button--green',
        okCallback: (comment) => {
            approvement.status = ApprovementStatus.Approved;
            emit('approve', { approvement, comment });
            emit('update:modelValue', props.modelValue);
        },
    });
}

function disapprove(approvement: IApprovement) {
    $confirm({
        title: t('confirm-task-or-goal-disapprove'),
        message: '',
        withComment: true,
        commentRequired: true,
        commentMaxLength: 512,
        buttonOkName: t('disapprove'),
        okCallback: (comment) => {
            approvement.status = ApprovementStatus.Disapproved;
            emit('disapprove', { approvement, comment });
            emit('update:modelValue', props.modelValue);
        },
    });
}

function isWaiting(approvement: IApprovement): boolean {
    return approvement.status === ApprovementStatus.Waiting;
}

function canApprove(approvement: IApprovement): boolean {
    return AuthorizationProvider.authorize(approvement, Operations.ApproveTask);
}
</script>

<style lang="scss">
.approval-list {
    &__list {
        margin: 0;
    }

    &__item {
        margin-bottom: 0.875rem;

        &:last-child {
            margin-bottom: 0;
        }
    }
}

.approval-cart {
    padding: 0.75rem 1rem;

    border-radius: 1rem;
    background: var(--background-color);
    --background-color: var(--background-secondary);

    &__row {
        &--content {
            display: flex;
            flex-wrap: wrap;
            gap: 1.5rem;
            width: 100%;
            align-items: center;
        }

        &--comment {
            margin: 0.25rem 0 0 2.5rem;

            @include caption-secondary(false);
            color: var(--text-black-primary);
        }
    }

    &__approver {
        flex-grow: 1;
        margin-right: auto;
    }

    &__actions {
        display: flex;
        gap: 0.25rem;
        flex-shrink: 0;
    }

    &__status {
        flex-shrink: 0;
        flex-basis: 5.625rem;
        @include button-secondary();
        line-height: 2rem;
        text-align: right;
    }
}
</style>
