import IApprovement from '../Models/IApprovement';
import IStory from '../Models/IStory';
import ITask from '../Models/ITask';
import IAttachment from '../Values/IAttachment';
import Permissions from './Permissions';

export interface IOperation<TResource = unknown> {
    authorOnly?: boolean;
    getAuthorId?: (resource: TResource) => string | number | undefined;
    requiredPermission: string;
}

export default class Operations {
    public static ReadTask: IOperation = {
        requiredPermission: Permissions.TaskManagement.CanRead,
    };

    public static CreateTask: IOperation = {
        requiredPermission: Permissions.Tasks.CanCreate,
    };

    public static UpdateTask: IOperation = {
        requiredPermission: Permissions.Tasks.CanUpdate,
    };

    public static UpdateTaskAuthor: IOperation = {
        requiredPermission: Permissions.CanAll,
    };

    public static ArchiveTask: IOperation<ITask> = {
        authorOnly: true,
        getAuthorId: (resource: ITask) => resource?.authorId,
        requiredPermission: Permissions.Tasks.CanDelete,
    };

    public static FinishTask: IOperation<ITask> = {
        authorOnly: true,
        getAuthorId: (resource: ITask) => resource?.authorId,
        requiredPermission: Permissions.Tasks.CanUpdate,
    };

    public static ReturnTaskToInProgress: IOperation<ITask> = {
        authorOnly: true,
        getAuthorId: (resource: ITask) => resource?.authorId,
        requiredPermission: Permissions.Tasks.CanUpdate,
    };

    public static RollbackChanges: IOperation<ITask> = {
        requiredPermission: Permissions.Tasks.CanUpdate,
    };

    public static CompleteTask: IOperation = {
        requiredPermission: Permissions.Tasks.CanComplete,
    };

    public static AddCollaborator: IOperation = {
        requiredPermission: Permissions.Tasks.CanUpdate,
    };

    public static RemoveCollaborator: IOperation = {
        requiredPermission: Permissions.Tasks.CanUpdate,
    };

    public static StartTaskApprovementProcess: IOperation<ITask> = {
        authorOnly: true,
        getAuthorId: (resource: ITask) => resource?.authorId,
        requiredPermission: Permissions.Tasks.CanUpdate,
    };

    public static ApproveTask: IOperation<IApprovement> = {
        authorOnly: true,
        getAuthorId: (resource: IApprovement) => resource?.approverId,
        requiredPermission: Permissions.Tasks.CanUpdate,
    };

    public static DisapproveTask: IOperation<IApprovement> = {
        authorOnly: true,
        getAuthorId: (resource: IApprovement) => resource?.approverId,
        requiredPermission: Permissions.Tasks.CanUpdate,
    };

    public static Comment: IOperation = {
        requiredPermission: Permissions.Tasks.CanComment,
    };

    public static UpdateComment: IOperation<IStory> = {
        authorOnly: true,
        getAuthorId: (resource: IStory) => resource?.actorId,
        requiredPermission: Permissions.Tasks.CanComment,
    };

    public static DeleteStory: IOperation<IStory | IAttachment> = {
        authorOnly: true,
        getAuthorId: (resource: IStory | IAttachment) =>
            (resource as IStory & IAttachment)?.actorId || (resource as IStory & IAttachment)?.authorId,
        requiredPermission: Permissions.Tasks.CanComment,
    };

    public static ReadProject: IOperation = {
        requiredPermission: Permissions.TaskManagement.CanRead,
    };

    public static ReadBoard: IOperation = {
        requiredPermission: Permissions.TaskManagement.CanRead,
    };

    public static ReadColumn: IOperation = {
        requiredPermission: Permissions.TaskManagement.CanRead,
    };

    public static CreateColumn: IOperation = {
        requiredPermission: Permissions.Columns.CanCreate,
    };

    public static UpdateColumn: IOperation = {
        requiredPermission: Permissions.Columns.CanUpdate,
    };

    public static DeleteColumn: IOperation = {
        requiredPermission: Permissions.Columns.CanDelete,
    };

    public static ReadStatistic: IOperation = {
        requiredPermission: Permissions.TaskManagement.CanReadStatistic,
    };
}
