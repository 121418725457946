import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "v-table__data-set" }
const _hoisted_2 = { class: "v-table__data-set-title" }
const _hoisted_3 = { class: "v-table__data" }




export default /*@__PURE__*/_defineComponent({
  __name: 'VTableDataSet',
  props: {
    title: {
        type: String,
        default: '',
    },
},
  setup(__props) {

// Svg
// ...

// Components

// Other
const props = __props;

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h3", _hoisted_2, _toDisplayString(props.title), 1),
    _createElementVNode("div", _hoisted_3, [
      _renderSlot(_ctx.$slots, "default")
    ])
  ]))
}
}

})