<template>
    <span class="text-diff">
        <button class="button button--link button--link-inline button--link-green" @click="toggleDifference">
            {{ isShowDifference ? t('hide-difference') : t('show-difference') }}
        </button>

        <template v-if="isShowDifference">
            <br />
            <template v-for="(word, index) in textDiff" :key="index">
                <span
                    class="text-diff__part"
                    :class="{
                        'text-diff__part--added': word.added,
                        'text-diff__part--removed': word.removed,
                    }"
                >
                    {{ word.value }}
                </span>
            </template>
        </template>
    </span>
</template>

<script setup lang="ts">
// Svg
// ...

// Components
// ...

// Other
import TextDiffService from '@/core/Services/TextDiffService';
import { $error } from '@/utils/app-utils';
import { ref, defineProps } from 'vue';
import { useI18n } from 'vue-i18n';
import { diffWords, Change } from 'diff';

const { t } = useI18n();
const props = defineProps({
    textDiffId: { type: Number, required: true },
});

let textDiff = ref(null as Change[] | null);
let isShowDifference = ref(false);

async function toggleDifference() {
    isShowDifference.value = !isShowDifference.value;

    if (textDiff.value) {
        return;
    }

    try {
        const response = await TextDiffService.find(props.textDiffId);
        textDiff.value = diffWords(response.left, response.right);
    } catch (error) {
        $error(error);
    }
}
</script>

<style lang="scss">
:root {
    --text-diff-added-color: #c7f0d2;
    --text-diff-removed-color: #fac5cd;
}

[theme='dark'] {
    --text-diff-added-color: #1b5e20; /* Очень темный зеленый для добавленных элементов */
    --text-diff-removed-color: #642727; /* Очень темный розовый для удаленных элементов */
}

.text-diff {
    &__part {
        white-space: pre-line;

        &--added {
            background-color: var(--text-diff-added-color);
        }

        &--removed {
            text-decoration: line-through;
            background-color: var(--text-diff-removed-color);
        }
    }
}
</style>
