enum ObjectType {
    Notification = 'Notification',
    Approvement = 'Approvement',
    Board = 'Board',
    Collaborator = 'Collaborator',
    Column = 'Column',
    Project = 'Project',
    Story = 'Story',
    Task = 'TaskModel',
    TaskSavePoint = 'TaskModelSavePoint',
}

export default ObjectType;
