import IUser from '../Models/IUser';
import { IOperation } from './Operations';
import Permissions from './Permissions';

interface IAuthorizationService {
    authorize<TResource = unknown>(
        user: IUser,
        permissions: string[],
        resource: TResource,
        operation: IOperation<TResource>,
    ): boolean;
}

class FallBackAuthorizationService implements IAuthorizationService {
    authorize() {
        return false;
    }
}

class AuthorizationService implements IAuthorizationService {
    public authorize(user: IUser, permissions: string[], resource: unknown, operation: IOperation): boolean {
        if (permissions.includes(Permissions.CanAll)) {
            return true;
        }

        if (resource && operation.authorOnly && operation.getAuthorId) {
            const userId = user.id;
            const authorId = operation.getAuthorId(resource);

            if (!userId || !authorId || userId !== authorId) {
                return false;
            }
        }

        return permissions.includes(operation.requiredPermission);
    }
}

interface IAuthorizationProvider {
    authorize<TResource = unknown>(resource: TResource, operation: IOperation<TResource>): boolean;
}

class AuthorizationProvider implements IAuthorizationProvider {
    public user: IUser;
    public permissions: string[];
    public service: IAuthorizationService;

    constructor() {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        this.user = null!;
        this.permissions = [];
        this.service = new FallBackAuthorizationService();
    }

    authorize(resource: unknown, operation: IOperation) {
        return this.service.authorize(this.user, this.permissions, resource, operation);
    }
}

const instance: IAuthorizationProvider = new AuthorizationProvider();

export const setupAuthorizationProvider = (user: IUser, permissions: string[]) => {
    (instance as AuthorizationProvider).user = user;
    (instance as AuthorizationProvider).permissions = permissions;
    (instance as AuthorizationProvider).service = new AuthorizationService();
};
export default instance;
