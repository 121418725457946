<template>
    <BaseSelect v-bind="$attrs" autocomplete="off">
        <template #open-indicator="{ attributes }">
            <BottomArrowSvg v-bind="attributes">^</BottomArrowSvg>
        </template>

        <template v-for="(_, slot) of $slots" v-slot:[slot]="scope">
            <slot :name="slot" v-bind="scope"></slot>
        </template>
    </BaseSelect>
</template>

<script lang="ts">
// Svg
import BottomArrowSvg from '@/assets/bottom-arrow.svg';

// Components
import BaseSelect from 'vue-select';

// Other
import { defineComponent } from 'vue';

export default defineComponent({
    components: {
        BottomArrowSvg,

        BaseSelect,
    },
});
</script>
