<template>
    <svg version="1.1" id="santa" viewBox="0 0 300 300" class="shizzle-animated Santa_Climbing_Chimney" v-if="show">
        <g id="Santa_Claus">
            <g id="head">
                <path
                    id="body"
                    class="santa-0"
                    d="M42.4,74.5c0,0,12.1,10.3,15,14.9c13.8,21.9,48.9,73,48.9,73s7,83.1-56.5,103.4l-47.3-5.6V70.5
           L42.4,74.5z"
                />
                <path
                    id="right_ear"
                    class="santa-1"
                    d="M132.2,130c-2.1,3.2-6.4,4-9.6,1.9l-0.4-0.2c-3.2-2.1-4-6.4-1.9-9.6l0,0
           c2.1-3.2,6.4-4,9.6-1.9l0.4,0.2C133.5,122.6,134.3,126.9,132.2,130L132.2,130z"
                />
                <path
                    id="left_ear"
                    class="santa-1"
                    d="M64.6,66.9c-2.1,3.2-6.4,4-9.6,1.9l-0.4-0.2c-3.2-2.1-4-6.4-1.9-9.6l0,0
           c2.1-3.2,6.4-4,9.5-1.9l0.4,0.2C65.8,59.5,66.7,63.7,64.6,66.9L64.6,66.9z"
                />
                <path
                    id="face_back"
                    class="santa-1 UpDown_1"
                    d="M125.4,96.4L90,62.5c-7.9-7.6-21.8-6-27.7,0.2c0,0,0,0-3,3.6c-3.3,4-8.1,10.8-9.2,14.2
           l61.5,55.8c5.2-3.4,10.4-8.1,13.8-11.3c2.3-2.2,1.1-1,1.1-1C132.3,117.8,133.4,104,125.4,96.4z"
                />
                <g id="cheeks" class="UpDown_1">
                    <g class="left_cheek">
                        <g>
                            <ellipse
                                transform="matrix(0.6666 -0.7454 0.7454 0.6666 -44.3544 80.8524)"
                                class="cheeks"
                                cx="68.2"
                                cy="90"
                                rx="7.1"
                                ry="9.6"
                            />
                        </g>
                    </g>
                    <g class="right_cheek">
                        <g>
                            <path
                                class="cheeks"
                                d="M108.3,128c-2.6,2.9-7.8,2.5-11.7-0.9c-3.8-3.4-4.8-8.6-2.2-11.5c2.6-2.9,7.8-2.5,11.7,0.9
                       S111,125,108.3,128z"
                            />
                        </g>
                    </g>
                </g>
                <path
                    id="beard"
                    class="santa-4 UpDown_1"
                    d="M117.9,131c-7.2,3.7-11.6-2.4-11.6-2.4c-6-5.8-10.2-6.7-17.2-4.4c1.8-6.3,0.4-13.4-4.1-19l0.1-0.1
           c-0.4-0.3-0.7-0.7-1.1-1c-0.3-0.4-0.7-0.7-1-1l-0.1,0.1c-5.8-4.2-13-5.3-19.2-3.2c2-7.1,0.9-11.2-5.2-17c0,0-6.3-4.2-3-11.5
           c0.4-0.8,8-10,8-10s-28.7,9.7-34,16.1l0,0c-0.4,0.3-0.7,0.7-1.1,1c-5.9,6.2-6.8,15.4-2.8,23.1c-2.4,1-4.6,2.5-6.5,4.4
           c-7.6,7.9-6.9,20.9,1.6,29c0.5,0.5,1,0.9,1.5,1.3c-0.7,8.5,2.5,15.4,9.6,22.5l0,0c0.2,0.2,0.3,0.3,0.5,0.5c0.1,0.1,0.2,0.2,0.3,0.3
           l0,0c7.4,6.8,13.4,8.6,21.8,7.6c0.4,0.5,0.9,1,1.4,1.5c8.5,8.1,21.5,8.2,29.1,0.3c1.9-2,3.3-4.3,4.1-6.7c7.8,3.6,17,2.3,22.9-3.9
           c0.3-0.4,0.7-0.7,1-1.1l0,0c6.2-5.6,14.5-34.7,14.5-34.7S118.7,130.6,117.9,131z"
                />
                <g id="mustache" class="UpDown_1">
                    <path
                        class="santa-6"
                        d="M113.5,149.5c-2.6-0.9-5.5-5.2-9.6-10.2c-4-5-0.4-13.2-6.9-22.6c-5.3-7.6-13.7-7.8-16.7-7.6
               c0-3.9-1-10.4-7.1-14.9c-9.2-6.8-17.5-3.4-22.4-7.6s-9.1-7.2-9.9-9.9S38.8,88.3,44.7,99c3.3,6,16.7,22.2,29.4,17.4
               c-3.8,11.1,8.4,22.6,14.1,25.6C95.1,145.7,116.1,150.4,113.5,149.5z"
                    />
                    <path
                        class="santa-7"
                        d="M113.5,149.5c-1.1-0.4-2.1-1.2-2.9-2.1c-0.9-0.8-1.6-1.8-2.4-2.7l-4.5-5.6c-0.8-0.9-1.3-2.1-1.6-3.3
               c-0.4-1.2-0.5-2.4-0.7-3.6c-0.3-2.4-0.6-4.7-1.1-7c-0.1-0.6-0.3-1.1-0.5-1.7l-0.6-1.6l-0.7-1.6l-0.8-1.5c-0.3-0.5-0.6-1-0.9-1.5
               l-0.2-0.4l-0.3-0.3l-0.6-0.7c-0.4-0.5-0.8-0.8-1.1-1.3c-0.4-0.4-0.8-0.8-1.3-1.1c-0.9-0.7-1.8-1.3-2.9-1.8c-1-0.5-2.1-0.9-3.2-1.2
               c-2.2-0.6-4.5-0.9-6.8-0.7h-0.8V109c0-2.3-0.3-4.7-1.1-6.9c-0.8-2.2-2-4.2-3.6-5.9c-0.4-0.4-0.8-0.8-1.3-1.2l-0.7-0.6l-0.7-0.5
               c-1-0.7-2-1.3-3-1.8c-2.1-1.1-4.4-1.8-6.8-2.2c-2.4-0.4-4.8-0.6-7.2-1.1c-1.2-0.3-2.4-0.6-3.5-1.3s-1.9-1.5-2.9-2.3
               c-1.8-1.5-3.7-3-5.5-4.7c-0.9-0.8-1.8-1.7-2.4-2.9c-0.2-0.3-0.3-0.6-0.4-0.9v-0.1v-0.1l0,0c0,0,0,0.1,0.2,0.1s0.3-0.1,0.3-0.1l0,0
               v0.1c-0.1,0.5-0.2,1.1-0.2,1.7c-0.1,2.4,0.1,4.8,0.4,7.1c0.4,2.4,0.9,4.7,1.6,6.9c0.7,2.3,1.6,4.4,2.8,6.4c1.2,2,2.7,3.9,4.2,5.7
               c1.6,1.8,3.2,3.5,5,5s3.7,2.9,5.7,4.1c2,1.1,4.2,2,6.4,2.3c1.1,0.2,2.2,0.2,3.4,0.1c1.1-0.1,2.2-0.4,3.3-0.8l1.8-0.6l-0.4,2.2
               l-0.4,1.6c-0.2,0.5-0.1,1.1-0.2,1.7s0,1.1,0,1.7c0,0.3,0,0.6,0.1,0.9l0.2,0.8c0.1,0.6,0.2,1.1,0.4,1.7c0.2,0.5,0.3,1.1,0.6,1.6
               c0.2,0.5,0.4,1.1,0.7,1.6l0.8,1.6c0.6,1,1.3,2,2,2.9c0.3,0.5,0.7,0.9,1.1,1.4s0.8,0.9,1.2,1.3c0.8,0.9,1.7,1.7,2.5,2.5
               c0.9,0.8,1.8,1.6,2.8,2.3c1,0.7,2,1.3,3,1.8c1.1,0.5,2.2,1,3.3,1.4c2.3,0.9,4.6,1.6,6.9,2.4c2.3,0.7,4.7,1.4,7,2
               c1.2,0.3,2.4,0.6,3.5,0.9l1.8,0.4l0.9,0.2L113.5,149.5l0.2-0.1l0,0l0,0v0.1l0,0C113.6,149.6,113.5,149.6,113.5,149.5L113.5,149.5z
                M113.5,149.4c0.1,0,0.1,0,0.2,0.1l0,0v0.1h-0.1h-0.1h-0.1l-0.5-0.1l-0.9-0.2l-1.8-0.4l-3.6-0.8c-2.4-0.6-4.7-1.2-7.1-1.8
               c-2.4-0.6-4.7-1.3-7-2.1c-1.2-0.4-2.3-0.8-3.5-1.3c-1.1-0.5-2.3-1.1-3.3-1.8c-1.1-0.7-2-1.4-3-2.2c-1-0.8-1.9-1.6-2.7-2.5
               c-0.4-0.4-0.9-0.9-1.3-1.4s-0.8-0.9-1.2-1.4c-0.8-1-1.5-2-2.2-3.1l-0.9-1.6c-0.3-0.6-0.5-1.1-0.8-1.7s-0.4-1.2-0.6-1.8
               c-0.2-0.6-0.4-1.2-0.5-1.9l-0.2-0.9c0-0.3,0-0.6-0.1-1c0-0.6-0.1-1.3,0-1.9s0.1-1.3,0.2-1.9l0.5-1.9l1.3,1.2
               c-1.2,0.4-2.5,0.7-3.8,0.9c-1.3,0.1-2.6,0-3.9-0.1c-1.3-0.2-2.5-0.6-3.7-1c-1.2-0.5-2.3-1-3.4-1.6c-2.2-1.2-4.2-2.7-6-4.4
               c-1.8-1.6-3.6-3.4-5.1-5.3c-1.6-1.9-3.1-3.8-4.3-6s-2.1-4.5-2.8-6.8c-0.7-2.4-1.2-4.8-1.4-7.2c-0.3-2.4-0.4-4.9-0.2-7.3
               c0.1-0.6,0.1-1.2,0.3-1.9c0-0.1,0.1-0.2,0.1-0.3c0-0.1,0.1-0.1,0.1-0.2c0-0.1,0.2-0.2,0.5-0.2c0.3,0.1,0.3,0.2,0.4,0.3
               c0,0.1,0.1,0.1,0.1,0.2c0,0.1,0,0.1,0,0.1v0.1c0.1,0.3,0.2,0.5,0.3,0.8c0.5,1,1.3,1.9,2.2,2.7c1.7,1.7,3.6,3.2,5.4,4.8
               c0.9,0.8,1.8,1.6,2.8,2.2c1,0.6,2.1,1,3.3,1.3c2.3,0.5,4.7,0.7,7.1,1.1c2.4,0.4,4.8,1.1,7,2.1c1.1,0.5,2.2,1.1,3.2,1.8l0.8,0.5
               l0.8,0.6c0.5,0.4,0.9,0.8,1.4,1.2c1.8,1.7,3.2,3.9,4.1,6.2c0.9,2.3,1.4,4.8,1.4,7.3l-0.8-0.8c2.5-0.2,5,0,7.4,0.7
               c1.2,0.3,2.4,0.8,3.5,1.3s2.1,1.3,3.1,2.1c0.5,0.4,0.9,0.8,1.4,1.3c0.4,0.5,0.9,0.9,1.2,1.4l0.5,0.7l0.3,0.4l0.2,0.4
               c0.3,0.5,0.7,1,0.9,1.6l0.8,1.7l0.7,1.7l0.5,1.8c0.1,0.6,0.3,1.2,0.4,1.8c0.2,1.2,0.4,2.4,0.5,3.6s0.2,2.4,0.3,3.6
               c0.1,1.2,0.2,2.4,0.5,3.5c0.3,1.1,0.7,2.2,1.4,3.2c0.7,0.9,1.4,1.9,2.1,2.8l2.2,2.8c0.7,0.9,1.5,1.8,2.3,2.7
               C111.5,148.2,112.4,149,113.5,149.4L113.5,149.4z"
                    />
                </g>
                <path class="santa-white" d="M68.2,85.1" />
                <g id="nose">
                    <circle class="nose" cx="84.2" cy="107.9" r="10.7" />
                </g>
                <g id="face">
                    <g id="eye_brows">
                        <g id="left_brow" class="eyesandbrow">
                            <path
                                class="santa-6"
                                d="M99,84.3c0,0,6.4-9.6-10.5-18.1c-9.7-4.9-17.8,5.2-17.8,5.2S86.7,67.4,99,84.3z"
                            />
                        </g>
                        <g id="right_brow" class="eyesandbrow">
                            <path
                                class="santa-6"
                                d="M104.4,89.4c0,0,9.3-6.9,18.6,9.6c5.3,9.5-4.3,18-4.3,18S121.8,101,104.4,89.4z"
                            />
                        </g>
                    </g>
                    <g id="eyes">
                        <g id="left_eye">
                            <path
                                id="left_eye_1_"
                                class="santa-11"
                                d="M84.6,91.5c-1,1.1-2.8,1.1-3.9,0.1l0,0c-1.1-1-1.1-2.8-0.1-3.9l5.6-5.8
                       c1-1.1,2.8-1.1,3.9-0.1l0,0c1.1,1,1.1,2.8,0.1,3.9L84.6,91.5z"
                            />
                            <circle id="left_eyeball" class="eyeball eyesandbrow" cx="88.5" cy="83.4" r="1.1" />
                        </g>
                        <g id="right_eye">
                            <path
                                id="right_eye_1_"
                                class="santa-11"
                                d="M102.3,108.4c-1,1.1-2.8,1.1-3.9,0.1l0,0c-1.1-1-1.1-2.8-0.1-3.9l5.6-5.8
                       c1-1.1,2.8-1.1,3.9-0.1l0,0c1.1,1,1.1,2.8,0.1,3.9L102.3,108.4z"
                            />
                            <circle id="right_eyeball" class="eyeball eyesandbrow" cx="106.2" cy="100.3" r="1.1" />
                        </g>
                    </g>
                </g>
                <g id="hat" class="UpDown_1">
                    <path
                        class="santa-13"
                        d="M105.3,31.6c0,0,20.8,4.7,27.5,7.6c6.7,3,17.4,10.6,22.4,18.3c7.8,12,12.9,20.6,12.5,40.5
               c-0.1,5.6-1.6,16.9-6.3,28.6l-8.9-0.1c0,0-0.8-7.3-8.4-28.4"
                    />
                    <path
                        class="santa-14"
                        d="M139.6,107.5c0,0,20.2-13.9,13.9-31.7c-6.5-18.6-37.8-49.3-57.9-44C75.5,37,75.2,50.3,75.2,50.3"
                    />
                    <g>
                        <circle class="santa-white" cx="158.5" cy="130.8" r="11.5" />
                        <g>
                            <path class="santa-white" d="M157.1,119.4c0,0-9.3,1-9.8,10.8c-0.5,11.3,11,12.1,11,12.1" />
                            <path
                                class="santa-7"
                                d="M157.1,119.4c0,0-0.5,0.2-1.4,0.6c-0.8,0.4-2,1-3.1,2s-2.2,2.3-2.9,3.9c-0.3,0.8-0.6,1.7-0.7,2.6
                       c-0.1,0.5-0.1,0.9-0.2,1.4v0.4v0.3c0,0.3,0,0.5,0,0.8c0,0.9,0.2,1.9,0.4,2.7c0.2,0.9,0.6,1.7,1,2.5c0.9,1.5,2.2,2.7,3.4,3.5
                       c1.3,0.8,2.5,1.4,3.3,1.7c0.9,0.3,1.4,0.5,1.4,0.5s-0.5,0.1-1.5,0.1s-2.4-0.2-4-0.8c-0.8-0.3-1.7-0.7-2.5-1.3
                       c-0.8-0.6-1.6-1.3-2.3-2.2c-0.7-0.9-1.2-2-1.6-3.1c-0.4-1.1-0.5-2.3-0.6-3.5c0-0.3,0-0.5,0-0.8v-0.5v-0.4c0-0.6,0.1-1.2,0.3-1.7
                       c0.2-1.1,0.6-2.2,1.2-3.2c0.5-1,1.2-1.8,2-2.5c0.7-0.7,1.6-1.2,2.3-1.6c1.5-0.8,2.9-1.1,3.9-1.1
                       C156.6,119.4,157.1,119.4,157.1,119.4z"
                            />
                        </g>
                    </g>

                    <image
                        style="overflow: visible; enable-background: new"
                        width="84"
                        height="82"
                        href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFQAAABSCAYAAADKMvPcAAAACXBIWXMAAAsSAAALEgHS3X78AAAA
   GXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAcdJREFUeNrs3UuSwjAQA1DaF8j9
   j5kTMCuqWACZJG23pJa3LDDPssvfIh7JZd/357fPtm2Lh3iJmYBHRRE4KiCVUaMKUhU2EDCVUAMF
   UwU20DDZUQMRkxk1UDFZYQMdkw01GDCZUIMFkwWVDhQdNhgxkVEvgR79kM6LgPgvwp2Kd1qyxtEP
   zqzsTFgU1J+gsyqpvGM1KirH1lC3E7qqpRWHgFFZkZnfVZXWUd2qaqgDYUBXQoWaFCuMqXBLN3ZU
   yA0GZlTorTDGBQD8hi0bKsWxAhMqzeEXCyrVMS0DKt25Nzoq5e0MZFTaO0SoqNS33RBR6a8PoqFK
   XHJFQh0KoHCnniplRlLPNpbcK4xqVMl3Q5Wosg+xqlClX7ZVoMo/FVyNOtRBV0+p5BM6K6nfGqoN
   6CrUVqArUNuBzoB9Rx1dQVufejIk9dVA7UGzUYc5c7u/QZNR3eWTu78TmpxUgyYXgyan1KDJqAZ1
   l8dOqUGdUOyUGtQJNahBXa6PtwZ1Qg3aanpl0INydn/UoMmTf4Mmr6R8BHKju39aljqhyWt8gyZi
   ustf6O5Hu09OaFIyndAT6Wz/rKYK06A/MDv89xPcMvNT+RNgAHRQ+6wwubXuAAAAAElFTkSuQmCC"
                        transform="matrix(1 0 0 1 58 45)"
                    ></image>
                </g>
                <g class="UpDown_1">
                    <path
                        id="mouth"
                        d="M72.4,118.4c1.8,1.4,2.6,3.7,1.7,4.9l0,0c-0.9,1.2-3.4,1.1-5.2-0.2l0,0c-1.8-1.4-2.6-3.7-1.7-4.9l0,0
           C68.1,116.9,70.4,116.9,72.4,118.4L72.4,118.4z"
                    />
                    <path
                        id="mouth_2"
                        d="M71.5,119.1c1.1,0.8,1.7,2.2,1.2,2.8l0,0c-0.5,0.7-1.9,0.5-3-0.3l0,0c-1.1-0.8-1.7-2.2-1.2-2.8l0,0
           C69,118.1,70.3,118.1,71.5,119.1L71.5,119.1z"
                    />
                </g>
                <g id="hand_top">
                    <path
                        class="santa-16"
                        d="M9.9,64.9L9.9,64.9c11.9,0,21.5,6.2,21.5,13.9v9.7c0,7.7-9.6,13.9-21.5,13.9l0,0"
                    />
                    <g>
                        <path
                            class="santa-17"
                            d="M13.7,91.4L13.7,91.4c4,0,7.2,1.9,7.2,4.2V106c0,2.3-3.2,4.2-7.2,4.2l0,0"
                        />
                        <g>
                            <g class="santa-18">
                                <path
                                    d="M26.8,91.1c0,8.4-9.6,15.1-21.5,15.1l0,0c-11.9,0-21.5-6.8-21.5-15.1V80.5c0-8.4,9.6-15.1,21.5-15.1l0,0
                           c11.9,0,21.5,6.8,21.5,15.1V91.1z"
                                />
                            </g>
                            <g>
                                <path
                                    class="santa-13"
                                    d="M29.8,88.1c0,8.4-9.6,15.1-21.5,15.1l0,0c-11.9,0-21.5-6.8-21.5-15.1V77.5c0-8.4,9.6-15.1,21.5-15.1l0,0
                           c11.9,0,21.5,6.8,21.5,15.1V88.1z"
                                />
                            </g>
                        </g>
                    </g>
                </g>
                <g id="hand_bottom">
                    <path
                        class="santa-19"
                        d="M10.2,212.9L10.2,212.9c4,0,7.2,1.9,7.2,4.2v10.4c0,2.3-3.2,4.2-7.2,4.2l0,0"
                    />
                    <g>
                        <path
                            class="santa-20"
                            d="M23.2,226.7L23.2,226.7c12,0,21.6,6.7,21.6,14.9V252c0,8.2-9.7,14.9-21.6,14.9l0,0"
                        />
                        <g>
                            <g class="santa-18">
                                <path
                                    d="M35.5,254.6c0,9-9.7,16.3-21.6,16.3l0,0c-12,0-21.6-7.3-21.6-16.3v-11.4c0-9,9.7-16.3,21.6-16.3l0,0
                           c12,0,21.6,7.3,21.6,16.3V254.6z"
                                />
                            </g>
                            <g>
                                <path
                                    class="santa-13"
                                    d="M38.5,251.6c0,9-9.7,16.3-21.6,16.3l0,0c-12,0-21.6-7.3-21.6-16.3v-11.4c0-9,9.7-16.3,21.6-16.3l0,0
                           c12,0,21.6,7.3,21.6,16.3V251.6z"
                                />
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
</template>

<script setup lang="ts">
import { ref } from 'vue';

// Svg
// ...

// Components
// ...

// Other

let show = ref(false);
setTimeout(() => (show.value = true), random(15 * 1000, 900 * 1000));

function random(min: number, max: number) {
    var delta = max - min;
    return max === min ? min : Math.random() * delta + min;
}
</script>

<style lang="scss">
#santa {
    position: absolute;
    width: 8rem;
    top: 3%;
    right: -7.825rem;
    z-index: 1001;
    pointer-events: none;
}

#mouth {
    animation: HoHoHoMouth 6s infinite 6s;
    -webkit-animation: HoHoHoMouth 6s infinite 6s;
    opacity: 0;
}

@-webkit-keyframes HoHoHoMouth {
    0% {
        opacity: 0;
    }
    30% {
        opacity: 1;
    }
    40% {
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
    60% {
        opacity: 0;
    }
    70% {
        opacity: 1;
    }
    80% {
        opacity: 0;
    }
}

@keyframes HoHoHoMouth {
    0% {
        opacity: 0;
    }
    30% {
        opacity: 1;
    }
    40% {
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
    60% {
        opacity: 0;
    }
    70% {
        opacity: 1;
    }
    80% {
        opacity: 0;
    }
}

#Santa_Container {
    position: absolute;
    margin-right: -35%;
}
#Santa_Slide_Panel {
    position: absolute;
    width: 80%;
    height: 100%;
    overflow: hidden;
    margin-left: -55%;
    -webkit-transition: all 3s ease;
    transition: all 1000ms ease;
    cursor: pointer;
}
#Santa_Slide_Panel:hover {
    margin-left: 0%;
}
#Santa_Greeting {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 28.75%;
    z-index: 100;
    /* Brick Tiled Background */
    background-color: silver;
    background-image: linear-gradient(335deg, #b00 23px, transparent 23px),
        linear-gradient(155deg, #d00 23px, transparent 23px), linear-gradient(335deg, #b00 23px, transparent 23px),
        linear-gradient(155deg, #d00 23px, transparent 23px);
    background-size: 58px 58px;
    background-position: 0px 2px, 4px 35px, 29px 31px, 34px 6px;
}
#Santa_Message {
    position: absolute;
    overflow-y: scroll; /* has to be scroll, not auto */
    -webkit-overflow-scrolling: touch; /* Accelerates Overflow Scrolling on IOS */

    padding: 15px;
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    height: 60%;
    width: 60%;

    box-shadow: 0 0 0 15px #587a55, 0 0 0 17px #587a55, 0 0 0 19px ghostwhite, 0 0 0 26px #1e340e, 0 0 0 40px #1e340e;
    background: ghostwhite;
}

#Santa_Message p {
    font-family: 'Linden Hill', serif;
    font-size: 110%;
    color: #587a55;
}
/* ANIMATIONS */

.UpDown_1 {
    animation: bottom_animation 6s infinite;
    -webkit-animation: bottom_animation 6s infinite;
    -webkit-animation-delay: 1s;
    animation-delay: 1s;
}
.eyesandbrow {
    animation: bottom_animation 6s infinite;
    -webkit-animation: bottom_animation 6s infinite;
    -webkit-animation-delay: 2s;
    animation-delay: 2s;
}
.shizzle-animated {
    visibility: visible !important;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-backface-visibility: visible !important;
    backface-visibility: visible !important;
}
.Santa_Climbing_Chimney {
    animation: Santa_Climbing_Chimney 12s;
    opacity: 0;
}
@keyframes Santa_Climbing_Chimney {
    0% {
        transform: translateY(550%);
    }

    10% {
        opacity: 1;
        transform: translateY(500%) rotate(-3deg);
    }

    20% {
        transform: translateY(400%) rotate(3deg);
    }

    30% {
        transform: translateY(300%) rotate(-3deg);
    }

    40% {
        transform: translateY(200%) rotate(3deg);
    }

    50% {
        transform: translateY(100%) rotate(-3deg);
    }

    60% {
        transform: translateY(50%) rotate(3deg);
    }

    70% {
        transform: translateY(25%) rotate(-3deg);
    }

    80% {
        transform: translateY(0%) rotate(-3deg);
    }

    90% {
        opacity: 1;
        transform: translateY(-25%) rotate(3deg);
    }

    100% {
        opacity: 0;
        transform: translateY(-100%) rotate(0deg);
    }
}

.slideScaleUpIn {
    -webkit-animation-delay: 1s;
    animation-duration: 1s;
    -webkit-animation-duration: 3s;
    -webkit-animation-delay: 3s;
    animation-duration: 3s;
    -webkit-animation-name: slideScaleUpIn;
    animation-name: slideScaleUpIn;
}

@-webkit-keyframes slideScaleUpIn {
    0% {
        opacity: 0;
        -webkit-transform: translateY(-2000px) scale(0.1);
    }
    80% {
        opacity: 1;
        -webkit-transform: scale(0.8);
    }
}
@keyframes slideScaleUpIn {
    0% {
        opacity: 0;
        transform: translateY(-2000px) scale(0.1);
    }
    80% {
        opacity: 1;
        transform: scale(0.8);
    }
}

@keyframes bottom_animation {
    0% {
        transform: rotate(0deg);
    }
    25% {
        transform: rotate(2deg);
    }
    50% {
        transform: rotate(0deg);
    }
    75% {
        transform: rotate(2deg);
    }
    100% {
        transform: rotate(0deg);
    }
}

@-webkit-keyframes bottom_animation {
    0% {
        transform: rotate(0deg);
    }
    25% {
        transform: rotate(2deg);
    }
    50% {
        transform: rotate(0deg);
    }
    75% {
        transform: rotate(2deg);
    }
    100% {
        transform: rotate(0deg);
    }
}

/* SVG DEFAULT STYLING FROM ILLUSTRATOR */
.santa-0 {
    fill: #c10e0e;
}
.santa-1 {
    fill: #f4c7b3;
}
.santa-2 {
    opacity: 0.2;
}
.cheeks {
    fill: #eab5a0;
}
.santa-4 {
    fill: #f2f2f2;
    stroke: #e6e6e6;
    stroke-width: 1.8489;
    stroke-miterlimit: 10;
}
.santa-5 {
    fill: none;
}
.santa-6 {
    fill: #f2f2f2;
}
.santa-7 {
    fill: #e6e6e6;
}
.santa-white {
    fill: #ffffff;
}
.nose {
    fill: #eab5a0;
}
.santa-11 {
    fill: #5e5958;
}
.eyeball {
    fill: #f9f5f4;
}
.santa-13 {
    fill: #d60b0b;
}
.santa-14 {
    fill: #bc0202;
}
.santa-15 {
    fill: #c6c6c6;
}
.santa-16 {
    fill: none;
    stroke: #f2f2f2;
    stroke-width: 16.7815;
    stroke-linecap: round;
    stroke-miterlimit: 10;
}
.santa-17 {
    fill: #c40808;
}
.santa-18 {
    opacity: 0.15;
}
.santa-19 {
    fill: #b50808;
}
.santa-20 {
    fill: none;
    stroke: #f2f2f2;
    stroke-width: 17.3975;
    stroke-linecap: round;
    stroke-miterlimit: 10;
}
</style>
