<template>
    <VPopper
        class="user-menu prevent-close"
        z-index="10000"
        :placement="placement"
        :offset-distance="offsetDistance"
        v-bind="$attrs"
    >
        <div class="user-menu__trigger">
            <slot></slot>
        </div>

        <template #content="{ close }">
            <ul class="button__dropdown-menu user-menu__item" @click="close">
                <li class="user-menu__item user-menu__item--slot">
                    <slot></slot>
                </li>

                <li class="user-menu__item user-menu__item--separator"></li>

                <li class="user-menu__item button button__dropdown-option button__dropdown-option--link" v-show="false">
                    <RouterLink :to="{ name: 'user.settings' }">
                        <SettingsSvg></SettingsSvg>

                        {{ t('settings') }}
                    </RouterLink>
                </li>

                <li class="user-menu__item button button__dropdown-option" @click="toggleTheme">
                    <ThemeSvg :class="{ 'rotate-180': theme === 'dark' }"></ThemeSvg>

                    <template v-if="theme === 'dark'">
                        {{ t('light-theme') }}
                    </template>

                    <template v-else>
                        {{ t('dark-theme') }}
                    </template>
                </li>

                <li
                    class="user-menu__item button button__dropdown-option"
                    @click="toggleWinterMode"
                    v-if="isNowWinter || winterMode"
                >
                    <template v-if="winterMode">
                        <WinterOffSvg></WinterOffSvg>
                        {{ t('disable-winter-mode') }}
                    </template>

                    <template v-else>
                        <WinterOnSvg></WinterOnSvg>
                        {{ t('enable-winter-mode') }}
                    </template>
                </li>

                <li class="user-menu__item button button__dropdown-option" @click="signOut">
                    <SignOutSvg></SignOutSvg>
                    {{ t('sign-out') }}
                </li>
            </ul>
        </template>
    </VPopper>
</template>

<script lang="ts">
// Svg
import ThemeSvg from '@/assets/theme.svg';
import SignOutSvg from '@/assets/sign-out.svg';
import SettingsSvg from '@/assets/settings.svg';
import WinterOnSvg from '@/assets/winter-on.svg';
import WinterOffSvg from '@/assets/winter-off.svg';

// Components
import VPopper from 'vue3-popper';

// Other
import oidc from '../vue-oidc-client';
import { defineComponent } from 'vue';
import { PropType } from 'vue';
import Storages from '@/core/Storages';
import Settings from '@/core/Settings';
import { useI18n } from 'vue-i18n';
import { isWinter } from '@/utils/utils';

type PlacementType =
    | 'auto'
    | 'auto-start'
    | 'auto-end'
    | 'top'
    | 'top-start'
    | 'top-end'
    | 'bottom'
    | 'bottom-start'
    | 'bottom-end'
    | 'right'
    | 'right-start'
    | 'right-end'
    | 'left'
    | 'left-start'
    | 'left-end';

type Theme = 'light' | 'dark';

export default defineComponent({
    components: {
        ThemeSvg,
        SignOutSvg,
        SettingsSvg,

        WinterOnSvg,
        WinterOffSvg,

        VPopper,
    },

    setup() {
        const { t } = useI18n();
        return { t };
    },

    data: () => ({
        isNowWinter: isWinter(),
    }),

    props: {
        placement: { type: String as PropType<PlacementType>, default: 'bottom-end' },
        offsetDistance: { type: String, default: '-36' },
    },

    computed: {
        theme: {
            get(): Theme {
                return Storages.Settings.getOrDefault(Settings.UI.Theme, 'light') as Theme;
            },
            set(value: Theme): void {
                Storages.Settings.set(Settings.UI.Theme, value);
                document.body.setAttribute('theme', value);
                document.querySelector('meta[name=color-scheme]')?.setAttribute('content', value);
            },
        },
        winterMode: Storages.Settings.computed<boolean>(Settings.UI.WinterEnabled, isWinter()),
    },

    methods: {
        signOut(): void {
            oidc.signOut();
        },

        toggleTheme(): void {
            this.theme = this.theme === 'dark' ? 'light' : 'dark';
        },

        toggleWinterMode(): void {
            this.winterMode = !this.winterMode;
        },
    },
});
</script>

<style lang="scss">
.user-menu {
    margin: 0.3125rem 0;

    &__trigger {
        display: flex;
        justify-content: flex-end;
        padding: 0 0.25rem;
        cursor: pointer;
    }

    &__item {
        &--separator {
            width: 100%;
            height: 0.0625rem;
            margin: 0.75rem 0;

            background-color: var(--background-color);
            --background-color: var(--background-tertiary);

            &--link {
                @include link();
            }
        }

        &--slot {
            padding: 0 1rem;
        }

        &--link {
            padding: 0.25rem 1rem;
            color: var(--text-black-primary);
        }
    }
}
</style>
