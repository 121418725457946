import Http from '@/core/Http';
import { AxiosRequestConfig } from 'axios';

export function groupedMap<TValue, TKey>(array: TValue[], selector: (item: TValue) => TKey): Map<TKey, TValue[]> {
    const map = new Map<TKey, TValue[]>();

    for (const item of array) {
        const key = selector(item);
        let list = map.get(key);
        if (list === undefined) {
            list = [];
            map.set(key, list);
        }
        list.push(item);
    }

    return map;
}

export function fileExtension(name: string): string {
    const dotIndex = name.lastIndexOf('.');
    return dotIndex === -1 ? name : name.substring(dotIndex, name.length);
}

export function findLast<TItem>(array: TItem[], predicate: (item: TItem) => boolean): TItem | undefined {
    for (let i = array.length - 1; i >= 0; i--) {
        if (predicate(array[i])) {
            return array[i];
        }
    }
}

export function fallbackCopyTextToClipboard(text: string) {
    const textArea = document.createElement('textarea');
    textArea.value = text;

    // Avoid scrolling to bottom
    textArea.style.top = '0';
    textArea.style.left = '0';
    textArea.style.position = 'fixed';

    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    document.execCommand('copy');

    document.body.removeChild(textArea);
}

export function copyTextToClipboard(text: string): void {
    if (!navigator.clipboard) {
        fallbackCopyTextToClipboard(text);
        return;
    }

    navigator.clipboard.writeText(text);
}

export function isEmptyArray(arr: unknown): boolean {
    return Array.isArray(arr) && arr.length === 0;
}

export function isNullOrUndefined(value: unknown): value is undefined | null {
    return value === null || value === undefined;
}

export function required(value: unknown): boolean {
    if (isNullOrUndefined(value) || isEmptyArray(value) || value === false) {
        return false;
    }

    return !!String(value).trim().length;
}

export function isWinter() {
    const date = new Date();
    const day = date.getDate();
    const month = date.getMonth();

    return (month >= 11 && day >= 0) || (month <= 0 && day <= 14);
}

export function downloadFile<TPayload = unknown>(url: string, payload: TPayload) {
    const request: AxiosRequestConfig<TPayload> = {
        url,
        method: 'GET',
        responseType: 'blob',
        params: payload,
    };

    Http.request(request).then((response) => {
        // create file link in browser's memory
        const href = URL.createObjectURL(response.data);

        const contentDisposition = response.headers['content-disposition'];
        let fileName = 'document';
        if (contentDisposition) {
            const fileNameMatch = contentDisposition.match(/filename="(.+)"/);
            if (fileNameMatch?.length === 2) {
                fileName = fileNameMatch[1];
            }
        }

        // create "a" HTML element with href to file & click
        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', fileName); //or any other extension
        document.body.appendChild(link);
        link.click();

        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
    });
}
