import { Plugin, reactive } from 'vue';

const breakpoints = {
    mobile: 0,
    tablet: 996,
    desktop: 1440,
};

const device = reactive({
    isMobile: false,
    isTablet: false,
    isDesktop: false,
});

function onResize() {
    device.isMobile = window.innerWidth >= breakpoints.mobile && window.innerWidth < breakpoints.tablet;
    device.isTablet = window.innerWidth >= breakpoints.tablet && window.innerWidth < breakpoints.desktop;
    device.isDesktop = window.innerWidth >= breakpoints.desktop;
}
onResize();

// Adds the resize listener.
window.addEventListener('resize', onResize, true);

const plugin: Plugin<unknown[]> = {
    install: (app) => {
        app.config.globalProperties.$device = device;
    },
};

declare module '@vue/runtime-core' {
    interface ComponentCustomProperties {
        $device: {
            isMobile: boolean;
            isTablet: boolean;
            isDesktop: boolean;
        };
    }
}

export default plugin;
