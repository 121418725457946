import { ActionContext, ActionTree } from 'vuex';
import store, { IState } from '.';
import ProjectService from '@/core/Services/ProjectService';
import BoardService from '@/core/Services/BoardService';
import UserService from '@/core/Services/UserService';
import { MutationNames } from './mutations';
import { setupAuthorizationProvider } from '@/core/Authorization/AuthorizationProvider';
import MutationBus from '@/core/Mutations/MutationBus';
import { StoreMutatorContext } from '@/core/Mutators/StoreMutator';
import { ErrorCodes } from '@/core/ErrorCodes';
import IUser from '@/core/Models/IUser';
import IProject from '@/core/Models/IProject';
import IBoard from '@/core/Models/IBoard';
import { markRaw } from 'vue';

export class ActionNames {
    public static readonly StartApplicationLoadingAsync = 'START_APPLICATION_LOADING_ASYNC';
    public static readonly FinishApplicationLoadingAsync = 'FINISH_APPLICATION_LOADING_ASYNC';
    public static readonly FetchRequiredUserAsync = 'FETCH_REQUIRED_USER_ASYNC';
    public static readonly FetchRequiredUsersAsync = 'FETCH_REQUIRED_USERS_ASYNC';
}

const actions: ActionTree<IState, IState> = {
    async START_APPLICATION_LOADING_ASYNC(context: ActionContext<IState, IState>) {
        context.commit(MutationNames.StartApplicationLoading);

        let permissions: string[];
        try {
            permissions = await UserService.getMyPermissionsAsync();
        } catch (error) {
            throw new Error(ErrorCodes.ServerError);
        }

        if (!permissions.length) {
            throw new Error(ErrorCodes.NotEnoughPermissions);
        }

        let user: IUser;
        let users: IUser[];
        let projects: IProject[];
        let boards: IBoard[];
        try {
            [user, users, projects, boards] = await Promise.all([
                UserService.getMyAccountAsync(),

                UserService.getAll(),
                ProjectService.getAllAsync(),
                BoardService.getAllAsync(),
            ]);

            MutationBus.activate(new StoreMutatorContext(store));
        } catch (error) {
            throw new Error(ErrorCodes.ServerError);
        }

        setupAuthorizationProvider(user, permissions);

        context.commit(MutationNames.SetUser, user);
        context.commit(MutationNames.SetUserPermissions, permissions);

        context.commit(MutationNames.SetUsers, users);
        context.commit(MutationNames.SetProjects, projects);
        context.commit(MutationNames.SetBoards, boards);
    },

    async FINISH_APPLICATION_LOADING_ASYNC(context: ActionContext<IState, IState>) {
        context.commit(MutationNames.FinishApplicationLoading);
    },

    async FETCH_REQUIRED_USER_ASYNC(context: ActionContext<IState, IState>, userId?: string) {
        if (!userId) {
            throw new Error('User id not provided');
        }

        if (!context.state.usersMap.has(userId)) {
            const user = await UserService.find(userId);
            context.state.usersMap.set(userId, user);
        }
    },

    async FETCH_REQUIRED_USERS_ASYNC(context: ActionContext<IState, IState>, userIds?: string[]) {
        if (!userIds) {
            throw new Error('User ids not provided');
        }

        const fetchingUserIds = userIds.filter((userId) => !context.state.usersMap.has(userId));

        if (fetchingUserIds && fetchingUserIds.length > 0) {
            const users = await UserService.query({
                whereId: fetchingUserIds,
            });

            for (const user of users) {
                context.state.usersMap.set(user.id, markRaw(user));
            }
        }
    },
};

export default actions;
