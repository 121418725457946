import IMutation from './IMutation';
import IMutatorContext from './IMutatorContext';

type MutationPair = { mutations: IMutation[]; eventName: string };

class MutationBus {
    private _queue: MutationPair[] = [];
    private _pending = false;
    private _contexts: IMutatorContext[] = [];

    public activate(contexts: IMutatorContext | IMutatorContext[]): void {
        if (this._contexts.length > 10) {
            console.warn('Memory leakage is possible, the number of mutation contexts exceeds the allowed maximum');
        }

        if (!Array.isArray(contexts)) {
            this._contexts.push(contexts);
            return;
        }

        for (const context of contexts) {
            this._contexts.push(context);
        }
    }

    public deactivate(contexts?: IMutatorContext | IMutatorContext[] | null): void {
        if (!contexts) {
            return;
        }

        if (!Array.isArray(contexts)) {
            contexts = [contexts];
        }

        for (const context of contexts) {
            const index = this._contexts.indexOf(context);

            if (index !== -1) {
                this._contexts.splice(index, 1);
            }
        }
    }

    public mutate(eventName: string, mutations: IMutation[]): void {
        this._queue.push({ mutations, eventName });
        this._dequeue();
    }

    private _dequeue() {
        if (this._pending) {
            return;
        }

        const pair = this._queue.shift();

        if (!pair) {
            return;
        }

        this._pending = true;
        Promise.all(
            this._contexts.map((context) => context.mutator.mutate(context, pair.mutations, pair.eventName)),
        ).then(() => {
            this._pending = false;
            this._dequeue();
        });
    }
}

const bus = new MutationBus();

export default bus;
