<template>
    <div class="chat-input" v-if="currentValue">
        <div class="chat-input__row chat-input__row--attachments">
            <VFileList
                class="file-list--nowrap"
                hide-add-button
                :uploader="uploader"
                :attachments="currentValue.attachments"
                :queue="queue"
                :uploadables="uploadables"
                @removed="removedAttachmentHandler"
                @uploaded="uploadedAttachmentHandler"
            ></VFileList>
        </div>

        <form class="chat-input__row chat-input__row--input" @submit.prevent="submit">
            <VUserCard class="chat-input__hover chat-input__hover--left" picture-only :user="currentUser"></VUserCard>

            <div class="chat-input__input">
                <VContentEditable
                    ref="textarea"
                    class="simple-contenteditable--borderless"
                    :placeholder="t('your-comment')"
                    @paste="pasted"
                    @submit="submit"
                    @keydown.up="startEdit"
                    @keydown.esc="stopEdit"
                    v-model="currentValue.text"
                ></VContentEditable>
            </div>

            <VButton class="button--circle button--green chat-input__hover chat-input__hover--right">
                <EditSvg v-if="isEdit"></EditSvg>

                <SendSvg v-else></SendSvg>
            </VButton>
        </form>
    </div>
</template>

<script setup lang="ts">
// Svg
import SendSvg from '@/assets/send.svg';
import EditSvg from '@/assets/edit.svg';

// Components
import VButton from './VButton.vue';
import VUserCard from './VUserCard.vue';
import VContentEditable from './VContentEditable.vue';

// Other
import IChatComment from '@/core/Values/IChatComment';
import store from '@/store';
import VFileList from './VFileList.vue';
import UploadableFile from '@/core/Uploader/UploadableFile';
import IApiResult from '@/core/IApiResult';
import IObjectStoreModel from '@/core/Values/IObjectStoreModel';
import IUploader from '@/core/Uploader/IUploader';
import IAttachment from '@/core/Values/IAttachment';
import { computed, markRaw, PropType, ref, watch, defineEmits, defineProps } from 'vue';
import UploadableFileStatus from '@/core/Uploader/UploadableFileStatus';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();
const emit = defineEmits(['update:editModel', 'update:newModel', 'stop-edit', 'start-edit', 'update', 'submit']);

const props = defineProps({
    newModel: { type: Object as PropType<IChatComment>, required: true },
    editModel: { type: Object as PropType<IChatComment | null>, default: () => null },
    uploader: { type: Object as PropType<IUploader<IApiResult<IObjectStoreModel>>>, required: true },
});

const uploadables = ref([] as UploadableFile<IApiResult<IObjectStoreModel>>[]);
const queue = ref([] as File[]);
const currentUser = store.state.user;
const textarea = ref(null as unknown as { focus: () => void });

let isEdit = computed((): boolean => !!props.editModel?.id && props.editModel.id !== -1);

const currentValue = computed({
    get: () => (isEdit.value ? props.editModel : props.newModel),
    set: (value) => emit(props.editModel ? 'update:editModel' : 'update:newModel', value),
});

function submit() {
    const eventName = isEdit.value ? 'update' : 'submit';

    if (
        uploadables.value.some(
            (uploadable) =>
                uploadable.status === UploadableFileStatus.UPLOADING ||
                uploadable.status === UploadableFileStatus.WAITING,
        )
    ) {
        return;
    }

    emit(eventName, currentValue.value);

    if (!isEdit.value) {
        currentValue.value = {
            id: -1,
            text: '',
            attachments: [],
        };
    }
}

watch(
    () => props.editModel,
    () => {
        if (!props.editModel) {
            return;
        }

        focus();
    },
);

function focus() {
    textarea.value?.focus();
}

function stopEdit() {
    emit('stop-edit');
}

function startEdit() {
    if (currentValue.value?.text || currentValue.value?.attachments.length) {
        return;
    }

    emit('start-edit');
}

function pasted(event: ClipboardEvent & { originalEvent: ClipboardEvent }) {
    event.preventDefault();

    if (isEdit.value) {
        return;
    }

    const items = (event.clipboardData || event.originalEvent.clipboardData)?.items;

    if (!items) {
        return;
    }

    for (let index = 0; index < items.length; index++) {
        const item = items[index];

        if (item.kind !== 'file') {
            continue;
        }

        const file = item.getAsFile();

        if (!file) {
            continue;
        }

        queue.value.push(markRaw(file));
    }
}

function removedAttachmentHandler(attachment: IAttachment) {
    var index = currentValue.value?.attachments.indexOf(attachment) ?? -1;

    if (index === -1) {
        return;
    }

    currentValue.value?.attachments.splice(index, 1);
}

function uploadedAttachmentHandler(uploadable: UploadableFile<IApiResult<IObjectStoreModel>>) {
    if (!uploadable.model) {
        return;
    }

    currentValue.value?.attachments.push({
        id: -1,
        authorId: currentUser?.id,
        fileName: uploadable.name,
        objectName: uploadable.model.data.objectName,
        extension: uploadable.extension,
        downloadUri: uploadable.model.data.downloadUri,
    });
}
</script>

<style lang="scss">
.chat-input {
    display: flex;
    max-height: 100%;
    flex-direction: column;
    flex-shrink: 0;

    &__row {
        flex-shrink: 0;
        &--input {
            position: relative;
        }

        &--attachments {
            padding: 0.5rem 0 0 4.5rem;
            -ms-overflow-style: none;
            overflow: auto;
            overflow: -moz-scrollbars-none;

            &::-webkit-scrollbar {
                width: 0;
            }
        }
    }

    &__input {
        padding: 0.5rem 4rem 0.75rem 3.5rem;

        @media (min-width: #{$breakpoint-tablet + 1}) {
            padding: 0.5rem 5rem 1.5rem 4.5rem;
        }
    }

    &__hover {
        position: absolute;
        top: 1rem;

        &--left {
            left: 1rem;

            @media (min-width: #{$breakpoint-tablet + 1}) {
                left: 2rem;
            }
        }

        &--right {
            right: 1rem;

            @media (min-width: #{$breakpoint-tablet + 1}) {
                right: 2rem;
            }
        }
    }

    &--shadow {
        box-shadow: 0 0 0.6875rem 0 rgba(0, 0, 0, 0.07);
    }
}
</style>
