import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import { computed } from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'VBoard',
  props: {
    center: { type: Boolean, default: false },
    vertical: { type: Boolean, default: false },
},
  setup(__props) {

// Svg
// ...

// Components

// Other
const props = __props;

const horizontal = computed((): boolean => {
    return !props.vertical;
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["board", {
            'board--center': __props.center,
            'board--vertical': __props.vertical,
            'board--horizontal': horizontal.value,
        }])
  }, [
    _renderSlot(_ctx.$slots, "default")
  ], 2))
}
}

})