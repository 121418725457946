import { defineComponent as _defineComponent } from 'vue'
import { vModelCheckbox as _vModelCheckbox, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, unref as _unref, createVNode as _createVNode, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "checkbox" }

import CheckSvg from '@/assets/check.svg';

// Components
// ...

// Other
import { computed } from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'VCheckbox',
  props: {
    label: { type: String, default: '' },
    modelValue: { type: Boolean, default: false },
},
  emits: ['update:modelValue'],
  setup(__props, { emit: __emit }) {

// Svg
const emit = __emit;
const props = __props;

const id = 'checkbox-' + Math.random().toString(16).slice(2);
const value = computed({
    get: () => props.modelValue,
    set: (value) => emit('update:modelValue', value),
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("span", _hoisted_1, [
    _withDirectives(_createElementVNode("input", {
      type: "checkbox",
      hidden: "",
      id: id,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((value).value = $event))
    }, null, 512), [
      [_vModelCheckbox, value.value],
      [_vShow, false]
    ]),
    _createElementVNode("span", {
      class: _normalizeClass(["checkbox__box", { 'checkbox__box--checked': __props.modelValue }]),
      onClick: _cache[1] || (_cache[1] = ($event: any) => (value.value = !value.value))
    }, [
      _createVNode(_unref(CheckSvg))
    ], 2),
    _createElementVNode("label", {
      class: "checkbox__label",
      for: id
    }, _toDisplayString(props.label), 1)
  ]))
}
}

})