import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import VModalError from './components/VModalError.vue';
import VModalAlert from './components/VModalAlert.vue';
import VModalConfirm from './components/VModalConfirm.vue';

// Other


export default /*@__PURE__*/_defineComponent({
  __name: 'AppError',
  setup(__props) {

// Components
removeAppLoader();

function removeAppLoader() {
    const loader = document.getElementById('app-loader');
    if (loader) {
        document.body.removeChild(loader);
    }
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(VModalError),
    _createVNode(VModalAlert),
    _createVNode(VModalConfirm)
  ], 64))
}
}

})