import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = { class: "v-table__container" }
const _hoisted_2 = { class: "v-table__wrapper" }
const _hoisted_3 = ["onClick", "onContextmenu"]
const _hoisted_4 = { class: "v-table__content--text" }
const _hoisted_5 = { class: "v-table__body" }

import TableSvg from '@/assets/table.svg';
import TimesSvg from '@/assets/times.svg';
import ArrowsSvg from '@/assets/arrows.svg';
import EyeOffSvg from '@/assets/eye-off.svg';
import ArrowLeftSvg from '@/assets/arrow-left.svg';
import OrderAscSvg from '@/assets/order-asc.svg';
import OrderDescSvg from '@/assets/order-desc.svg';

// Components
import VDraggable from 'vuedraggable';
import { Splitpanes as VSplitpanes, Pane as VPane, RequestUpdateType } from 'splitpanes';

// Other
import { PropType, ref, toRef, h, computed } from 'vue';
import ITable, { ITableColumn } from '@/core/Values/ITable';
import debounce from 'debounce';
import { DebounceInstance } from '@/core/Types';
import ContextMenu, { MenuItem } from '@imengyu/vue3-context-menu';
import { useI18n } from 'vue-i18n';
import { IOrder } from '@/core/Values/IOrder';
import VCheckbox from './VCheckbox.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'VTable',
  props: {
    table: {
        type: Object as PropType<ITable>,
        default: () => ({}),
    },
    order: {
        type: Object as PropType<IOrder>,
        required: true,
    },
},
  emits: ['update:table', 'update:order'],
  setup(__props, { emit: __emit }) {

// Svg
const { t } = useI18n();
const emit = __emit;
const props = __props;

let table = toRef(props.table);
let order = toRef(props.order);
let isScrolled = ref(false);
let debounceInstance: DebounceInstance<ITable> | null = null;

if (table.value.columns.length && !table.value.columns[0].width) {
    let total = 100;
    const columns = table.value.columns.filter((column) => column.visible).reverse();
    columns.forEach((column, index) => {
        if (index === columns.length - 1) {
            column.width = total;
        } else {
            total = total - (column.minWidth ?? 10);
            column.width = column.minWidth ?? 10;
        }
    });
    emit('update:table', table.value);
}

const lastIndex = computed(() => table.value.columns.findLastIndex((column) => column.visible));
const firstIndex = computed(() => table.value.columns.findIndex((column) => column.visible));

function openContextMenu(event: MouseEvent, column: ITableColumn) {
    const items = getContextMenuItems(table.value, column);

    if (!items?.filter((item) => !item.hidden).length) {
        return;
    }

    ContextMenu.showContextMenu({
        x: event.x,
        y: event.y,
        zIndex: 100000,
        customClass: 'prevent-close',

        items,
    });
}

function getContextMenuItems(table: ITable, column: ITableColumn): MenuItem[] {
    const canMoveLeft = getLeftColumnIndex(table, column) !== -1;
    const canMoveRight = getRightColumnIndex(table, column) !== -1;

    const items: MenuItem[] = [
        {
            icon: () => h(OrderAscSvg, { class: 'svg svg--150' }),
            label: t('order-ascending'),
            onClick: () => setOrderBy(column, true),
            hidden: !column.sortable,
        },
        {
            icon: () => h(OrderDescSvg, { class: 'svg svg--150' }),
            label: t('order-descending'),
            onClick: () => setOrderBy(column, false),
            hidden: !column.sortable,
        },

        {
            divided: 'self',
            hidden: !column.sortable,
        },

        {
            icon: () => h(ArrowsSvg, { class: 'svg svg--150' }),
            label: t('sort'),
            children: [
                {
                    icon: () => h(TimesSvg, { class: 'svg svg--150' }),
                    label: t('order-reset'),
                    onClick: () => resetOrderBy(),
                },
                {
                    icon: () => h(OrderAscSvg, { class: 'svg svg--150' }),
                    label: t('order-ascending'),
                    onClick: () => setOrderBy(column, true),
                },
                {
                    icon: () => h(OrderDescSvg, { class: 'svg svg--150' }),
                    label: t('order-descending'),
                    onClick: () => setOrderBy(column, false),
                },
            ],
            hidden: !column.sortable,
        },

        {
            icon: () => h(ArrowsSvg, { class: 'svg svg--150 rotate-90' }),
            label: t('move'),
            children: [
                {
                    icon: () => h(ArrowLeftSvg, { class: 'svg svg--150' }),
                    label: t('move-left'),
                    hidden: !canMoveLeft,
                    onClick: () => moveColumnLeft(table, column),
                },
                {
                    icon: () => h(ArrowLeftSvg, { class: 'svg svg--150 rotate-180' }),
                    label: t('move-right'),
                    hidden: !canMoveRight,
                    onClick: () => moveColumnRight(table, column),
                },
            ],
            hidden: !column.draggable || (!canMoveLeft && !canMoveRight),
        },

        {
            divided: 'self',
            hidden: !column.draggable || (!canMoveLeft && !canMoveRight),
        },

        {
            icon: () => h(EyeOffSvg, { class: 'svg svg--150' }),
            label: t('hide-column'),
            hidden: column.required,
            onClick: () => toggleColumnVisibility(table, column),
        },

        {
            icon: () => h(TableSvg, { class: 'svg svg--150' }),
            label: t('columns'),
            children: table.columns
                .filter((column) => !column.required)
                .map((column) => ({
                    label: column.title,
                    onClick: () => toggleColumnVisibility(table, column),
                    icon: () => h(VCheckbox, { modelValue: column.visible }),
                    clickClose: false,
                })),
        },
    ];
    return items;
}

function setOrderBy(column: ITableColumn, ascending: boolean) {
    if (!column.sortable || !column.orderKey) {
        return;
    }

    order.value.orderBy = column.orderKey;
    order.value.orderAscending = ascending;

    emit('update:order', order.value);
}

function resetOrderBy() {
    order.value.orderBy = undefined;
    emit('update:order', order.value);
}

function toggleColumnVisibility(table: ITable, column: ITableColumn) {
    column.visible = !column.visible;

    const columns = table.columns.filter((column) => column.visible);
    const totalWidth = columns.reduce((total, column) => total + (column.width ?? 0), 0);

    if (totalWidth !== 0) {
        const firstColumn = columns[0];
        const isColumnAdded = column.visible;

        if (!firstColumn.width) {
            return;
        }

        if (isColumnAdded) {
            column.width = column.minWidth ?? 10;
            firstColumn.width = firstColumn.width - column.width;
        } else {
            firstColumn.width = firstColumn.width + (column.width ?? 0);
        }
    }

    emit('update:table', table);
}

function moveColumnLeft(table: ITable, column: ITableColumn) {
    if (!column.draggable) {
        return;
    }

    const columnIndex = table.columns.indexOf(column);
    const leftColumnIndex = getLeftColumnIndex(table, column);

    if (leftColumnIndex === -1) {
        return;
    }

    table.columns[columnIndex] = table.columns[leftColumnIndex];
    table.columns[leftColumnIndex] = column;

    emit('update:table', table);
}

function moveColumnRight(table: ITable, column: ITableColumn) {
    if (!column.draggable) {
        return;
    }

    const columnIndex = table.columns.indexOf(column);
    const rightColumnIndex = getRightColumnIndex(table, column);

    if (rightColumnIndex === -1) {
        return;
    }

    table.columns[columnIndex] = table.columns[rightColumnIndex];
    table.columns[rightColumnIndex] = column;

    emit('update:table', table);
}

function getLeftColumnIndex(table: ITable, column: ITableColumn) {
    const columnIndex = table.columns.indexOf(column);
    const leftColumnIndex = table.columns.findLastIndex((column, index) => index < columnIndex && column.visible);
    const leftColumn = table.columns[leftColumnIndex];
    return leftColumn?.draggable ? leftColumnIndex : -1;
}

function getRightColumnIndex(table: ITable, column: ITableColumn) {
    const columnIndex = table.columns.indexOf(column);
    const rightColumnIndex = table.columns.findIndex((column, index) => index > columnIndex && column.visible);
    const rightColumn = table.columns[rightColumnIndex];
    return rightColumn?.draggable ? rightColumnIndex : -1;
}

function onResize(event: RequestUpdateType[]) {
    let eventIndex = 0;
    let columnIndex = 0;
    while (eventIndex < event.length) {
        const column = table.value.columns[columnIndex];
        columnIndex++;

        if (!column || !column.visible) {
            continue;
        }

        const update = event[eventIndex];
        column.width = update.size ?? 10;

        eventIndex++;
    }

    if (!debounceInstance) {
        debounceInstance = debounce((table: ITable) => {
            emit('update:table', table);
        }, 500);
    }

    debounceInstance(table.value);
}

function onScroll(event: Event) {
    const scroll = event.target as HTMLElement;
    isScrolled.value = scroll.scrollTop !== 0;
}

return (_ctx: any,_cache: any) => {
  return (_unref(table))
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: "v-table",
        onScroll: onScroll
      }, [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            (_unref(table).header)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 0,
                  class: _normalizeClass(["v-table__header prevent-close", { 'v-table__header--with-shadow': _unref(isScrolled) }])
                }, [
                  _createVNode(_unref(VDraggable), {
                    tag: "div",
                    class: "v-table__row v-table__row--header",
                    group: "tasks",
                    draggable: ".v-table__column--sortable",
                    "item-key": "id",
                    "force-fallback": true,
                    "scroll-sensitivity": 200,
                    onUpdate: _cache[0] || (_cache[0] = ($event: any) => (emit('update:table', _unref(table)))),
                    modelValue: _unref(table).columns,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_unref(table).columns) = $event))
                  }, {
                    item: _withCtx(({ element, index }) => [
                      (element.visible)
                        ? (_openBlock(), _createElementBlock("div", {
                            key: 0,
                            class: _normalizeClass(["v-table__column v-table__column--border v-table__column--priority v-table__column--draggable v-table__column--header", {
                                    'v-table__column--sortable': element.draggable,
                                    'v-table__column--index': index === firstIndex.value,
                                    'v-table__column--border-left-borderless': index === firstIndex.value,
                                    'v-table__column--border-right-borderless': index === lastIndex.value,
                                }]),
                            style: _normalizeStyle({ width: element.width + '%' }),
                            onClick: ($event: any) => (
                                    setOrderBy(
                                        element,
                                        element.orderKey === _unref(order).orderBy ? !_unref(order).orderAscending : true,
                                    )
                                ),
                            onContextmenu: _withModifiers(($event: any) => (openContextMenu($event, element)), ["prevent"])
                          }, [
                            _createElementVNode("span", _hoisted_4, _toDisplayString(element.title), 1),
                            _createElementVNode("span", null, [
                              (element.orderKey === _unref(order).orderBy && _unref(order).orderAscending)
                                ? (_openBlock(), _createBlock(_unref(OrderAscSvg), {
                                    key: 0,
                                    class: "svg svg--125 svg--margin-25x svg--middle"
                                  }))
                                : (element.orderKey === _unref(order).orderBy && !_unref(order).orderAscending)
                                  ? (_openBlock(), _createBlock(_unref(OrderDescSvg), {
                                      key: 1,
                                      class: "svg svg--125 svg--margin-25x svg--middle"
                                    }))
                                  : _createCommentVNode("", true)
                            ])
                          ], 46, _hoisted_3))
                        : _createCommentVNode("", true)
                    ]),
                    footer: _withCtx(() => [
                      _createVNode(_unref(VSplitpanes), {
                        class: "v-table__row v-table__row--header v-table__row--header-shadow v-table__row--invisible",
                        onResize: onResize
                      }, {
                        default: _withCtx(() => [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(table).columns, (column, index) => {
                            return (_openBlock(), _createElementBlock(_Fragment, { key: index }, [
                              (column.visible)
                                ? (_openBlock(), _createBlock(_unref(VPane), {
                                    key: 0,
                                    class: _normalizeClass(["v-table__column v-table__column--border v-table__column--borderless", {
                                            'v-table__column--index': index === firstIndex.value,
                                        }]),
                                    "min-size": column.minWidth ?? 10,
                                    size: column.width,
                                    style: _normalizeStyle({ width: column.width + '%' })
                                  }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(column.width), 1)
                                    ]),
                                    _: 2
                                  }, 1032, ["class", "min-size", "size", "style"]))
                                : _createCommentVNode("", true)
                            ], 64))
                          }), 128))
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }, 8, ["modelValue"])
                ], 2))
              : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_5, [
              _renderSlot(_ctx.$slots, "default")
            ])
          ])
        ])
      ], 32))
    : _createCommentVNode("", true)
}
}

})