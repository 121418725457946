import Methods from '../Methods';
import INotification from '../Models/INotification';
import Transport, { ITransport } from '../Transports';
import IApiResult from '../IApiResult';
import { QueryNotificationRequest } from './NotificationService';

class AggregatorService {
    private _transport: ITransport;

    constructor(transport: ITransport) {
        this._transport = transport;
    }

    public queryNotificationsAsync(request?: QueryNotificationRequest): Promise<INotification[]> {
        return this._transport.invokeAsync<QueryNotificationRequest, INotification[]>(
            Methods.Get,
            `/api/v1/aggregator/notifications`,
            request,
        );
    }

    public queryNotificationsApiAsync(request?: QueryNotificationRequest): Promise<IApiResult<INotification[]>> {
        return this._transport.invokeApiAsync<QueryNotificationRequest, INotification[]>(
            Methods.Get,
            `/api/v1/aggregator/notifications`,
            request,
        );
    }

    public queryNotificationsWithUriAsync(uri: string): Promise<INotification[]> {
        return this._transport.invokeAsync<QueryNotificationRequest, INotification[]>(Methods.Get, uri);
    }
}

const service = new AggregatorService(Transport);

Object.freeze(service);

export default service;
