import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, isRef as _isRef, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "v-modal__row v-modal__row--header v-modal__title" }
const _hoisted_2 = { class: "v-modal__row v-modal__row--content v-modal__content" }
const _hoisted_3 = { class: "v-modal__row v-modal__row--actions" }
const _hoisted_4 = ["href"]

import VModal from './VModal.vue';
import VButton from './VButton.vue';

// Other
import emitter from '@/core/Emitter';
import { EventNames } from '@/core/EventNames';
import { markRaw, ref } from 'vue';
import { useI18n } from 'vue-i18n';


export default /*@__PURE__*/_defineComponent({
  __name: 'VModalError',
  setup(__props) {

// Svg
// ...

// Components
const { t, te } = useI18n();

let visible = ref(false);
let serviceDeskUri = markRaw(process.env.VUE_APP_SERVICE_DESK_URI);
let errorTitle = ref('');
let errorMessage = ref('');

emitter.on(EventNames.Error, open);

function open(error: unknown) {
    visible.value = true;

    let localizationKey = '';

    if (error instanceof Error) {
        localizationKey = error.message;
    }

    if (typeof error === 'string') {
        localizationKey = error;
    }

    errorTitle.value = t('error');
    errorMessage.value = te('errors.' + localizationKey) ? t('errors.' + localizationKey) : t('unknown-error');
}

function close() {
    visible.value = false;

    errorTitle.value = '';
    errorMessage.value = '';
}

function reload() {
    location.reload();
    return false;
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(VModal, {
    class: "v-modal--error",
    visible: _unref(visible),
    "onUpdate:visible": _cache[0] || (_cache[0] = ($event: any) => (_isRef(visible) ? (visible).value = $event : visible = $event)),
    onClose: close
  }, {
    default: _withCtx(() => [
      _createElementVNode("h2", _hoisted_1, _toDisplayString(_unref(errorTitle)), 1),
      _createElementVNode("p", _hoisted_2, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(errorMessage).split('\n'), (chunk, index) => {
          return (_openBlock(), _createElementBlock(_Fragment, { key: index }, [
            _createTextVNode(_toDisplayString(chunk) + " ", 1),
            _cache[1] || (_cache[1] = _createElementVNode("br", null, null, -1))
          ], 64))
        }), 128))
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(VButton, {
          class: "button--rectangle button--center button--blurry v-modal__action",
          onClick: reload
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_unref(t)('reload')), 1)
          ]),
          _: 1
        }),
        _createElementVNode("a", {
          target: "_blank",
          href: _unref(serviceDeskUri),
          class: "button--rectangle button--center button--negative v-modal__action"
        }, _toDisplayString(_unref(t)('goto-service-desk')), 9, _hoisted_4)
      ])
    ]),
    _: 1
  }, 8, ["visible"]))
}
}

})