enum ApprovementStatus {
    Waiting = 1,
    Approved,
    Disapproved,
    Skipped,
}

class ApprovementTitle {
    static [ApprovementStatus.Waiting] = 'approval-statuses.waiting';
    static [ApprovementStatus.Approved] = 'approval-statuses.approved';
    static [ApprovementStatus.Disapproved] = 'approval-statuses.disapproved';
    static [ApprovementStatus.Skipped] = 'approval-statuses.skipped';
}

export { ApprovementTitle };
export default ApprovementStatus;
